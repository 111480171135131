import './clouddevops.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const Clouddevops = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>Our Services</h1>
    </div>
    <div className='clouddevops_page'>
        <Row className='align_margin'>
            <Col xs={{ span: 23 }} lg={{ span: 13 }}>
            <div className='bg_box1'></div>
            
                
            </Col>
            <Col className='services_div_right' xs={{ span: 23 }} lg={{ span: 11 }}>
                <h1>Cloud DevOps</h1>
                <div className='line'></div>
                <p>Outsource the complex SRE process to our experts and focus on your core business. We provide exceptional end to end SRE process to keep your delivery focused on your business process and delivery.</p>                
            </Col>
        </Row>
        <div className='clouddevops_box_container'>
        

        <Row className='clouddevops_s2_container align_margin'>
            
            <Col className='clouddevops_div_right' xs={{ span: 24,offset:0 }} lg={{ span: 24, offset:0 }}>
                <div className='clouddevops_s2_box_container'>
                    <Row>
                    <Col className='clouddevops_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M14.9,2.4c1.3,0,27.9,1.2,39.2,1.6c19.8,0.8,39.7,1.7,59.5,2.5c3.8,0.2,5.6,2.1,5.6,5.9
	c-0.3,13.9-0.5,27.8-0.8,41.6c-0.3,12.7-0.5,25.3-0.8,38c-0.1,3.6-4.2,7-7.8,6.5c-7.6-1-15.3-2-22.9-2.9c-1.7-0.2-3.3-0.4-5.1-0.6
	c0.6,3.6,1.2,7,1.8,10.4c0.3,1.9-0.3,3.4-1.9,4.4c-8.1,5.1-16.2,10.1-24.3,15.1c-0.8,0.5-2.1,0.8-3.1,0.6c-11-2.3-22-4.7-33-7.1
	c-1.6-0.3-2.6-1.3-3.1-2.9c-0.6-2,0.3-3.7,2.4-4.8c7.8-4,15.6-8,23.5-11.9c1.1-0.6,2.1-1.4,2-2.7c-0.1-1.9-0.5-3.9-0.7-5.8
	c-1.5-0.2-2.9-0.4-4.2-0.5c-9.1-1.2-18.2-2.3-27.3-3.5c-3.7-0.5-5.1-2.1-5.1-5.9c0-18.5,0-37.1,0-55.6c0-5.3,0-10.5,0-15.8
	c0-2,0.8-3.5,2.4-4.5C12.5,3.8,13.7,3.1,14.9,2.4z M111.6,79.3c0-1.6,0-3.1,0-4.6c0.4-19.5,0.8-39.1,1.2-58.6
	c0.1-3.3-1.3-4.8-4.6-4.9c-13.4-0.6-91.8-4.5-95.9-4.7C10.4,6.4,10,6.8,9.9,8.7c0,0.3,0,0.6,0,0.8c0,18.5,0,36.9,0,55.4
	c0,0.4,0,0.8,0,1.3C43.8,70.6,77.5,74.9,111.6,79.3z M22.5,44.8c-0.3,0-0.6-0.1-0.8-0.1c-1.8-0.2-3,0.7-3.1,2.2s0.9,2.6,2.6,2.8
	c2.8,0.2,5.7,0.5,8.5,0.7c7.3,0.6,14.6,1.2,21.9,1.8c7.5,0.6,15,1.2,22.5,1.8c7.6,0.6,15.3,1.2,22.9,1.8c2.7,0.2,5.4,0.5,8.2,0.6
	c1.8,0.1,3-1.4,2.6-3c-0.3-1.2-1.2-1.8-2.8-2c-8.3-0.7-16.6-1.3-24.9-2c-8.4-0.7-16.8-1.3-25.2-2C44.1,46.5,33.3,45.6,22.5,44.8
	C22.5,44.7,22.5,44.8,22.5,44.8z M53.5,33.5c-0.4,0-0.9-0.1-1.3-0.1c-1.6-0.1-2.8,0.8-2.9,2.2c-0.2,1.5,0.8,2.7,2.5,2.8
	c8.2,0.6,16.5,1.3,24.7,1.9c8.1,0.6,16.2,1.2,24.3,1.8c1.8,0.1,3.1-0.7,3.2-2.2c0.1-1.6-0.9-2.6-2.8-2.7c-5.5-0.4-11-0.8-16.4-1.2
	C74.4,35,64,34.2,53.5,33.5C53.6,33.4,53.6,33.4,53.5,33.5z M101.7,27c0.2,0,0.5,0,0.7,0c1.9,0.1,3.1-0.7,3.2-2.3
	c0.1-1.5-1-2.6-3-2.7c-1.7-0.1-3.4-0.2-5.2-0.3c-8.3-0.5-16.6-1.1-25-1.6c-5.6-0.4-11.2-0.7-16.8-1c-1.8-0.1-2.9,0.9-3,2.4
	c0,1.4,1,2.5,2.7,2.6c0.3,0,0.6,0,0.8,0c5.6,0.3,11.1,0.7,16.7,1C82.6,25.7,92.1,26.4,101.7,27L101.7,27z M21.7,58
	c-0.7,0.2-1.5,0.2-2.1,0.6c-1,0.5-1.3,1.5-1,2.6s1,1.8,2.2,1.9c4.4,0.5,8.8,0.9,13.3,1.4c8.2,0.9,16.4,1.7,24.6,2.5
	c3,0.3,5.9,0.6,8.9,0.8C69,68,70,67,70.2,65.7c0.1-1.3-0.7-2.4-2.1-2.6c-0.5-0.1-1-0.1-1.4-0.2c-6.7-0.7-13.4-1.4-20.1-2.1
	c-8.3-0.8-16.5-1.7-24.8-2.5C21.8,58.2,21.7,58.1,21.7,58z M17.3,34.8c-1.1,0-1.8,0.6-1.8,1.5c0,1,0,2-0.1,3c0,1,0.6,1.7,1.7,1.7
	c1.7,0.1,3.4,0.2,5.1,0.3c1.1,0.1,1.8-0.5,1.8-1.5c0-0.9,0-1.9,0-2.9c0-1.1-0.6-1.7-1.9-1.9c-0.5,0-1-0.1-1.5-0.1
	c-0.1-1.3,0.1-1.6,1.4-1.5c2.2,0.1,4.4,0.3,6.6,0.4c0.2,0,0.4,0,0.5,0c0,0.5,0,1,0,1.6c-0.5,0-1.1-0.1-1.6-0.1
	c-1.1-0.1-1.9,0.5-1.9,1.6c0,0.9,0,1.9,0,2.8c0,1.1,0.7,1.8,1.9,1.8c1.6,0.1,3.2,0.2,4.9,0.3c1.2,0.1,1.9-0.5,2-1.6
	c0-0.9,0-1.8,0-2.7c0-1.3-0.6-1.9-2-2c-0.5,0-0.9-0.1-1.4-0.1c0-0.6,0-1,0-1.6c2.6,0.2,5.2,0.3,7.8,0.5c0.5,0,0.8,0.3,0.8,0.8
	c0,0.3,0,0.5,0,0.8c-0.6,0-1.1-0.1-1.6-0.1c-1.1,0-1.8,0.5-1.9,1.6c0,1,0,1.9-0.1,2.9s0.6,1.7,1.7,1.8c1.7,0.1,3.4,0.2,5.1,0.3
	c1.1,0.1,1.8-0.6,1.8-1.6c0-0.9,0-1.9,0-2.9c0-1.1-0.7-1.8-1.9-1.8c-0.5,0-1-0.1-1.6-0.1c0-0.2,0-0.3,0-0.5c0-1.7-1-2.7-2.9-2.8
	c-2.3-0.1-4.5-0.3-6.8-0.4c-0.2,0-0.3,0-0.5,0c0-0.2,0-0.4,0-0.5c0-0.5-0.1-1.1,0.7-1.3c0.4-0.1,0.5-0.7,0.8-1.1
	c0.1-0.1,0.2-0.4,0.3-0.4c0.8-0.2,1.5-0.7,2.4,0c0.5,0.4,1.2,0.3,1.7-0.1c0.3-0.2,0.6-0.5,0.9-0.8c0.6-0.5,0.6-1,0.3-1.7
	c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1-0.1-0.3,0-0.4c0.3-0.7,0.7-1.4,1.7-1.6c0.6-0.1,0.9-0.6,0.9-1.1c0-0.5,0-0.9,0-1.4
	c0-0.6-0.3-1-0.9-1.2c-0.3-0.1-0.5-0.2-0.8-0.4c-0.1,0-0.3-0.1-0.3-0.2c-0.3-0.7-0.8-1.4-0.2-2.2c0.4-0.5,0.2-1.1-0.3-1.6
	c-0.3-0.3-0.6-0.6-0.9-0.9c-0.5-0.5-1.1-0.7-1.8-0.4c-0.6,0.3-1.2,0.3-1.9-0.1c-0.5-0.2-0.8-0.4-0.9-0.9c-0.2-0.9-0.9-1.3-1.9-1.4
	c-2-0.1-2.1,0.1-2.7,1.4c0,0.1-0.2,0.3-0.4,0.4c-0.8,0.3-1.6,0.5-2.5,0c-0.5-0.3-1-0.2-1.5,0.1c-0.4,0.3-0.8,0.6-1.2,1
	c-0.4,0.4-0.6,0.9-0.3,1.4c0.4,0.7,0.4,1.4-0.1,2.1c-0.1,0.2-0.3,0.4-0.6,0.5c-1.5,0.4-1.7,0.6-1.7,2.1c0,0.1,0,0.2,0,0.3
	c-0.1,0.9,0.4,1.4,1.3,1.7c0.3,0.1,0.7,0.2,0.7,0.3c0.2,0.7,0.9,1.3,0.4,2c-0.6,0.9-0.4,1.3,0.3,2.1c0.1,0.1,0.3,0.3,0.4,0.4
	c0.7,0.7,1.4,0.9,2.3,0.5c0.2-0.1,0.6-0.3,0.7-0.2c0.7,0.3,1.6,0.4,1.8,1.3c0.1,0.6,0.5,1,1.2,1.2c0.1,0,0.2,0.1,0.2,0.2
	c0,0.5,0,0.9,0,1.4c-0.3,0-0.5,0-0.6,0c-2.3-0.1-4.6-0.3-6.9-0.4c-1.8-0.1-2.9,0.8-2.9,2.4c0,0.2,0,0.3,0,0.6
	C18.3,34.9,17.8,34.8,17.3,34.8z M35.1,27.2c-0.5-0.3-0.9-0.3-1.4,0c-0.4,0.2-0.9,0.4-1.4,0.4c-0.5,0.1-0.8,0.3-0.9,0.7
	c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.5-0.5,0.7-1.1,0.5c-0.1,0-0.2-0.2-0.3-0.3c-0.2-1-0.8-1.6-1.9-1.8c-0.1,0-0.3-0.1-0.3-0.1
	c-0.8-0.6-1.5-0.5-2.4-0.1c-0.4,0.2-0.6,0-0.9-0.2c-0.3-0.3-0.4-0.5-0.2-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.1-0.6,0-1
	c-0.2-0.5-0.5-0.9-0.6-1.4c-0.1-0.5-0.4-0.7-0.9-0.9c-0.2,0-0.3-0.1-0.5-0.2c-0.6-0.3-0.7-0.5-0.6-1.1c0-0.1,0.2-0.2,0.3-0.2
	c1.1-0.2,1.5-0.7,2-1.6c0.4-0.9,0.7-1.7,0.1-2.7c-0.1-0.1,0.2-0.4,0.3-0.6c0.1-0.1,0.5-0.2,0.5-0.2c1,0.6,1.8,0.5,2.9,0.1
	c1-0.3,1.7-0.7,2-1.7c0.1-0.3,0.4-0.2,0.7-0.2c0.3,0,0.5,0,0.6,0.3c0.2,1,0.8,1.6,1.9,1.8c0.1,0,0.2,0.1,0.3,0.1
	c0.8,0.6,1.6,0.6,2.4,0.1c0.4-0.2,0.6,0,0.8,0.2c0.2,0.2,0.4,0.4,0.2,0.7c-0.1,0.1-0.2,0.4-0.3,0.5c-0.3,0.4-0.2,0.8,0,1.3
	c0.2,0.4,0.4,0.9,0.6,1.3c0.1,0.5,0.4,0.7,0.9,0.9c0.2,0,0.3,0.1,0.5,0.2c0.6,0.3,0.7,0.5,0.6,1.1c0,0.1-0.2,0.2-0.3,0.2
	c-1.1,0.2-1.6,0.8-2,1.7c-0.4,0.9-0.7,1.6,0,2.5c0.2,0.2-0.4,0.9-0.8,0.8C35.7,27.5,35.4,27.4,35.1,27.2z M27.6,37.2
	c0-0.1,0.2-0.3,0.3-0.2c1.5,0.1,3,0.2,4.5,0.3c0.1,0,0.3,0.2,0.3,0.3c0,0.9,0,1.9,0,2.8c-1.7-0.1-3.4-0.2-5.2-0.3
	C27.5,39.1,27.5,38.1,27.6,37.2z M22.3,36.7c0,1,0,2-0.1,3.1c-1.7-0.1-3.4-0.2-5.1-0.3c0-1,0-2,0.1-3.1
	C18.9,36.4,20.6,36.5,22.3,36.7z M43,41c-1.7-0.1-3.4-0.2-5.1-0.3c0-1,0-2,0.1-3.1c1.7,0.1,3.4,0.2,5.1,0.3C43.1,39,43.1,39.9,43,41
	z M25.3,21.1c-0.1,2.6,2.2,4.9,5,5.1c2.9,0.2,5.2-1.7,5.3-4.4c0.1-2.6-2.2-4.8-5-5C27.7,16.6,25.3,18.6,25.3,21.1z M33.9,21.7
	c0,1.7-1.6,3-3.5,2.9C28.5,24.5,27,23,27,21.3c0-1.7,1.6-3,3.5-2.9C32.4,18.5,33.9,20,33.9,21.7z"/>
</svg>
                        <h3>Monitoring and Observability</h3>
                        <p>Robust monitoring and observability systems are crucial for SRE engineering. This includes collecting and analyzing metrics, logs, and traces to gain insights into system behavior, identify bottlenecks, and detect anomalies. Monitoring tools and dashboards provide real-time visibility into system health and performance.</p>
                    </Col>
                    <Col className='clouddevops_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
					<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M121.8,109.7C121.8,109.7,121.7,109.7,121.8,109.7c-1.3,0-2.5,0-3.7,0c-3.2,0-6.5,0-9.7,0c-1.1,0-2.2,0-3.3,0
	c-6.5,0-12.9,0-19.4,0l0,0c0.2-0.3,0.6-0.3,0.9-0.4c3.3-1.3,4.8-5.1,3.3-8.3c-0.4-0.8-1-1.5-1.5-2.2c0-2.1,0.2-4.2,0-6.4
	c-0.5-5.7-3.1-10.1-8.1-13c0,6,0,12,0,18.1c0,4.1,0,8.1,0,12.2c0-8.1,0-8.1,0-12.2c0-6,0-12,0-18.1c0-0.5,0-1,0-1.5
	c0-0.2,0-0.5,0-0.7v-0.4c0-2.1-1.7-3.7-3.7-3.7H60.1L60,73c-0.2-0.3,0.2-0.5,0.4-0.7c2.4-2.7,3.5-5.8,3.6-9.4c0-3.5,0-6.9,0-10.3
	c0,3.4,0,6.8,0,10.2c1.4,0,2.8,0.1,4.2,0c1.6,0,2.8-0.9,3.5-2.3c1.2-2.4,0.4-5.6-1.7-7.3c0.2-5.3,0.3-10.6,0-15.9
	c-0.3-5.5-4.6-10-10.1-10.9c-2.7-0.5-5.4-0.2-8.1-0.2c-3.9,0-7,2.5-7.9,6.2c-0.1,0.3,0,0.6-0.4,0.7c-0.8-2.1-2.4-3-4.6-2.9
	c-0.5,0-1.1,0-1.7,0c-4,0.1-7.6,3.2-7.8,7.3c-0.2,5.2-0.2,10.4,0,15.6c-2.2,1.4-2.2,3.5-2.1,5.7c0.1,2.1,1.8,3.8,3.9,3.9
	c1.4,0,2.8,0,4.2,0c0.1,3.9,1.5,7.2,4.1,10.1c-0.1,0.1-0.3,0.1-0.4,0.2H23.9c-0.3,0-0.5,0-0.8,0.1c-2.4,0.3-3.8,1.9-3.8,4.5
	c0,0.6,0,1.2,0,1.8c0,1.5,0,3,0,4.5c0-1.5,0-3,0-4.5c-3.7,2-6,5.1-7.3,9c-1.1,3.4-0.7,6.9-0.7,10.3c-0.1,0.4-0.4,0.6-0.6,0.9
	c-1.4,1.7-1.8,3.7-1.1,5.8c0.7,2.2,2.4,3.3,4.4,4.1c0.5,0,0.5,0,0,0c-1.7,0-3.4,0-5.2,0c-0.9,0-1.8,0-2.7,0c0,0,0,0-0.1,0
	c-0.7,0-1,0.2-0.9,1c0.1,1.7,0,3.5,0,5.2c0,2,0,2,2,2s2,0,2.1,2c36.6,0,73.2,0,109.8,0c0.1-2,0.1-2,2.1-2c0.2,0,0.3,0,0.5,0
	c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.4,0c0.7,0,0.9-0.5,0.8-1.4c-0.1-1.9-0.1-3.7,0-5.6C123.1,109.9,122.7,109.7,121.8,109.7z M64,50.7
	c0,0.3,0,0.6,0,1C64,51.4,64,51.1,64,50.7c0-0.6-0.1-1.2-0.3-1.7C63.9,49.6,64,50.1,64,50.7z M63.4,48.5c-0.1-0.2-0.2-0.3-0.3-0.5
	C63.2,48.2,63.3,48.3,63.4,48.5z M35.5,50.3c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0-0.1,0-0.2,0-0.3C35.5,50.6,35.5,50.4,35.5,50.3z
	 M40.6,71.9c-2.4-2.7-3.7-5.7-3.7-9.2l-0.1-9.2v-1c0-0.2,0-0.4,0-0.6c0-0.4,0-0.9,0-1.3c0-1.7,1.1-2.8,2.8-2.8c3.4,0,6.8,0,10.2,0
	s6.7,0,10.1,0c1.7,0,2.7,1.1,2.8,2.8c0,0.4,0,0.9,0,1.3c0,0.2,0,0.4,0,0.6l-0.1,10.1c-0.1,3.4-1.2,6.2-3.2,8.5l0,0
	c-0.7,0.6-0.8,1.3-0.7,1.7H41.6L40.6,71.9z M121.2,116.1c-0.1,0-0.1,0-0.2,0c-2.3,0-3.3,0.4-3.6,2H10.6c-0.4-1.6-1.3-2-3.6-2
	c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c0-0.5,0-1,0-1.5c0-1,0-2,0-3c0.6,0,1.3,0,2,0c1,0,2,0,3,0c0.7,0,1.4,0,2.2,0h4.6h0.8h61h5.4
	h19.4h3.1h0.2c1.2,0,2.4,0,3.5,0c2,0,4.1,0,6.2,0c1.2,0,2.2,0,3.2,0C121.2,112.7,121.2,114.3,121.2,116.1
	C121.3,116.1,121.2,116.1,121.2,116.1z M59.1,56.3c-0.2-1.7-1.5-2.8-3.2-2.8h-0.1c-1.7,0-3,1.2-3.3,2.9c0,0.4,0,0.7,0,1
	c0,0.1,0,0.2,0,0.3s0,0.3,0,0.4c0,0.4,0,0.8,0,1.2c0.2,1.7,1.6,2.9,3.3,2.9c0.1,0,0.1,0,0.2,0c1.7-0.1,3-1.2,3.2-2.9
	C59.3,58.3,59.3,57.2,59.1,56.3z M55.9,54.8c-0.1,0-0.2,0-0.2,0S55.7,54.8,55.9,54.8C55.8,54.8,55.8,54.8,55.9,54.8z M55.5,54.8
	c-0.1,0-0.1,0-0.2,0C55.3,54.9,55.4,54.8,55.5,54.8z M55.1,54.9C55,54.9,55,55,54.9,55C55,55,55.1,54.9,55.1,54.9z M54.8,55.1
	c-0.1,0-0.1,0.1-0.2,0.1C54.7,55.2,54.8,55.1,54.8,55.1z M54.5,55.3L54.5,55.3C54.4,55.4,54.5,55.3,54.5,55.3z M54,56.2
	c0,0.1-0.1,0.2-0.1,0.4C53.9,56.4,53.9,56.3,54,56.2z M54.3,55.5c0,0.1-0.1,0.1-0.1,0.2C54.2,55.7,54.2,55.6,54.3,55.5z M54.1,55.8
	c0,0.1-0.1,0.1-0.1,0.2C54,56,54.1,55.9,54.1,55.8z M54.4,60.2L54.4,60.2L54.4,60.2z M53.9,59.1c0,0.1,0,0.3,0.1,0.4
	C53.9,59.3,53.9,59.2,53.9,59.1z M54,59.6c0,0.1,0.1,0.2,0.1,0.2C54.1,59.7,54,59.6,54,59.6z M54.2,59.9c0,0.1,0.1,0.1,0.1,0.2
	C54.3,60,54.2,60,54.2,59.9z M55.6,60.8c0.1,0,0.2,0,0.2,0h-0.1C55.7,60.8,55.7,60.8,55.6,60.8z M54.7,60.4c0.1,0,0.1,0.1,0.2,0.1
	C54.8,60.5,54.7,60.5,54.7,60.4z M55,60.6c0.1,0,0.1,0.1,0.2,0.1C55.1,60.7,55,60.6,55,60.6z M55.3,60.7c0.1,0,0.1,0,0.2,0
	C55.4,60.8,55.4,60.7,55.3,60.7z M57.4,60.1c0.2-0.3,0.3-0.6,0.4-1C57.7,59.5,57.6,59.8,57.4,60.1z M17.1,30.3
	c6.7,0,12.2-5.5,12.3-12.2c0-6.7-5.5-12.2-12.2-12.3C10.5,5.9,5,11.4,5,18.1C4.9,24.8,10.4,30.3,17.1,30.3z M15.2,10.1
	c0-1.3,0.8-2.2,2-2.2s2,0.8,2.1,2.2c0,2.3,0,4.7,0,7c1.1,1.1,2.2,2.2,3.3,3.3c1,1.1,1.1,2.3,0.2,3.1c-0.9,0.9-2.1,0.8-3.1-0.3
	c-1.2-1.2-2.4-2.4-3.6-3.6c-0.6-0.6-0.9-1.3-0.8-2.2C15.2,15.1,15.2,12.6,15.2,10.1z M103.9,73c1.5-0.8,2.4-2,2.7-3.6
	c0.2-1-0.2-1.8-1.1-2.2c-0.8-0.4-1.9-0.4-2.3,0.4c-0.7,1.5-2,2.2-3.2,3.1c-0.4,0.3-0.8,0.8-1,1.3c-1,1.9-0.3,4.2,1.7,5.5
	c1.6,1.1,1.6,1.1,0,2.2c-1.2,0.8-1.8,1.9-2.1,3.2c-0.3,1.4,0.7,2.5,2.3,2.5c0.5-0.1,1.3-0.4,1.5-1.2c0.2-0.8,0.7-1.2,1.4-1.5
	c1.7-0.8,2.8-2.2,2.8-4c0-1.9-1.1-3.2-2.8-4.1c-0.3-0.2-0.8-0.3-0.8-0.7C103,73.3,103.6,73.2,103.9,73z M53.8,63.6c-1.3,0-2.7,0-4,0
	s-2.7,0-4,0c-1.9,0-3.4,1.4-3.4,3.3s1.4,3.4,3.4,3.4c0.5,0,1,0,1.6,0c0.4,0,0.8,0,1.2,0s0.8,0,1.2,0s0.8,0,1.2,0s0.8,0,1.2,0
	c0.6,0,1.1,0,1.6,0c2.1,0,3.5-1.4,3.5-3.4C57.1,65,55.7,63.6,53.8,63.6z M49.8,65c1.3,0,2.7,0,4,0C52.4,65,51.1,65,49.8,65L49.8,65z
	 M45.8,65c0.4,0,0.8,0,1.2,0C46.6,65,46.2,65,45.8,65c-0.5,0-0.9,0.1-1.2,0.3C44.9,65.1,45.3,65,45.8,65z M44.6,68.6
	c-0.2-0.1-0.3-0.2-0.4-0.4C44,68.1,44,68,43.9,67.9c0.1,0.1,0.1,0.2,0.2,0.3C44.2,68.4,44.4,68.5,44.6,68.6c0.1,0.1,0.2,0.1,0.4,0.2
	C44.8,68.7,44.7,68.7,44.6,68.6z M47.2,69c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0-0.3,0C47.3,69,47.3,69,47.2,69z M49.7,68.9L49.7,68.9
	c-0.4,0-0.9,0-1.3,0C48.9,69,49.3,68.9,49.7,68.9s0.9,0,1.3,0C50.6,69,50.2,68.9,49.7,68.9z M51.9,69c0.1,0,0.2,0,0.4,0h-0.1
	C52.1,69,52,69,51.9,69z M53.7,68.9L53.7,68.9L53.7,68.9c0.2,0,0.3,0,0.5,0C54,68.9,53.8,68.9,53.7,68.9z M54.5,68.8
	c0.1,0,0.2-0.1,0.3-0.1C54.7,68.7,54.6,68.8,54.5,68.8z M54.9,68.6c0.1-0.1,0.3-0.2,0.4-0.3C55.2,68.4,55.1,68.5,54.9,68.6z
	 M43.7,62.2c1.8,0,3.2-1.4,3.3-3.4c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c0-0.2,0-0.4,0-0.7c-0.1-2.1-1.4-3.5-3.3-3.5
	l0,0c-1.9,0-3.3,1.4-3.3,3.4c0,0.6,0,1.3,0,1.9C40.4,60.8,41.7,62.2,43.7,62.2L43.7,62.2z M43.6,60.8L43.6,60.8
	C43.6,60.8,43.7,60.8,43.6,60.8C43.7,60.8,43.6,60.8,43.6,60.8z M45.6,57.9c0,0.3,0,0.6,0,0.9C45.6,58.4,45.6,58.1,45.6,57.9
	c0-0.3,0-0.6,0-0.9C45.6,57.3,45.6,57.6,45.6,57.9z M43.7,54.8L43.7,54.8C43.6,54.8,43.6,54.8,43.7,54.8
	C43.6,54.8,43.6,54.8,43.7,54.8z M43.4,54.8L43.4,54.8C43.4,54.8,43.4,54.8,43.4,54.8z M43.2,54.8L43.2,54.8
	C43.2,54.8,43.2,54.8,43.2,54.8z M43,54.9L43,54.9L43,54.9z M42.9,54.9C42.8,55,42.8,55,42.9,54.9C42.8,55,42.8,55,42.9,54.9z
	 M42.7,55C42.6,55,42.6,55.1,42.7,55C42.6,55.1,42.6,55,42.7,55z M42.5,55.1C42.5,55.1,42.5,55.2,42.5,55.1
	C42.5,55.2,42.5,55.1,42.5,55.1z M42.4,55.2C42.3,55.3,42.3,55.3,42.4,55.2C42.3,55.3,42.3,55.3,42.4,55.2z M42.2,55.4L42.2,55.4
	L42.2,55.4z M41.7,56.9c0-0.1,0-0.2,0-0.2C41.7,56.8,41.7,56.8,41.7,56.9c0,0.3,0,0.6,0,0.9C41.6,57.5,41.6,57.2,41.7,56.9z
	 M42.1,55.5C42.1,55.5,42.1,55.6,42.1,55.5C42.1,55.6,42.1,55.5,42.1,55.5z M42,55.7C42,55.7,42,55.8,42,55.7
	C42,55.7,42,55.7,42,55.7z M41.9,55.9C41.9,55.9,41.9,56,41.9,55.9C41.9,55.9,41.9,55.9,41.9,55.9z M41.8,56L41.8,56
	C41.8,56.1,41.8,56.1,41.8,56z M41.8,56.2L41.8,56.2C41.7,56.3,41.7,56.3,41.8,56.2z M41.7,56.5c0,0.1,0,0.1,0,0.2
	C41.7,56.6,41.7,56.5,41.7,56.5z M42.2,60.2L42.2,60.2L42.2,60.2z M41.7,59.1C41.7,59.2,41.7,59.2,41.7,59.1
	C41.7,59.2,41.7,59.2,41.7,59.1z M41.8,59.6L41.8,59.6L41.8,59.6z M42,59.9L42,59.9L42,59.9z M43.2,60.8L43.2,60.8L43.2,60.8z
	 M42.9,60.7C42.8,60.7,42.8,60.6,42.9,60.7C42.8,60.6,42.8,60.7,42.9,60.7z M42.5,60.5L42.5,60.5L42.5,60.5z M78.2,54.6
	c0,2.5,1.7,4.3,4.2,4.3c6.2,0,12.4,0,18.5,0c6,0,12.1,0,18.1,0c2.1,0,3.9-1.8,3.9-3.9c0-12.3,0-24.5,0-36.8c0-1.4,0.1-2.8,0.1-4.2
	c0-2.3-1.8-4-4.1-4.1c-1.3,0-2.7,0-4,0c0-1.3,0-2.5,0-3.8c0-1.4-0.9-2.2-2.1-2.2c-1.1,0-1.9,0.9-2,2.2c0,1.3,0,2.5,0,3.8
	c-6.8,0-13.6,0-20.3,0c0-1.3,0-2.5,0-3.8c0-1.4-0.9-2.2-2.1-2.2c-1.1,0-1.9,0.9-2,2.2c0,1.3,0,2.5,0,3.8c-1.4,0-2.8,0-4.2,0.1
	c-2.2,0.1-3.9,1.7-4,3.8c-0.1,1.5,0,3,0,4.4C78.3,30.3,78.2,42.4,78.2,54.6z M121,54.9c0,1-0.9,1.8-1.9,1.8c-6,0-12.1,0-18.1,0
	c-6.2,0-12.4,0-18.5,0c-1.4,0-2.2-0.8-2.2-2.2c0-9,0-18.1,0-26.9v-7.5H121v7.3C121,36.5,121,45.8,121,54.9z M114.5,72.8
	c1.3-0.8,2.1-2,2.4-3.5c0.2-1.1-0.3-1.9-1.3-2.3c-1-0.3-2-0.1-2.3,0.9c-0.4,1-1.1,1.4-1.9,1.9c-3.2,1.8-3.3,5.8-0.1,7.7
	c0.5,0.3,1,0.4,1.3,0.9c-0.3,0.5-0.9,0.6-1.4,0.9c-1.3,0.8-2.1,2-2.4,3.4c-0.2,1.1,0.3,1.9,1.3,2.3c1,0.3,2,0.1,2.4-0.9
	s1-1.4,1.9-1.9c3.2-1.8,3.2-5.8,0-7.7c-0.5-0.3-1-0.4-1.3-1C113.5,73.2,114,73.1,114.5,72.8z M112.9,106.8c0.7-1,1.4-1.2,2.5-1.2
	c4.3-0.2,7.6-3.9,7.6-8.2c0-4.2-3.5-7.9-7.7-8c-5.3-0.1-10.6,0-15.9,0c-0.7,0-0.9,0.2-0.9,0.9c0,2.1,0,4.2,0,6.2c0,1.2,0,2.5,0,3.7
	c0,5.3,1.6,7.7,6.5,9.5c1.1,0,2.2,0,3.3,0C110.2,109.1,111.9,108.4,112.9,106.8z M114.9,94.1c0-0.7,0.3-0.7,0.9-0.6
	c1.8,0.4,3.2,2.1,3.2,3.9c0,1.9-1.4,3.7-3.2,4c-0.6,0.1-0.9,0.1-0.8-0.6C114.9,98.6,114.9,96.4,114.9,94.1z M118.5,49.4
	c-0.1-0.2-0.2-0.4-0.3-0.5c-1.4-2.2-2.7-4.4-4.1-6.6c-3.7-6-7.5-12.1-11.2-18.2c-0.6-1-1.4-1.5-2.3-1.5c-1.4,0-2,1.1-2.3,1.6
	l-0.1,0.1c-1.4,2.4-2.9,4.7-4.3,7.1c-2.5,4.1-5.1,8.3-7.6,12.4c-0.8,1.3-1.6,2.6-2.4,4c-0.4,0.6-0.8,1.3-1.1,1.9l-1.9,3.1h3.6h32.4
	h3.5l-1.7-3C118.6,49.5,118.5,49.4,118.5,49.4z M98.6,34.6c0-1.3,0.8-2.2,2-2.2c1.1,0,2,0.8,2.1,2.1c0,0.7,0,1.4,0,2s0,1.2,0,1.8
	c-0.1,1.3-0.8,2.2-2,2.2c-1.1,0-2-0.8-2-2.1C98.6,37.1,98.6,35.8,98.6,34.6z M102.6,44.5c0,1.1-0.9,2-2,2.1c-1.1,0-2-0.9-2.1-2
	c0-1.1,0.9-2,2-2.1C101.7,42.5,102.6,43.4,102.6,44.5z M100.4,24.7C100.4,24.6,100.4,24.6,100.4,24.7
	C100.4,24.6,100.4,24.6,100.4,24.7c0.1-0.1,0.1-0.1,0.1-0.1l0,0c0,0,0,0,0.1,0l0,0l0,0l0,0c0,0,0,0,0.1,0l0,0c0,0,0,0,0.1,0l0,0
	c0,0,0.1,0,0.1,0.1l0,0l0.1,0.1l0,0c0,0,0,0.1,0.1,0.1l0,0c0,0.1,0.1,0.1,0.1,0.2c0-0.1-0.1-0.1-0.1-0.2l0,0c0,0,0-0.1-0.1-0.1l0,0
	l-0.1-0.1l0,0c0,0-0.1,0-0.1-0.1l0,0c0,0,0,0-0.1,0l0,0c0,0,0,0-0.1,0l0,0l0,0c0,0,0,0-0.1,0l0,0C100.5,24.6,100.5,24.6,100.4,24.7
	C100.4,24.6,100.4,24.6,100.4,24.7C100.4,24.6,100.4,24.6,100.4,24.7C100.4,24.7,100.3,24.7,100.4,24.7c0,0.1-0.1,0.1-0.1,0.1l0,0
	c0,0,0,0.1-0.1,0.1l0,0V25v0.1c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0-0.1,0.1-0.1c0,0,0,0,0-0.1V25v-0.1l0,0c0,0,0-0.1,0.1-0.1l0,0
	C100.3,24.7,100.3,24.7,100.4,24.7C100.3,24.7,100.4,24.7,100.4,24.7z M9,122.4c-0.2,1.3,0.3,1.6,1.6,1.6l0,0c34.8,0,69.7,0,104.5,0
	l0,0c0.7,0,1.6,0.2,2.3,0.2c0.5,0,1-0.1,1.3-0.5c0.7-0.9,0.2-2.5,0.2-3.8c-36.6,0-73.2,0-109.8,0C9.1,120.7,9.1,121.6,9,122.4z
	 M10.8,121.6h106.6c0,0.3,0.1,0.6,0.1,0.9c-0.3,0-0.6,0-1-0.1s-0.9-0.1-1.4-0.1c-12.2,0-24.4,0-36.6,0c-22.2,0-45.1,0-67.7,0
	C10.8,122,10.8,121.8,10.8,121.6z"/>
</svg>
                        <h3>Incident Management</h3>
                        <p>Incident management is a critical component of SRE engineering. It involves establishing processes and tools for incident response, including incident identification, notification, escalation, and resolution. SREs collaborate with development teams to address incidents promptly and minimize their impact on system availability and performance.</p>
                    </Col>
                    <Col className='clouddevops_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M106,20.1c-28.1,0-56,0-84,0c0,0.5,0,1,0,1.5c0,17.8,0,35.6,0,53.4c0,2.6-0.5,3.1-3,3.1c-1.7,0-3.3,0-5,0
	c-3.3,0.1-6,2.7-6,6c0,3.3,2.6,5.9,5.9,6c1.3,0,2.6,0,4,0c0,1.3,0,2.6,0,4c-2.6,0-5.3,0.4-7.8-0.8c-4-1.8-6.1-5-6.1-9.4
	C4,74.5,4,65.2,4,56c0-13.1,0-26.2,0-39.2C4,10.2,8.2,6,14.7,6c1.6,0,3.2,0,4.8,0C21.3,6,22,6.7,22,8.6c0,2.4,0,4.8,0,7.3
	c28,0,55.9,0,84,0c0-2.4,0-4.7,0-7.1c0-2.2,0.6-2.8,2.8-2.8c2.1,0,4.3-0.1,6.4,0.1c5.1,0.6,8.8,4.8,8.8,10c0,8.8,0,17.7,0,26.5
	c0,13.5,0,27.1,0,40.6c0,6.7-4.2,10.8-10.9,10.8c-2.3,0-4.7,0-7.1,0c0-1.3,0-2.6,0-4c2.7,0,5.3,0,8,0c4-0.1,6.8-3.9,5.7-7.7
	c-0.7-2.4-3-4.2-5.6-4.2c-1.6,0-3.3,0-4.9,0c-2.9,0-3.3-0.4-3.3-3.3c0-17.7,0-35.3,0-53C106,21.2,106,20.7,106,20.1z M17.9,10
	c-1.3,0-2.5,0-3.6,0C10.5,10,8,12.6,8,16.4c0,19.5,0,38.9,0,58.4c0,0.3,0,0.7,0.1,1.2c3-2.4,6.5-1.9,9.9-2
	C17.9,52.6,17.9,31.4,17.9,10z M110.1,10c0,21.4,0,42.7,0,64c3.4,0,6.9-0.3,9.9,2c0-0.7,0-1.2,0-1.6c0-18.4,0-36.8,0-55.1
	c0-1.2,0-2.3,0-3.5c-0.1-3-2.5-5.6-5.6-5.7C113,10,111.6,10,110.1,10z M52,87.7c0-3.8,0.5-6.5-0.2-8.9c-0.7-2.3-2.6-4.3-4-6.4
	C42.3,64,42.7,53.8,49,46.9c5.6-6.2,12.7-8.4,20.7-6c8.1,2.4,12.8,8.1,14.1,16.5c0.8,5-0.4,9.6-2.9,13.9c-1.3,2.2-2.9,4.3-4.2,6.5
	C76.3,78.3,76,79,76,79.7c-0.1,4.5,0,9.1,0,13.6c0,0.7-0.2,1.5-0.5,2.1c-1.1,2.4-2.3,4.7-3.5,7c-0.5,1.1-1.3,1.6-2.5,1.5
	c-3.7,0-7.4,0-11.1,0c-1.2,0-2-0.4-2.4-1.5c-0.1-0.3-0.2-0.5-0.4-0.8C52.7,97,51,92.1,52,87.7z M70.4,76.2c1.1-0.2,2.4-0.1,2.7-0.6
	c2-2.9,4.2-5.8,5.5-9c2.6-6.3,1.8-12.4-3-17.5c-4.5-4.8-10.2-6.2-16.5-4.3c-10.1,3.1-14.2,14.9-8.7,24.3c1.1,1.9,2.4,3.7,3.6,5.5
	c1.1,1.6,1.4,1.7,3.4,1.2c-1.1-5.7-2.3-11.3-3.4-17c-0.2-1-0.1-1.8,0.8-2.4c0.9-0.6,1.8-0.4,2.6,0.3c2.1,1.6,4.3,3.2,6.5,4.9
	c2.2-1.6,4.3-3.2,6.4-4.8c0.9-0.7,1.7-1,2.7-0.3c0.9,0.6,1,1.5,0.7,2.6C72.7,64.6,71.6,70.2,70.4,76.2z M71.9,80.1
	c-5.4,0-10.6,0-15.8,0c0,4,0,7.9,0,11.8c5.3,0,10.6,0,15.8,0C71.9,88,71.9,84.1,71.9,80.1z M59,62.8c0.7,3.5,1.3,6.6,2,9.6
	c0.3,1.2,0.2,2.9,1,3.4c0.8,0.6,2.4,0.2,3.6,0.1c0.3,0,0.8-0.5,0.9-0.8c0.4-1.5,0.7-3,1-4.5c0.5-2.5,1-5.1,1.5-7.9
	c-1.2,0.9-2.2,1.7-3.2,2.4c-1.5,1.1-2,1.1-3.5,0C61.2,64.4,60.2,63.7,59,62.8z M57.3,96.1c0.6,1.2,1.1,2.4,1.7,3.4
	c0.2,0.3,0.6,0.5,1,0.5c2.7,0,5.3,0,8,0c0.3,0,0.8-0.1,0.9-0.3c0.7-1.2,1.2-2.4,1.9-3.6C66.1,96.1,61.8,96.1,57.3,96.1z M102,88
	c0,7,0,14.1,0,21.1c0,0.9-0.3,1.9-0.7,2.7c-1.7,2.9-3.4,5.8-5.2,8.7c-1.2,2.1-2.8,2-4.1,0c-1.8-3-3.6-5.9-5.3-8.9
	c-0.4-0.6-0.6-1.5-0.6-2.2c0-14.2,0-28.5,0-42.7c0-2,0.7-2.6,2.6-2.6c3.6,0,7.2,0,10.9,0c1.8,0,2.5,0.7,2.5,2.5
	C102,73.7,102,80.8,102,88z M90.1,82.1c0,8.7,0,17.3,0,25.9c0.7,0,1.2,0,1.9,0c0-8.6,0-17.2,0-25.9C91.3,82.1,90.7,82.1,90.1,82.1z
	 M97.9,108c0-8.7,0-17.3,0-25.9c-0.7,0-1.2,0-1.9,0c0,8.6,0,17.2,0,25.9C96.7,108,97.3,108,97.9,108z M98,68.1c-2.7,0-5.3,0-7.8,0
	c0,1.3,0,2.6,0,3.9c2.7,0,5.2,0,7.8,0C98,70.7,98,69.4,98,68.1z M98,76.1c-2.7,0-5.3,0-7.9,0c0,0.7,0,1.2,0,1.8c2.7,0,5.2,0,7.9,0
	C98,77.3,98,76.7,98,76.1z M96.4,112.1c-1.6,0-3.1,0-4.8,0c0.8,1.3,1.6,2.6,2.4,4C94.9,114.7,95.6,113.5,96.4,112.1z M25.2,122
	c-4.9,0-9.7,0-14.6,0c-2.9,0-5.2-1.4-6.1-3.9c-1.1-2.7-0.4-5,1.6-7.1c9.5-9.5,19-19,28.5-28.5c2.5-2.5,5.7-3.2,8.7-1.9
	c3.2,1.4,4.7,4,4.7,7.5c0,8.5,0,16.9,0,25.4c0,5.2-3.4,8.5-8.5,8.6C34.7,122,30,122,25.2,122z M44,90c0-1,0.1-1.8,0-2.7
	c-0.1-1.4-0.9-2.5-2.2-3.1c-1.7-0.7-3.1-0.1-4.4,1.2c-9.4,9.4-18.8,18.9-28.3,28.3c-0.2,0.2-0.4,0.4-0.6,0.6c-1,1.4-0.3,3.4,1.4,3.6
	c2,0.2,4,0,6.2,0c0-1.4,0-2.7,0-3.9c1.4,0,2.6,0,4,0c0,1.3,0,2.6,0,3.8c1.4,0,2.7,0,4,0c0-1.3,0-2.6,0-3.8c1.4,0,2.6,0,4,0
	c0,1.3,0,2.6,0,3.8c1.4,0,2.6,0,4,0c0-1.3,0-2.6,0-3.9c1.4,0,2.6,0,4,0c0,1.3,0,2.6,0,3.9c1.5,0,3,0.1,4.4,0
	c1.7-0.1,3.3-1.5,3.5-3.2c0.2-1.5,0-3.1,0-4.8c-1.4,0-2.6,0-3.9,0c0-1.4,0-2.6,0-4c1.3,0,2.6,0,3.8,0c0-1.4,0-2.7,0-4
	c-1.3,0-2.6,0-3.8,0c0-1.4,0-2.6,0-4c1.3,0,2.6,0,3.8,0c0-1.4,0-2.6,0-4c-1.3,0-2.6,0-3.9,0c0-1.4,0-2.6,0-3.9
	C41.3,90,42.6,90,44,90z M44.2,35.6c-0.9,0.8-1.8,1.7-2.6,2.6c1.4,1.4,2.7,2.7,4,4c0.9-0.8,1.8-1.6,2.6-2.4
	C46.8,38.3,45.5,37,44.2,35.6z M86.4,38.3c-0.9-0.9-1.8-1.8-2.6-2.7c-1.4,1.4-2.7,2.7-4.1,4.1c0.8,0.8,1.7,1.7,2.5,2.6
	C83.7,40.9,85.1,39.6,86.4,38.3z M65.9,28.1c-1.3,0-2.6,0-3.9,0c0,2,0,3.9,0,5.9c1.3,0,2.5,0,3.9,0C65.9,31.9,65.9,30,65.9,28.1z
	 M34.1,59.9c2,0,3.9,0,5.8,0c0-1.3,0-2.6,0-3.9c-2,0-3.9,0-5.8,0C34.1,57.4,34.1,58.7,34.1,59.9z M93.9,59.9c0-1.3,0-2.6,0-3.9
	c-2,0-3.9,0-5.8,0c0,1.3,0,2.5,0,3.9C90.1,59.9,92,59.9,93.9,59.9z M29,110c-1.6,0-3.2,0-4.7,0c-1,0-1.7-0.4-2.1-1.3
	c-0.4-0.9-0.1-1.6,0.6-2.3c3.2-3.2,6.5-6.5,9.7-9.7c0.7-0.7,1.4-1,2.4-0.6c0.9,0.4,1.2,1.2,1.2,2.1c0,3.1,0,6.2,0,9.4
	c0,1.6-0.8,2.3-2.4,2.3C32,110,30.5,110,29,110z M31.9,106c0-1,0-1.9,0-2.6c-0.9,0.8-1.8,1.7-2.8,2.6C30.1,106,31,106,31.9,106z"/>
</svg>
                        <h3>Capacity Planning and Scaling</h3>
                        <p>SREs engage in capacity planning to ensure that systems can handle anticipated workloads effectively. This involves analyzing historical data, forecasting future demand, and scaling infrastructure resources accordingly. SREs work closely with development teams to optimize system architecture for scalability, enabling efficient resource allocation and dynamic scaling based on demand.</p>
                    </Col>
					<Col className='clouddevops_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
						<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M15.3,113.8c0-5.8,0-11.5,0-17.2c0-0.4,0.2-0.8,0.5-1c3.6-2.9,7.7-4.8,12.5-4.7c7.5,0,15,0.1,22.5,0.3
	c1.2,0,2.5,0.2,3.6,0.5c2.2,0.7,3,2.7,2.1,4.9c-0.4,1.1-1.2,1.6-2.4,1.6c-4.7,0-9.4,0.1-14.1,0.2c-0.5,0-1-0.1-1.4,0.1
	c-0.4,0.2-1,0.7-1,1.1c0,0.4,0.6,0.8,1,1.1c0.1,0.1,0.3,0,0.4,0c7-0.2,14-0.3,21.1-0.7c6.5-0.4,12.4-2.9,18.2-5.9
	c1.7-0.9,3.3-1.8,5-2.6c0.8-0.4,1.6-0.7,2.5-0.9c1.9-0.2,2.9,1.1,1.9,2.8c-0.8,1.3-1.8,2.4-2.9,3.4c-8.5,7.4-18.2,12.3-29.3,14.2
	c-2.5,0.4-5,0.3-7.6,0.3c-7.5,0-15-0.1-22.5-0.2c-3,0-5.8,1.1-8.5,2.2C16.5,113.3,16,113.5,15.3,113.8z M111,15
	c-2.8,1.1-5.6,2.2-8.7,2.1C96.2,17,90.1,17,84,17c-4,0-8-0.3-11.9,0.3c-10.4,1.7-19.5,6.4-27.6,13c-1.4,1.1-2.6,2.5-3.8,3.9
	c-0.7,0.8-1.3,2-0.3,2.8c0.6,0.5,1.8,0.7,2.7,0.5c1.2-0.3,2.4-1.1,3.5-1.7c5.7-3.1,11.6-6.1,18.1-7c5.6-0.7,11.3-0.8,16.9-1.1
	c2.4-0.1,4.9-0.1,7.3-0.1c0.8,0,1.4,0.3,1.5,1.1c0,0.9-0.6,1.2-1.4,1.1c-0.4,0-0.8,0-1.1,0c-4.6,0.1-9.3,0.2-13.9,0.2
	c-1.4,0-2.1,0.7-2.5,1.8c-0.7,2,0.1,3.9,2.1,4.5c1.2,0.4,2.5,0.6,3.7,0.6c7.3,0.1,14.5,0.1,21.8,0.3c4.8,0.1,9-1.5,12.8-4.4
	c0.7-0.5,1-1.1,1-2c0-5.1,0-10.1,0-15.2c0-0.4,0-0.8-0.1-1.4C112,14.5,111.5,14.8,111,15z M77,52.8c-0.1-0.2-0.8-0.1-1.2,0
	c-4.7,1.7-7.7,0.1-8.7-4.7c-0.1-0.5-0.8-1.2-1.3-1.2c-1.3-0.2-2.7,0-4.1,0c-1,0-1.5,0.6-1.6,1.6c0,3.9-4.5,6.8-8.4,4.9
	c-0.4-0.2-1.5,0.1-1.7,0.5c-0.8,1.1-1.4,2.4-2.1,3.6c-0.5,0.8-0.2,1.5,0.6,2c3.7,2.7,3.7,6.7,0.1,9.7c-0.7,0.6-0.9,1.2-0.5,1.9
	c0.7,1.2,1.3,2.5,2.2,3.6c0.3,0.4,1.3,0.7,1.8,0.5c4.7-1.6,7.7,0,8.7,4.8c0.1,0.5,0.8,1.2,1.3,1.2c1.4,0.2,2.8,0,4.2,0
	c0.9,0,1.3-0.5,1.4-1.4c0.8-4.8,3.9-6.7,8.4-5c0.9,0.3,1.5,0.1,1.9-0.6c0.7-1.1,1.3-2.3,1.9-3.4c0.5-0.9,0.3-1.6-0.6-2.2
	c-3.5-2.5-3.5-6.8-0.2-9.5c0.4-0.3,0.7-0.7,1.2-1.3C79.3,55.9,78.2,54.3,77,52.8z M71.4,64c0,4.1-3.3,7.3-7.3,7.3
	c-4.1,0-7.5-3.2-7.5-7.3c0-4.2,3.2-7.5,7.5-7.5C68.2,56.6,71.4,59.9,71.4,64z M1.9,117.2c0,2.2,1.1,3.3,3.4,3.4c1.5,0,3.1,0,4.6,0
	c1.9,0,3.1-1.1,3.2-3c0-8.3,0-16.6,0-24.9c0-1.9-1.3-3-3.2-3c-1.5,0-3,0-4.5,0c-2.4,0-3.5,1.1-3.5,3.5c0,4,0,8,0,11.9
	C1.8,109.1,1.8,113.1,1.9,117.2z M126.1,10.7c0-2.1-1.1-3.2-3.2-3.3c-1.6,0-3.2,0-4.8,0c-2,0-3.2,1.2-3.2,3.1c0,8.2,0,16.4,0,24.6
	c0,2,1.2,3.2,3.3,3.2c1.5,0,2.9,0,4.4,0c2.5,0,3.5-1.1,3.5-3.5c0-4,0-8,0-11.9C126.2,18.9,126.2,14.8,126.1,10.7z M37.3,54.7
	c0.2,0.9,0.7,1.5,1.7,1.3c1-0.2,1.3-0.9,1.1-1.8c-0.4-1.7-0.7-3.4-1.2-5.1c-0.2-0.5-1-1.1-1.5-1.1c-1.8,0.2-3.6,0.6-5.4,1
	c-0.9,0.2-1.3,0.9-1,1.7c0.2,0.5,1,0.8,1.6,1c0.4,0.1,0.9-0.1,1.6-0.2c-3.3,5.3-4.6,10.7-3.8,16.6c0.6,4.1,2.2,7.8,4.7,11.1
	c0.6,0.7,1.2,1.1,2.1,0.5c0.8-0.6,0.7-1.3,0.2-2.1c-0.1-0.2-0.2-0.3-0.3-0.5c-3.4-4.7-4.7-9.9-3.8-15.7c0.5-3.1,1.5-5.9,3.5-8.6
	C37.1,53.6,37.2,54.2,37.3,54.7z M93.9,76.1c0.1-0.2,0.2-0.4,0.4-0.6c3.7-6.4,4.5-13.1,2-20.1c-0.8-2.3-2.3-4.4-3.5-6.5
	c-0.5-0.8-1.3-1.2-2.1-0.5c-0.8,0.7-0.7,1.5-0.1,2.2c0.1,0.1,0.2,0.2,0.2,0.3c3.4,4.7,4.7,9.9,3.8,15.7c-0.5,3.1-1.5,5.9-3.5,8.6
	c-0.2-0.8-0.3-1.3-0.4-1.9c-0.2-0.9-0.7-1.5-1.7-1.3c-1,0.2-1.2,0.9-1,1.8c0.3,1.6,0.6,3.3,1.2,4.9c0.2,0.5,1.1,1.2,1.6,1.2
	c1.8-0.2,3.6-0.6,5.4-1.1c0.4-0.1,0.9-1,1-1.5c0-0.9-0.7-1.2-1.6-1.1c-0.5,0.1-0.9,0.1-1.4,0.2C93.9,76.2,93.9,76.1,93.9,76.1z"/>
</svg>
                        <h3>Change Management</h3>
                        <p>SRE engineering emphasizes disciplined change management practices to minimize the risk of service disruptions. This involves implementing change control processes, conducting impact assessments, and ensuring proper testing and validation of changes before deployment. SREs collaborate with development teams to maintain stability during software releases and configuration changes.</p>
                    </Col>
					<Col className='clouddevops_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M81.1,2.3c3.6,2.8,7.2,5.7,10.7,8.5c1.3,1,1.3,2.4,0,3.5c-3.3,2.7-6.6,5.3-10,8c-1,0.8-2.3,0.7-3-0.2
	c-0.7-0.9-0.6-2.1,0.4-3c1.5-1.2,3-2.4,4.5-3.6c0.3-0.2,0.5-0.5,1-0.9c-0.7,0-1.1,0-1.4,0c-21.2,0-42.5,0-63.7,0c-3.4,0-5,1.5-5,5
	c0,1.7,0,3.4,0,5.1c0,1.4-0.8,2.2-2,2.2c-1.3,0-2.1-0.8-2.1-2.2c0-2-0.1-4.1,0-6.1c0.1-4.5,3.7-8,8.3-8c3.9,0,7.9,0,11.8,0
	c17.5,0,35,0,52.5,0c0.4,0,0.9,0,1.7,0c-0.7-0.6-1.2-1-1.7-1.4c-1.1-0.9-2.2-1.8-3.3-2.6c-1.9-1.5-1.9-2.6-0.1-4.2
	C80.2,2.3,80.6,2.3,81.1,2.3z M2.3,48.3c1.6,1.8,2.7,1.7,4.2-0.2c1.2-1.5,2.5-3.1,4-4.9c0,0.8,0,1.3,0,1.7c0,19.8,0,39.7,0,59.5
	c0,1.6,0,3.3,0,4.9c0.1,4,2.9,7.4,6.9,7.9c2.5,0.3,5,0.2,7.6,0.2c1.2,0,2-1,2-2.1c-0.1-1.2-0.9-1.9-2.2-1.9c-1.8,0-3.6,0-5.4,0
	c-3.1,0-4.7-1.6-4.7-4.7c0-21.5,0-42.9,0-64.4c0-0.3,0-0.6,0.1-1.1c1.5,1.9,2.9,3.6,4.3,5.3c0.9,1.1,2.1,1.3,3.1,0.6
	c0.9-0.7,1.1-2,0.2-3.1c-2.6-3.3-5.2-6.6-7.9-9.9c-1-1.3-2.5-1.3-3.5,0C8,39.7,5.1,43.3,2.3,46.9C2.3,47.4,2.3,47.8,2.3,48.3z
	 M33.3,84.6c-2.6,0.1-4.2,1.8-4.2,4.3c0,2.6,0,5.2,0,7.8c0,2.6,1.6,4.3,4.2,4.3c4.1,0.1,8.1,0.1,12.2,0c2.6,0,4.2-1.7,4.2-4.3
	c0-2.5,0-5.1,0-7.6c0-2.9-1.6-4.5-4.5-4.6c-1.2,0-2.4,0-3.5,0c-0.4-3.3,0.2-4.1,3.3-4.1c5.2,0,10.4,0,15.7,0c0.4,0,0.9,0,1.3,0
	c0,1.4,0,2.7,0,4.1c-1.3,0-2.5,0-3.7,0c-2.7,0-4.4,1.7-4.4,4.5c0,2.5,0,5,0,7.5c0,2.8,1.7,4.5,4.5,4.5c3.9,0,7.7,0,11.6,0
	c2.9,0,4.5-1.7,4.6-4.6c0-2.3,0-4.7,0-7c0-3.3-1.6-4.9-4.9-4.9c-1.1,0-2.2,0-3.3,0c0-1.5,0-2.7,0-4.1c6.2,0,12.4,0,18.5,0
	c1.1,0,1.8,0.8,1.9,1.9c0.1,0.7,0,1.3,0,2.1c-1.4,0-2.6,0-3.8,0c-2.7,0.1-4.3,1.7-4.4,4.4c0,2.6,0,5.1,0,7.7c0,2.6,1.6,4.3,4.2,4.4
	c4,0.1,8,0.1,12.1,0c2.6,0,4.2-1.8,4.2-4.4c0-2.5,0-5.1,0-7.6c0-2.8-1.7-4.5-4.5-4.5c-1.2,0-2.4,0-3.7,0c0-0.5,0-0.8,0-1.2
	c0-4.6-2.5-7-7.1-7c-5.4,0-10.8,0-16.3,0c-0.4,0-0.8,0-1.3,0c0-0.6,0-1,0-1.4c0-1.4-0.3-2.8,1.7-3.5c0.9-0.3,1.2-1.9,1.8-2.9
	c0.2-0.4,0.4-1,0.7-1.2c1.9-0.7,3.6-2,5.8-0.5c1.3,0.9,2.9,0.5,4.1-0.6c0.8-0.7,1.5-1.5,2.2-2.2c1.3-1.3,1.5-2.8,0.7-4.5
	c-0.2-0.5-0.5-1-0.6-1.6c-0.1-0.3-0.2-0.7-0.1-1c0.7-2,1.5-3.9,4-4.4c1.5-0.3,2.1-1.6,2.2-3.1c0-1.2,0-2.4,0-3.6
	c0-1.5-0.8-2.5-2.1-3.1c-0.7-0.3-1.3-0.5-2-0.8c-0.3-0.1-0.6-0.3-0.7-0.5c-0.7-1.9-2-3.6-0.5-5.8c0.9-1.4,0.5-2.9-0.7-4.2
	c-0.7-0.7-1.5-1.5-2.2-2.2c-1.3-1.2-2.7-1.6-4.3-0.7c-1.5,0.8-2.9,0.9-4.5,0.1c-1.1-0.5-1.9-1-2.2-2.2c-0.6-2.4-2.2-3.3-4.6-3.3
	c-4.7,0-5.1,0.5-6.4,4.1c-0.1,0.4-0.5,0.8-0.9,1c-1.9,0.9-3.8,1.7-5.9,0.3c-1.1-0.8-2.5-0.3-3.5,0.6c-0.9,0.9-1.9,1.8-2.7,2.7
	c-1,1-1.3,2.4-0.6,3.6c1.1,1.9,0.9,3.6-0.1,5.5c-0.3,0.6-0.6,1.1-1.3,1.4c-3.6,1.4-4,1.9-3.9,5.9c0,0.2,0,0.5,0,0.7
	c-0.1,2.3,0.9,3.6,3.1,4.3c0.6,0.2,1.6,0.4,1.7,0.8c0.6,1.7,2.2,3.2,1,5.3c-1.3,2.5-1,3.4,0.9,5.5c0.3,0.3,0.7,0.7,1,1
	c1.6,1.8,3.4,2.2,5.6,1c0.5-0.3,1.3-0.8,1.6-0.6c1.6,0.8,3.9,0.7,4.4,3.1c0.3,1.5,1.2,2.6,2.8,3c0.2,0,0.4,0.3,0.4,0.5
	c0,1.2,0,2.4,0,3.8c-0.6,0-1.1,0-1.5,0c-5.5,0-10.9,0-16.4,0c-4.2,0-6.8,2.5-6.8,6.7c0,0.4,0,0.9,0,1.5
	C35.8,84.6,34.5,84.5,33.3,84.6z M75.3,61.9c-1.2-0.6-2.2-0.6-3.4,0.1c-1,0.6-2.2,1.1-3.3,1.4c-1.2,0.3-1.9,1-2.2,2.1
	c-0.1,0.4-0.3,0.7-0.4,1.1c-0.6,1.4-1.2,1.8-2.7,1.4c-0.3-0.1-0.6-0.4-0.7-0.7c-0.6-2.5-2-4.1-4.6-4.5c-0.3,0-0.6-0.2-0.8-0.3
	c-1.9-1.5-3.7-1.2-5.6,0c-0.9,0.5-1.4,0.2-2.1-0.4c-0.7-0.7-0.9-1.2-0.4-2.1c0.3-0.5,0.5-1.1,0.7-1.6c0.4-0.9,0.3-1.7-0.1-2.6
	c-0.6-1.2-1.2-2.4-1.5-3.6c-0.3-1.2-1-1.9-2.1-2.3c-0.4-0.1-0.7-0.3-1.1-0.4c-1.5-0.6-1.8-1.2-1.4-2.7c0.1-0.3,0.4-0.6,0.7-0.7
	c2.5-0.6,3.6-2.2,4.6-4.6c1-2.5,1.5-4.6,0.1-7c-0.2-0.3,0.4-1.1,0.7-1.5c0.3-0.3,1.1-0.7,1.3-0.5c2.3,1.4,4.4,1,6.9-0.1
	c2.4-1,4.1-2.1,4.7-4.7c0.2-0.9,1-0.7,1.6-0.7c0.6,0,1.3-0.1,1.5,0.7c0.6,2.5,2,4.1,4.6,4.5c0.2,0,0.5,0.1,0.7,0.3
	c1.9,1.5,3.9,1.2,5.8,0c0.9-0.6,1.4,0,1.9,0.5c0.6,0.6,1,1.1,0.4,1.9c-0.3,0.4-0.4,1-0.6,1.4c-0.6,1.1-0.5,2.2,0.1,3.3
	c0.6,1,1,2.2,1.4,3.3c0.3,1.2,0.9,1.9,2.1,2.2c0.4,0.1,0.7,0.3,1.1,0.4c1.5,0.6,1.8,1.2,1.4,2.7c-0.1,0.3-0.4,0.6-0.7,0.7
	c-2.6,0.6-3.7,2.3-4.6,4.7c-1,2.4-1.5,4.4,0,6.7c0.4,0.6-0.9,2.3-1.8,2.3C76.7,62.6,76,62.3,75.3,61.9z M57.9,89.4
	c0-0.2,0.5-0.7,0.8-0.7c3.6,0,7.1,0,10.7,0c0.3,0,0.8,0.4,0.8,0.7c0.1,2.4,0,4.9,0,7.4c-4.1,0-8.2,0-12.4,0
	C57.8,94.3,57.8,91.8,57.9,89.4z M45.4,88.7c0,2.7,0,5.3,0,8.1c-4,0-8.1,0-12.2,0c0-2.7,0-5.4,0-8.1C37.3,88.7,41.3,88.7,45.4,88.7z
	 M94.8,96.8c-4.1,0-8.1,0-12.2,0c0-2.7,0-5.3,0-8.1c4,0,8.1,0,12.2,0C94.8,91.5,94.8,94.1,94.8,96.8z M113.1,84.6
	c-1.4-1.7-2.7-3.4-4.1-5.1c-0.9-1.1-2.1-1.3-3.1-0.6c-1,0.8-1,2-0.1,3.2c2.6,3.2,5.2,6.5,7.8,9.7c1.2,1.5,2.5,1.4,3.7,0
	c2.3-2.9,4.6-5.8,6.9-8.7c0.4-0.5,0.8-1,1.2-1.5c0.6-0.9,0.3-2-0.5-2.7c-0.8-0.6-1.9-0.5-2.6,0.2c-0.3,0.2-0.5,0.5-0.7,0.8
	c-1.3,1.6-2.5,3.2-4,5c0-0.8,0-1.3,0-1.8c0-20.8,0-41.5,0-62.3c0-0.7,0-1.4,0-2.2c-0.1-4-2.9-7.4-6.9-7.9c-2.5-0.3-5.1-0.2-7.7-0.2
	c-1.2,0-1.9,1-1.8,2.1c0.1,1.1,0.8,1.9,2.1,1.9c1.7,0,3.4,0,5.1,0c3.7,0,5.2,1.5,5.2,5.2c0,21.2,0,42.3,0,63.5c0,0.4,0,0.8,0,1.2
	C113.3,84.5,113.2,84.5,113.1,84.6z M44.9,117.5c20.8,0,41.5,0,62.3,0c0.7,0,1.4,0,2.2,0c4-0.1,7.4-2.9,7.9-6.9
	c0.3-2.5,0.2-5.1,0.2-7.7c0-1.2-1-1.9-2.1-1.8c-1.1,0.1-1.9,0.8-1.9,2.1c0,1.8,0,3.6,0,5.4c0,3.2-1.6,4.8-4.8,4.8
	c-21.4,0-42.7,0-64.1,0c-0.3,0-0.7,0-1.3-0.1c1.9-1.5,3.6-2.9,5.3-4.3c1.1-0.9,1.3-2.1,0.6-3.1c-0.8-1-2-1-3.2-0.1
	c-3.2,2.6-6.5,5.2-9.7,7.8c-1.5,1.2-1.4,2.5,0,3.7c2.5,2,5,4,7.5,6c0.8,0.7,1.7,1.4,2.6,2c0.9,0.6,2,0.5,2.7-0.3
	c0.7-0.8,0.6-2-0.2-2.8c-0.3-0.3-0.6-0.5-0.8-0.7c-1.5-1.2-3.1-2.5-4.9-4C44,117.5,44.4,117.5,44.9,117.5z M51.7,47.3
	c-0.1,6.9,5.4,12.5,12.2,12.6c6.8,0,12.4-5.4,12.4-12.3c0.1-6.8-5.4-12.4-12.2-12.4C57.3,35.2,51.8,40.6,51.7,47.3z M72.2,47.5
	c0,4.5-3.6,8.2-8.1,8.3c-4.6,0-8.3-3.7-8.3-8.3c0-4.5,3.6-8.2,8.2-8.2C68.5,39.3,72.2,43,72.2,47.5z"/>
</svg>
                        <h3>Automation and Infrastructure as Code</h3>
                        <p>Automation is a fundamental component of SRE engineering. SREs leverage automation tools and practices to streamline routine tasks, deployments, and infrastructure management. Infrastructure as Code (IaC) techniques allow for defining and provisioning infrastructure resources programmatically, ensuring consistency, repeatability, and version control.</p>
                    </Col>
					<Col className='clouddevops_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M100.1,114.6c-2.4-2.4-4.7-4.8-7.1-7.1c-1.8,1-3.5,2.1-5.2,3c-3.9,2-8,3.5-12.3,4.4c-0.9,0.2-1.2,0.5-1.2,1.5
	c0.1,3,0,6,0,9c-6.8,0-13.5,0-20.5,0c0-1.3,0-2.7,0-4c0-1.8,0-3.6,0-5.3c0-0.3-0.4-0.9-0.7-1c-6.3-1.4-12.1-3.8-17.5-7.4
	c-0.1-0.1-0.2-0.1-0.4-0.1c-2.3,2.4-4.7,4.7-6.9,7c-4.9-4.9-9.7-9.7-14.5-14.5c2.2-2.2,4.6-4.6,7-6.9c-1-1.7-2-3.3-2.8-4.9
	c-2.2-4.1-3.7-8.4-4.7-12.9c-0.2-0.8-0.5-1.1-1.3-1.1c-3,0-6,0-9.1,0c0-2.7,0-5.4,0-8.2c6.7,0,13.5,0,20.3,0
	c1,13,6.6,23.5,17.1,31.2c7.6,5.6,16.3,8.1,25.7,7.7c10.5-0.5,19.6-4.5,27.1-11.9c7.5-7.4,11.4-16.5,12.1-27c6.8,0,13.5,0,20.3,0
	c0,2.6,0,5.3,0,8.1c-1.4,0-2.8,0-4.3,0c-1.7,0-3.4,0-5.1,0c-0.3,0-0.9,0.4-1,0.7c-1.4,6.3-3.9,12.1-7.4,17.5
	c-0.1,0.1-0.1,0.2-0.2,0.5c2.4,2.3,4.8,4.7,7.1,7.1C109.7,105,105,109.7,100.1,114.6z M36.2,64.1C35.9,72,40,82.5,50,88.1
	c10,5.6,20.1,5.3,29.6-1.1c8.1-5.4,11.9-13.2,12.3-22.9c-5.8,0-11.3,0-16.9,0c-1.4,7.1-5.3,10.7-11.3,10.6
	c-5.6-0.1-9.5-3.9-10.7-10.6C47.3,64.1,41.8,64.1,36.2,64.1z M66.1,2.7c0,6.9,0,13.6,0,20.2c3,0.6,5.8,0.9,8.6,1.7
	c2.8,0.7,5.4,1.9,8.2,2.9c3.3-5.7,6.7-11.5,10.1-17.5C84.5,5.4,75.6,3,66.1,2.7z M23,62c0.3-5.9,1.8-11.5,4.5-16.7
	C21.6,41.8,15.8,38.5,9.8,35C5.3,43.5,3,52.4,2.6,62C9.5,62,16.2,62,23,62z M125.4,61.9c-0.4-9.5-2.7-18.4-7.2-26.9
	c-5.9,3.4-11.8,6.8-17.7,10.2c2.7,5.3,4.2,10.8,4.5,16.7C111.8,61.9,118.6,61.9,125.4,61.9z M61.9,2.6C52.4,3,43.5,5.4,35,9.9
	c3.4,5.9,6.8,11.8,10.2,17.7c5.3-2.7,10.9-4.2,16.7-4.5C61.9,16.2,61.9,9.5,61.9,2.6z M96.4,11.9c-3.4,5.9-6.8,11.8-10.2,17.7
	c5,3.2,9,7.3,12.3,12.3c5.9-3.4,11.8-6.8,17.7-10.2C111.1,23.5,104.5,17,96.4,11.9z M11.8,31.6c5.9,3.4,11.8,6.8,17.7,10.2
	c3.3-5,7.3-9,12.3-12.3c-3.4-5.9-6.8-11.7-10.2-17.7C23.4,17,16.9,23.5,11.8,31.6z M57.1,63.9c-0.1,3.8,3,7,6.9,7
	c3.7,0.1,6.9-3.1,7-6.8c0.1-3.8-3.1-7-6.9-7C60.3,57.1,57.1,60.2,57.1,63.9z M85.6,47.6c0.9-0.9,1-2.1,0.7-3.3
	c-0.8-2.8-4.1-3.6-6.3-1.4c-3.5,3.5-7,7-10.5,10.5c-0.2,0.2-0.4,0.5-0.4,0.4c1.7,1.7,3.4,3.4,5,5C77.7,55.3,81.7,51.5,85.6,47.6z"/>
</svg>
                        <h3>Performance Optimization</h3>
                        <p>SREs focus on optimizing system performance and efficiency. This includes analyzing application and infrastructure bottlenecks, tuning configurations, and implementing performance improvements. SREs collaborate with development teams to identify and address performance-related issues, ensuring optimal system responsiveness and resource utilization.</p>
                    </Col>
					<Col className='clouddevops_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M18.8,66.7c-1.4-1.6-2.7-3-4-4.5c-2.4-2.7-5.1-5.1-7.1-8C-2.2,39.6,6.6,19,24,15.7c6.2-1.2,12.1-0.2,17.6,2.9
	c0.6,0.3,1.3,0.5,1.9,0.5c17,0,33.9,0,50.9,0c4.3,0,6.6,2.3,6.6,6.6c0,10.9,0,21.8,0,32.6c0,0.5,0,1,0,1.6c0.5,0,0.9,0,1.3,0
	c5.2,0,10.5,0,15.7,0c4,0,6.3,2.4,6.4,6.4c0,1.1,0,2.1,0,3.2c0,3.5-2.5,6-6.1,6c-5.3,0-10.6,0-15.8,0c-0.4,0-0.9,0-1.5,0
	c0,0.6,0,1,0,1.5c0,13.5,0,27,0,40.5c0,4.4-2.3,6.6-6.7,6.6c-22.8,0-45.6,0-68.4,0c-4.4,0-6.7-2.3-6.7-6.7c0-10.8,0-21.5,0-32.3
	c0-0.8-0.4-1.6-0.6-2.4c-0.4-1.8-1.1-3.6-1.3-5.4c-0.2-2.3,0-4.5-0.1-6.8C17.2,69.3,17.4,67.9,18.8,66.7z M92.3,75.7
	c-0.1,0.3-0.2,0.7-0.3,1C88,91,75,101,60.3,101c-10.3,0-18.9-4-25.6-11.9c-0.5-0.6-0.9-0.9-1.8-0.5c-2.9,1.2-5.9,1-8.8-0.3
	c-0.3-0.1-0.6-0.3-1-0.4c0,0.5,0,0.8,0,1.1c0,9.6,0,19.2,0,28.7c0,2.1,0.6,2.7,2.8,2.7c22.8,0,45.6,0,68.4,0c2.2,0,2.8-0.6,2.8-2.8
	c0-13.5,0-27,0-40.5c0-0.4,0-0.9,0-1.4C95.4,75.7,93.9,75.7,92.3,75.7z M60.1,46.3c-1.9,0.4-3.9,0.6-5.8,1.1c-0.5,0.1-1,0.7-1.2,1.1
	c-1,2.7-2.3,5.3-4.2,7.5c-1.9,2.2-3.9,4.2-5.9,6.3c-1.1,1.2-2.1,2.4-3.2,3.7c-0.4,0.5-0.4,0.9,0,1.5c0.5,0.8,0.8,1.7,0.8,2.6
	c0.1,1.9,0,3.8,0.1,5.7c0,0.7,0.2,1.4,0.5,2c4.2,8.4,13.7,12.9,23.1,11.1c10.2-2,18.1-11.4,17.2-22.5C80.5,54.9,71.2,46.4,60.1,46.3
	z M23,46.2c-0.3,0-0.5,0.1-0.8,0.1c-2.8,0.5-5.6-1-6.6-3.7c-1-2.7,0.2-5.8,2.7-7.2c2.5-1.4,5.8-0.8,7.3,1.7c0.9,1.4,1.8,1.7,3.2,1.5
	c0.3,0,0.7,0,1,0c0.9,0.1,1.4-0.2,1.9-1.1c1.3-2.3,4-3.3,6.5-2.6c2.6,0.7,4.3,3,4.3,5.7c0,2.6-1.7,5-4.2,5.6
	c-1.1,0.3-2.3,0.2-3.4,0.2c0,6.4,0,12.9,0,19.7c0.3-0.4,0.5-0.5,0.6-0.7c1.4-1.9,2.8-3.8,4.3-5.6c1.3-1.5,2.8-2.9,4.2-4.4
	c3.7-3.9,6-8.4,6.3-13.9c0.5-13.4-10.7-23.8-24.2-22.2C10.3,21.2,2.1,40,11.6,52.8c1.8,2.5,4.2,4.5,6.2,6.8c1.6,1.9,3,3.9,4.5,5.8
	c0.1,0.2,0.3,0.3,0.7,0.6C23,59.3,23,52.8,23,46.2z M53.7,35c19.6-1.9,32.4,6.5,38.9,24.9c1.4,0,2.9,0,4.6,0c0-0.5,0-0.9,0-1.3
	c0-10.9,0-21.8,0-32.7c0-2.2-0.6-2.8-2.8-2.8c-15.2,0-30.4,0-45.7,0c-0.4,0-0.8,0-1.4,0C50.6,26.7,52.7,30.6,53.7,35z M37.3,85.9
	c6.3,8.4,17.8,12.6,28.3,10.6c11.7-2.3,21-11.7,23.1-23.2c2.2-12.1-3.1-24-13.5-30.3c-6.2-3.8-12.9-5.1-20.1-3.8
	c-0.3,0.1-0.8,0.4-0.9,0.6c-0.1,1.1,0,2.2,0,3.4c14.8-3.1,26.5,6.2,30,16.9c3.7,11.4-1.1,23.8-11.4,29.8c-10.5,6.1-24.9,4.1-33.3-7
	C38.8,83.8,38.1,84.9,37.3,85.9z M105,71.7c4.6,0,9,0,13.5,0c1.2,0,1.8-0.8,1.9-2.1c0-1,0-1.9,0-2.9c0-1.9-0.7-2.6-2.5-2.6
	c-3.9,0-7.9,0-11.8,0c-0.3,0-0.7,0.1-1,0.1C105,66.7,105,69.1,105,71.7z M36.6,77.7c-5.1,0-10.2,0-15.3,0c-0.1,4.1,3.4,7.6,7.6,7.6
	C33.1,85.4,36.7,81.8,36.6,77.7z M30.8,65.8c0-7.8,0-15.5,0-23.2c-1.3,0-2.5,0-3.7,0c0,7.8,0,15.5,0,23.2
	C28.3,65.8,29.5,65.8,30.8,65.8z M93.2,64c0,2.6,0,5.1,0,7.6c2.6,0,5.2,0,7.8,0c0-2.6,0-5.1,0-7.6C98.3,64,95.7,64,93.2,64z
	 M21.2,73.6c5.2,0,10.3,0,15.4,0c0-1.3,0-2.5,0-3.7c-5.2,0-10.3,0-15.4,0C21.2,71.1,21.2,72.3,21.2,73.6z M23,40.6
	c0-1-0.8-1.9-1.8-1.9c-1,0-2,0.9-2,1.9c0,1,0.8,1.9,1.8,1.9C22.1,42.5,23,41.6,23,40.6z M36.7,38.7c-1,0-1.9,0.9-1.9,1.8
	c0,1,0.9,2,1.9,2c1,0,1.9-0.9,1.9-2C38.6,39.5,37.7,38.7,36.7,38.7z M15.5,14.2c1.2-0.6,2.3-1.2,3.5-1.8c-1.3-2.7-2.6-5.2-3.9-7.8
	c-1.3,0.6-2.3,1.2-3.5,1.7C12.9,9,14.2,11.6,15.5,14.2z M38.9,12.4c1.2,0.6,2.3,1.1,3.5,1.7c1.3-2.7,2.6-5.2,3.9-7.8
	C45,5.8,44,5.2,42.8,4.7C41.5,7.3,40.2,9.8,38.9,12.4z M30.8,3.6c-1.3,0-2.5,0-3.7,0c0,2.6,0,5.1,0,7.7c1.3,0,2.4,0,3.7,0
	C30.8,8.8,30.8,6.2,30.8,3.6z M54.3,112.6c11.7,0,23.3,0,34.9,0c0-1.3,0-2.6,0-3.8c-11.7,0-23.3,0-34.9,0
	C54.3,110.2,54.3,111.4,54.3,112.6z M30.9,108.8c0,1.3,0,2.5,0,3.8c1.3,0,2.5,0,3.8,0c0-1.3,0-2.5,0-3.8
	C33.4,108.8,32.2,108.8,30.9,108.8z M42.5,108.8c-1.3,0-2.5,0-3.8,0c0,1.3,0,2.5,0,3.8c1.3,0,2.5,0,3.8,0
	C42.5,111.4,42.5,110.1,42.5,108.8z M46.5,112.6c1.3,0,2.5,0,3.8,0c0-1.3,0-2.5,0-3.8c-1.3,0-2.5,0-3.8,0
	C46.5,110.1,46.5,111.4,46.5,112.6z M54.3,60.1c0.1-3.3,2.7-5.9,5.9-5.9c3.1,0,5.7,2.6,5.7,5.8c0,3.2-2.6,5.8-5.9,5.8
	c-0.6,0-1.2,0-1.9,0c0,1.7,0,3.3,0,4.9c0,1.6,0,3.2,0,4.9c1.4,0,2.6,0,4,0c0-2,0-4,0-5.9c6-1.5,8.8-7.2,7.4-12.2
	c-1.4-5-6.4-8.1-11.6-7c-5.1,1.1-8.4,6.1-7.5,11.4c1.2,0,2.5,0,3.8,0C54.3,61.3,54.3,60.7,54.3,60.1z M62,79.6c-1.3,0-2.5,0-3.8,0
	c0,1.3,0,2.6,0,3.8c1.3,0,2.5,0,3.8,0C62,82.1,62,80.8,62,79.6z"/>
</svg>
                        <h3>Reliability Engineering</h3>
                        <p>Reliability engineering involves proactively addressing potential points of failure and designing systems for resilience. SREs conduct risk assessments, implement fault tolerance mechanisms, and establish disaster recovery strategies. They also perform incident postmortems to learn from failures and prevent similar issues in the future.</p>
                    </Col>
					<Col className='clouddevops_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M62.2,91.3c-1.5,1.5-3,2.9-4.5,4.5c-1.7-1.7-3.5-3.5-5.2-5.3c-0.8-0.8-1.7-1.5-2.9-1c-1.2,0.5-1.4,1.6-1.4,2.8
	c0,2.2,0,4.4,0,6.7C39,99,30,99,20.8,99c0-2.5,0-4.8,0-7.2c0-0.9,0-1.8-0.1-2.7c-0.1-1.2-0.9-2-2.1-2c-1.2,0-2,0.7-2.2,1.9
	c-0.1,0.4-0.1,0.9-0.1,1.3c0,3.3,0,6.7,0,10c0,2.5,0.6,3.1,3,3.1c10,0,20,0,30,0c2.4,0,3-0.6,3.1-3.1c0-0.7,0.4-1.3,0.6-2
	c0.7,0.3,1.5,0.5,1.9,0.9c3.1,3,6.1,6.1,9.2,9.2c1.1,1.1,1.3,1.9,0.1,3c-3.2,3-6.2,6.2-9.4,9.3c-0.5,0.4-1.2,0.6-1.8,0.9
	c-0.2-0.6-0.6-1.3-0.7-1.9c-0.1-2.6-0.6-3.2-3.4-3.2c-13.5,0-26.9,0-40.4,0c-4.6,0-6.6-2-6.6-6.6c0-12.5,0-24.9,0-37.4
	c0-4.3,2-6.3,6.3-6.4c15,0,30,0,45.1,0c2.7,0,3.3,0.6,3.3,3.4c0,2.8,0.1,5.7,0,8.5c0,0.8-0.5,1.8-1,2.4c-1.5,1.7-1.6,2.6,0.1,4.2
	c2,2.1,4.1,4.1,6.1,6.2C62.1,91.1,62.1,91.1,62.2,91.3z M73.4,63.1c0.8-0.8,1.6-1.4,2.8-1c1.2,0.5,1.5,1.5,1.5,2.7
	c0,2.3,0,4.5,0,6.8c10.7,0,21.2,0,31.7,0c0-1.7,0-3.3,0-5c0-1.4,0.6-2.4,2.1-2.5c1.3-0.1,2.2,0.9,2.2,2.5c0,2,0,4,0,6
	c0,2.9-0.5,3.4-3.4,3.4c-11.2,0-22.5,0-33.7,0c-2.8,0-3.3-0.5-3.4-3.3c0-0.6-0.5-1.2-0.8-1.8c-0.6,0.3-1.2,0.4-1.7,0.8
	c-3.2,3.1-6.3,6.3-9.5,9.4c-1.1,1.1-1.1,1.8,0,2.9c3.2,3.1,6.2,6.2,9.4,9.3c0.5,0.4,1.2,0.6,1.8,0.9c0.2-0.6,0.7-1.2,0.7-1.9
	c0.1-2.7,0.6-3.2,3.3-3.2c14.2,0,28.4,0,42.6,0c4.4,0,6.6-2.2,6.6-6.7c0-10,0-19.9,0-29.9c0-4-2.1-6.1-6.1-6.1
	c-17.5,0-34.9,0-52.4,0c-3.8,0-6,2.1-6,6c0,5.1,0,10.1,0,15.2c0,2.3,0,4.6,0,7.5C65.6,71,69.5,67.1,73.4,63.1z M74.4,23.8
	c0,9.6,8,17.5,17.6,17.5c9.6,0,17.5-7.9,17.5-17.5c0-9.7-7.9-17.4-17.5-17.4C82.2,6.3,74.4,14.1,74.4,23.8z M10.9,43.4
	c0,9.6,8,17.5,17.6,17.5C38.2,60.8,46,53,46,43.3c0-9.7-7.9-17.4-17.6-17.4C18.7,26,10.9,33.8,10.9,43.4z"/>
</svg>
                        <h3>Collaboration and Communication</h3>
                        <p>Effective collaboration and communication are essential components of SRE engineering. SREs work closely with development teams, product owners, and stakeholders to align priorities, gather requirements, and implement solutions. Regular communication channels, such as meetings and status updates, facilitate cross-functional collaboration and knowledge sharing.</p>
                    </Col>
                    
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default Clouddevops