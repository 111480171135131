import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { Aboutus, Analytics, Appdevelopment, Career, Clouddevops, Cloudmigration, Contactus, Dataintegration, Digitaltransformation, Homepage, Servicespage } from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage/>,
  },
  {
    path: "/services",
    element: <Servicespage/>,
  },
  {
    path: "/career",
    element: <Career />,
  },
  {
    path: "/about",
    element: <Aboutus />,
  },
  {
    path: "/analytics",
    element: <Analytics/>,
  },
  {
    path: "/itstaffing",
    element: <Servicespage />,
  },
  {
    path: "/appdevelopment",
    element: <Appdevelopment />,
  },
  {
    path: "/clouddevops",
    element: <Clouddevops />,
  },
  {
    path: "/cloudmigration",
    element: <Cloudmigration />,
  },
  {
    path: "/dataintegration",
    element: <Dataintegration/>,
  },
  {
    path: "/digitaltransformation",
    element: <Digitaltransformation />,
  },
  {
    path: "/contactus",
    element: <Contactus />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode basename="/dhansol">
    <RouterProvider router={router} />
  </React.StrictMode>
);