import './analytics.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const Analytics = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>Our Services</h1>
    </div>
    <div className='analytics_page'>
        <Row className='align_margin'>
            <Col xs={{ span: 23 }} lg={{ span: 13 }}>
            <div className='bg_box1'></div>
            
                
            </Col>
            <Col className='services_div_right' xs={{ span: 23 }} lg={{ span: 11 }}>
                <h1>Analytics</h1>
                <div className='line'></div>
				<p>
				Empower your business with valuable insights. Let our us unleash the power of data. We are technology enthusiasts and business SMEs, who breath data and infer knowledge from it. Our expertise has been empowering many organizations with business intelligence and predictive analytics and influencing their decisions with intelligence driven analytics.</p>
				<br/>
				<p>Follow our Use Case Studies to understand how we influenced organizations in the past.</p>
            </Col>
        </Row>
        <div className='analytics_box_container'>
        

        <Row className='analytics_s2_container align_margin'>
            
            <Col className='analytics_div_right' xs={{ span: 24,offset:0 }} lg={{ span: 24, offset:0 }}>
                <div className='analytics_s2_box_container'>
                    <Row>
                    <Col className='analytics_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M66,101.4c5.4,0,10.6,0,15.8,0c1.3-14,14.8-21.3,25.4-19.4c10.7,2,18.4,11.4,18,22.4
	c-0.4,10.7-8.7,19.5-19.8,20.6c-11.3,1.1-22.3-7.2-23.6-19.5c-5.2,0-10.5,0-15.8,0c0,0.7,0,1.4,0,2.1c-0.1,3.1-2.6,5.7-5.7,5.7
	c-10.6,0-21.3,0-31.9,0c-3.2,0-5.6-2.6-5.7-5.8c0-0.6,0-1.3,0-2.1c-2,0-4,0-6,0c-6.5-0.1-11.9-4.4-13.4-10.7
	c-0.3-1.2-0.4-2.4-0.4-3.7c0-4.9,0-9.8,0-14.7C2.9,68,8.8,62,17.2,62c7.9,0,15.8,0,23.7,0c0.4,0,0.9,0,1.4,0c0-0.8,0-1.5,0-2.2
	c0.1-3.1,2.6-5.7,5.7-5.7c10.6,0,21.3,0,31.9,0c3.2,0,5.7,2.6,5.8,5.8c0,0.7,0,1.3,0,2.1c0.5,0,0.9,0.1,1.3,0.1c7.9,0,15.9,0,23.8,0
	c6.2,0,10.4-4.2,10.5-10.4c0-4.3,0-8.5,0-12.8c0-6.1-4.2-10.3-10.3-10.3c-1.8,0-3.5,0-5.4,0c0,0.8,0,1.4,0,2.1
	c-0.1,3.2-2.6,5.7-5.8,5.7c-10.6,0-21.2,0-31.8,0c-3.2,0-5.7-2.6-5.8-5.8c0-0.6,0-1.2,0-1.9c-3.9,0-7.8,0-11.7,0
	c0,0.3-0.1,0.7-0.1,1c0,2.5,0,2.5-2.5,3.1c-0.6,0.2-1.2,0.3-1.8,0.5c-1.3,0.4-1.6,1.2-1,2.4c0.7,1.3,1.4,2.6,2.1,3.8
	c-2.6,2.6-5.1,5.1-7.8,7.7c-1.2-0.7-2.5-1.4-3.8-2.2c-1.1-0.6-2-0.2-2.3,1c-0.3,1.2-0.6,2.4-1,3.5c-0.1,0.3-0.6,0.7-0.8,0.7
	c-3.4,0.1-6.7,0-10.2,0c-0.4-1.5-0.8-2.9-1.2-4.4c-0.3-1.2-1.2-1.5-2.2-1c-1.1,0.6-2.2,1.2-3.2,1.8c-0.5,0.3-0.9,0.4-1.4-0.1
	c-2.3-2.4-4.7-4.7-7.1-7.1c0.7-1.2,1.3-2.3,2-3.5c0.9-1.7,0.7-2.3-1.1-2.8c-1.1-0.3-2.2-0.5-3.2-0.9c-0.3-0.1-0.8-0.6-0.8-1
	c-0.1-3.1,0-6.2-0.1-9.2c0-0.7,0.3-0.9,0.9-1.1C4.9,20.6,6,20.3,7.1,20c1.5-0.4,1.8-1.2,1.1-2.5c-0.7-1.2-1.4-2.4-2-3.5
	c2.6-2.6,5.1-5.1,7.8-7.8c1,0.6,2.2,1.3,3.5,2c1.3,0.7,2.1,0.4,2.5-1c0.4-1.4,0.8-2.8,1.2-4.2c3.4,0,6.8,0,10.2,0
	c0.3,0,0.7,0.4,0.8,0.7c0.4,1.2,0.7,2.4,1,3.7c0.3,1.2,1.2,1.6,2.3,1c1.3-0.7,2.6-1.5,3.9-2.2c2.4,2.4,4.8,4.8,7.2,7.3
	c0.2,0.2,0.2,0.8,0,1.1c-0.6,1.1-1.2,2.2-1.9,3.3c-0.5,0.9-0.1,1.9,0.9,2.2c1.5,0.4,2.9,0.8,4.4,1.3c0,1.1,0,2.1,0,3.3
	c3.9,0,7.8,0,11.8,0c0-0.5,0-1.1,0-1.7c0-3.5,2.5-6,6-6c10.5,0,21,0,31.4,0c3.4,0,5.9,2.5,6,5.9c0,0.6,0,1.2,0,2c1.8,0,3.5,0,5.2,0
	c8.6,0,14.5,5.9,14.5,14.5c0,4.2,0,8.5,0,12.7c0,8.2-6,14.2-14.2,14.2c-7.9,0-15.8,0-23.7,0c-0.4,0-0.9,0-1.5,0c0,0.7,0,1.3,0,2
	c-0.1,3.4-2.6,5.9-6,5.9c-10.5,0-21,0-31.4,0c-3.4,0-5.9-2.6-6-6c0-0.6,0-1.1,0-1.8c-0.5,0-0.9-0.1-1.3-0.1c-7.9,0-15.9,0-23.8,0
	C11,66,6.8,70.1,6.8,76.4c0,4.9,0,9.9,0,14.8c0,6,4.2,10.2,10.2,10.3c1.8,0,3.6,0,5.5,0c0-0.7,0-1.4,0-2c0.1-3.2,2.6-5.8,5.8-5.8
	c10.6,0,21.2,0,31.8,0c3.2,0,5.7,2.6,5.8,5.7C66,100,66,100.6,66,101.4z M121.2,103.4c0-9.7-8-17.7-17.7-17.7
	c-9.8,0-17.8,8-17.8,17.8c0,9.7,8,17.7,17.7,17.7C113.2,121.2,121.2,113.2,121.2,103.4z M6.9,24.2c0,1.3,0,2.6,0,3.8
	c0,0.7,0.2,1.1,0.9,1.2c0.4,0.1,0.9,0.3,1.3,0.3c1.5,0.2,2.5,0.9,2.8,2.5c0.1,0.7,0.7,1.3,0.9,2c0.1,0.5,0.2,1.1,0,1.5
	c-0.5,1.1-1.1,2.1-1.8,3.2c0.9,0.9,1.8,1.6,2.6,2.5c0.6,0.7,1.2,0.8,2,0.2c1.7-1.3,3.4-1.9,5.4-0.4c0.3,0.2,0.7,0.3,1,0.4
	c0.9,0.2,1.2,0.7,1.5,1.5c0.8,3.1,0.8,3.1,4.1,3.2c1.3,0,1.9-0.3,2.1-1.6c0.2-1.9,1-3.3,3.1-3.6c0.5-0.1,1-0.6,1.6-0.7
	c0.5-0.1,1.1-0.2,1.5,0c1.1,0.5,2.1,1.1,3,1.7c1.2-1.2,2.3-2.3,3.5-3.5c-0.4-0.8-0.9-1.7-1.4-2.5c-0.6-0.9-0.4-1.6-0.1-2.6
	c0.8-2.6,2.2-4.3,5-4.4c0.2,0,0.6-0.5,0.6-0.8c0.1-1.4,0-2.8,0-4.2c-1-0.3-1.8-0.6-2.7-0.8c-1.2-0.2-1.6-0.9-2.2-1.9
	c-1.3-2.4-1.3-4.5,0.5-6.5c0.2-0.2,0.2-0.8,0-0.9c-1-1.1-2-2.2-2.9-3.2c-0.6,0.3-1,0.5-1.2,0.7c-1.7,1.3-3.4,1.8-5.3,0.4
	c-0.3-0.3-0.8-0.4-1.3-0.5c-0.7-0.2-1.1-0.6-1.2-1.3c-0.3-1.1-0.4-2.6-1.1-3.2c-0.8-0.6-2.2-0.1-3.3-0.2C24.5,6.7,24,7,23.8,8
	c-0.1,0.8-0.3,1.5-0.6,2.2c-0.2,0.5-0.6,0.9-1.1,1.1c-1.2,0.6-2.6,1.6-3.8,1.5c-1.3-0.1-2.5-1.2-3.8-1.9c0,0-0.1,0-0.3,0
	c-0.4,0.5-0.9,1.1-1.5,1.6c-0.6,0.6-1.2,1.1-1.8,1.6c0.8,1.3,1.4,2.4,1.9,3.5c0.2,0.3,0.2,0.9,0,1.2c-0.5,1.3-0.8,3-1.7,3.9
	C10.1,23.6,8.4,23.7,6.9,24.2z M83.7,32.4c5.1,0,10.3,0,15.4,0c1.6,0,2.3-0.7,2.3-2.3c0-2.4,0-4.8,0-7.1c0-1.6-0.7-2.3-2.3-2.3
	c-10.3,0-20.5,0-30.8,0c-1.6,0-2.3,0.7-2.3,2.3c0,2.3,0,4.6,0,6.9c0,1.9,0.7,2.6,2.6,2.6C73.6,32.4,78.7,32.4,83.7,32.4z M64.1,58.1
	c-5.2,0-10.4,0-15.5,0c-1.6,0-2.3,0.7-2.3,2.3c0,2.4,0,4.8,0,7.1c0,1.6,0.7,2.3,2.3,2.3c10.3,0,20.5,0,30.8,0c1.6,0,2.3-0.7,2.3-2.3
	c0-2.3,0-4.6,0-6.9c0-1.9-0.7-2.6-2.6-2.6C74.2,58.1,69.1,58.1,64.1,58.1z M44.3,109.3c5.1,0,10.3,0,15.4,0c1.6,0,2.3-0.7,2.3-2.3
	c0-2.4,0-4.8,0-7.1c0-1.6-0.7-2.3-2.3-2.3c-10.3,0-20.5,0-30.8,0c-1.6,0-2.3,0.7-2.3,2.3c0,2.3,0,4.6,0,6.9c0,1.9,0.7,2.6,2.6,2.6
	C34.2,109.3,39.2,109.3,44.3,109.3z M97.2,108.7c-0.7-0.9-1.4-1.8-2-2.6c-1.1,1-2,1.8-2.7,2.5c1.8,1.8,3.5,3.5,5.2,5.2
	c5.5-5.5,11.2-11.2,16.8-16.8c-0.8-0.8-1.7-1.6-2.8-2.7C107,98.9,102.2,103.6,97.2,108.7z M26.6,38.4c-6.5,0-11.8-5.4-11.8-11.8
	c0-6.5,5.4-11.8,11.9-11.8c6.5,0,11.8,5.4,11.8,11.9C38.4,33.2,33.1,38.4,26.6,38.4z M34.5,26.6c0-4.4-3.5-7.9-7.8-8
	c-4.3,0-7.9,3.6-7.9,7.9c0,4.3,3.5,7.8,7.8,7.9C30.9,34.5,34.4,31,34.5,26.6z M73.8,24.6c-1.3,0-2.6,0-3.8,0c0,1.3,0,2.6,0,3.8
	c1.3,0,2.5,0,3.8,0C73.8,27.1,73.8,25.9,73.8,24.6z M81.7,28.4c0-1.3,0-2.5,0-3.8c-1.3,0-2.6,0-3.8,0c0,1.3,0,2.5,0,3.8
	C79.2,28.4,80.4,28.4,81.7,28.4z M89.6,28.4c0-1.3,0-2.5,0-3.8c-1.3,0-2.6,0-3.8,0c0,1.3,0,2.5,0,3.8C87.1,28.4,88.3,28.4,89.6,28.4
	z M97.4,28.4c0-1.3,0-2.5,0-3.7c-1.3,0-2.6,0-3.8,0c0,1.3,0,2.5,0,3.7C94.9,28.4,96.2,28.4,97.4,28.4z M50.3,62.1c0,1.3,0,2.6,0,3.8
	c1.3,0,2.6,0,3.8,0c0-1.3,0-2.5,0-3.8C52.7,62.1,51.5,62.1,50.3,62.1z M61.9,62.1c-1.3,0-2.5,0-3.8,0c0,1.3,0,2.5,0,3.8
	c1.3,0,2.5,0,3.8,0C61.9,64.5,61.9,63.3,61.9,62.1z M66,65.9c1.3,0,2.6,0,3.8,0c0-1.3,0-2.6,0-3.8c-1.3,0-2.5,0-3.8,0
	C66,63.4,66,64.6,66,65.9z M73.9,65.9c1.3,0,2.6,0,3.8,0c0-1.3,0-2.6,0-3.8c-1.3,0-2.5,0-3.8,0C73.9,63.4,73.9,64.6,73.9,65.9z
	 M34.3,101.5c-1.3,0-2.5,0-3.8,0c0,1.3,0,2.5,0,3.8c1.3,0,2.5,0,3.8,0C34.3,104,34.3,102.7,34.3,101.5z M38.4,105.3
	c1.3,0,2.6,0,3.8,0c0-1.3,0-2.5,0-3.8c-1.3,0-2.5,0-3.8,0C38.4,102.8,38.4,104.1,38.4,105.3z M50.1,105.3c0-1.3,0-2.6,0-3.8
	c-1.3,0-2.5,0-3.8,0c0,1.3,0,2.5,0,3.8C47.6,105.3,48.9,105.3,50.1,105.3z M54.2,105.3c1.3,0,2.5,0,3.8,0c0-1.3,0-2.6,0-3.8
	c-1.3,0-2.5,0-3.8,0C54.2,102.9,54.2,104.1,54.2,105.3z"/>
</svg>
                        <h3>Data Preprocessing</h3>
                        <p>Let us collect, transform and normalize your data to be clean and makes sense for any analysis that matters to you.</p>
                    </Col>
                    <Col className='analytics_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
					<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M66.1,63.5c0.4,0,0.7,0,1.2,0c0,2.4,0,4.7,0,7c0,1.8,0.7,2.5,2.5,2.5c1.5,0,3,0,4.4,0c0.8,0,1.3,0.2,1.8,0.8
	c1.6,2,4.6,2.1,6.4,0.4c1.8-1.8,1.8-4.7-0.1-6.5c-1.9-1.7-4.8-1.6-6.4,0.5c-0.5,0.6-1,0.8-1.7,0.8c-0.9-0.1-1.9,0-2.9,0
	c0-1.9,0-3.6,0-5.4c2.4-0.3,2.6-0.6,2.6-3.2c0-1.6,0-3.2,0-5c2,0,3.9,0,5.8,0c0.2,0,0.5,0.4,0.7,0.6c1.8,2.1,4.7,2.4,6.6,0.5
	c1.8-1.7,1.8-4.6,0.1-6.4c-1.9-1.9-4.8-1.6-6.6,0.4c-0.3,0.3-0.7,0.6-1.1,0.6c-1.8,0.1-3.5,0-5.5,0c0-1.7,0-3.3,0-5
	c0-2.6-0.1-2.8-2.7-3.2c0-1.7,0-3.5,0-5.4c1.2,0,2.4-0.1,3.6,0c0.4,0,0.7,0.4,1,0.8c1.3,1.5,3.4,2,5.2,1.2c1.8-0.8,2.9-2.7,2.6-4.8
	c-0.3-1.9-1.8-3.5-3.8-3.7c-1.6-0.2-2.9,0.4-4.1,1.7c-0.3,0.4-0.9,0.7-1.4,0.7c-1.6,0.1-3.3,0-4.9,0c-1.5,0-2.2,0.8-2.2,2.3
	c0,2.4,0,4.7,0,7.2c-0.5,0-0.8,0-1.2,0c0-7,0-14,0-21.3c2.7,0.6,5.3,1,7.8,1.8c16.8,5.7,24.9,23.3,20,38.9c-0.8,2.5-2.1,5-3.5,7.3
	c-4.7,7.6-7.2,15.9-7.6,24.8c0,0.2-0.1,0.4-0.1,0.7c-5.5,0-11,0-16.6,0C66.1,84.7,66.1,74.2,66.1,63.5z M61.1,22.1
	c-8.6,0.9-15.8,4.7-21.4,11.4c-7.9,9.4-9.4,23.7-3.6,33.8c1,1.8,2,3.5,3,5.3c2.5,4.4,4.2,9,5.1,14c0.5,2.9,0.8,5.8,1.2,8.7
	c5.5,0,11,0,16.5,0c0-5.8,0-11.6,0-17.4c-1,0.2-1.4-0.3-1.7-1.1c-0.2-0.7-0.4-1.4-0.8-2c-0.3-0.5-0.8-0.9-1.3-1.2
	c-1.4-0.7-2.9-1.3-4.3-1.8c-0.5-0.2-1.2-0.3-1.7-0.1C49,73,49,73,46.6,70.6c-2.3-2.3-2.3-2.3-1-5.2c0.3-0.7,0.3-1.3,0-2
	c-0.7-1.3-1.2-2.7-1.7-4.1c-0.3-0.8-0.6-1.2-1.4-1.5c-3-1.1-3-1.1-3-4.4c0-3.4,0-3.3,3.1-4.6c0.5-0.2,1-0.8,1.2-1.3
	c0.7-1.4,1.3-2.9,1.9-4.4c0.2-0.4,0.3-1.1,0.1-1.4c-1.8-2.5-1-4.2,1.2-6c2.2-1.8,2.1-2.1,4.7-0.8c0.9,0.4,1.7,0.5,2.6-0.1
	c0.9-0.5,1.9-1.1,2.9-1.2c1.7-0.3,2.5-1.3,2.9-2.9c0.2-1,0.5-2.1,1.9-1.9c0-2.3,0-4.4,0-6.6C61.6,22.1,61.3,22.1,61.1,22.1z
	 M50.7,105.6c4.7,0,9.5,0,14.2,0c4.2,0,8.4,0,12.6,0c3.4,0,5.8-2.9,5.1-6.1c-12.4,0-24.7,0-37.2,0C45,103,47.2,105.6,50.7,105.6z
	 M52.4,53.3c0,5.6,4.3,10.8,9.5,11.3c0-7.5,0-15,0-22.6C56.8,42.5,52.4,47.7,52.4,53.3z M55.3,115.7c5.8,0.1,11.6,0.1,17.4,0
	c3.2,0,5.4-2.9,4.8-6c-9,0-18,0-27,0C50.2,113.1,52.2,115.7,55.3,115.7z M60.4,125.9c2.4,0.1,4.7,0.1,7.1,0c3.1-0.1,5.3-3,4.7-6
	c-5.5,0-10.9,0-16.5,0C55.4,123.2,57.4,125.8,60.4,125.9z M66,4.3C66,2.9,65.2,2,64,2c-1.2,0-2,0.9-2,2.3c0,2.3,0,4.6,0,7
	c0,1.4,0.9,2.2,2.1,2.2c1.2,0,1.9-0.9,2-2.2c0-1.2,0-2.4,0-3.5C66,6.6,66.1,5.4,66,4.3z M16.5,60.7c1.3,0,2.2-0.8,2.2-1.9
	c0-1.1-0.8-2.1-2.1-2.1c-2.4-0.1-4.9-0.1-7.3,0c-1.3,0-2.1,1-2,2.1c0.1,1.1,0.9,1.9,2.1,1.9c1.2,0,2.4,0,3.5,0
	C14.2,60.7,15.4,60.7,16.5,60.7z M118.5,60.7c1.3,0,2.1-0.8,2.1-1.9c0.1-1.2-0.7-2.1-2-2.2c-2.5-0.1-5-0.1-7.5,0c-1.2,0-2,1-2,2.1
	c0,1.1,0.9,1.9,2.1,1.9c1.2,0,2.4,0,3.5,0C116.1,60.7,117.3,60.7,118.5,60.7z M17.2,88c2.4-1.4,4.9-2.7,7.2-4.2
	c0.9-0.5,0.9-1.6,0.4-2.5c-0.6-0.9-1.5-1.3-2.4-0.8c-2.3,1.3-4.6,2.6-6.9,4c-0.8,0.5-1,1.5-0.6,2.3c0.3,0.6,1.1,1,1.5,1.4
	C16.8,88,17,88,17.2,88z M91.9,9.5c-0.9-0.4-1.8,0-2.4,0.9c-1.2,2-2.4,4.1-3.5,6.1c-0.7,1.2-0.4,2.4,0.6,3c1.1,0.6,2.2,0.3,3-1
	c1.1-1.9,2.2-3.9,3.3-5.8c0.2-0.4,0.3-0.8,0.6-1.6C93,10.6,92.6,9.8,91.9,9.5z M112.6,84.5c-2.3-1.4-4.6-2.7-6.9-4
	c-1-0.5-1.9-0.1-2.5,0.9c-0.5,0.9-0.4,1.9,0.5,2.5c2.3,1.4,4.6,2.8,6.9,4c1.3,0.7,2.7-0.4,2.7-2.2C113.2,85.4,113,84.8,112.6,84.5z
	 M42.4,17.7c0-0.2,0-0.4-0.1-0.5c-1.4-2.4-2.7-4.9-4.2-7.2c-0.5-0.8-1.5-0.9-2.4-0.4c-0.9,0.5-1.4,1.5-0.9,2.4
	c1.3,2.4,2.6,4.7,4.1,7c0.3,0.5,1.5,0.7,2.1,0.6c0.7-0.2,1.1-1,1.7-1.6C42.6,17.9,42.5,17.8,42.4,17.7z M14.8,30.8
	c-0.4,0.9,0,1.8,0.9,2.4c2,1.1,3.9,2.3,5.9,3.4c1.4,0.8,2.6,0.6,3.2-0.6c0.6-1.1,0.2-2.2-1.2-2.9c-1.9-1.1-3.7-2.2-5.6-3.2
	c-0.4-0.2-0.9-0.4-1.7-0.7C15.8,29.7,15.1,30.1,14.8,30.8z M110.3,29.7c-2.1,1.1-4.2,2.4-6.3,3.6c-1.1,0.7-1.4,1.8-0.8,2.8
	c0.6,1,1.7,1.3,2.9,0.6c2.1-1.2,4.1-2.3,6.1-3.6c0.6-0.4,0.9-1.1,1.1-1.4C113.3,29.8,111.7,28.9,110.3,29.7z"/>
</svg>
                        <h3>Business Intelligence</h3>
                        <p>Build analytics and visualization that shows that brings light and provide insights into your current performance </p>
                    </Col>
                    <Col className='analytics_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M80.1,74.2c5.9,1,10.2,4.1,12.6,9.4c2.5,5.8,1.8,11.3-1.9,16.4c-0.4,0.5-0.7,1.3-0.7,1.9c0,7.7,0,15.3,0,23
	c0,0.5,0,0.9,0,1.4c-16.8,0-33.4,0-50.2,0c0-0.4,0-0.9,0-1.3c0-4.1,0-8.2,0-12.3c0-10.7,3.9-19.8,11.3-27.4c0.3-0.4,0.7-0.9,0.7-1.3
	c0-5.9,0-11.7,0-17.6c0-0.1-0.1-0.1-0.1-0.3c-3.9,0.1-7.5-1-10.5-3.7c-3-2.8-4.4-6.3-4.6-10.4c-3.3,0-6.4,0-9.6,0
	c-0.3,0-0.7,0.3-1,0.6c-2.2,2.2-4.4,4.5-6.7,6.6c-0.4,0.4-1.1,0.6-1.7,0.8c-0.3,0.1-0.8,0.1-1.1,0c-1.8-0.2-3.2,0-4.3,1.9
	c-1.4,2.2-4.3,2.7-6.8,1.7c-2.4-1-3.9-3.5-3.7-6.1c0.2-2.6,2.2-4.7,4.8-5.3c2.4-0.5,5,0.6,6.4,2.8c0.3,0.4,0.7,1.1,1,1
	c1.2-0.1,2.5,0.6,3.6-0.5c2.3-2.3,4.5-4.6,6.9-6.8c0.4-0.4,1.1-0.7,1.6-0.7c3.4-0.1,6.8,0,10.1,0c0.2-3-0.7-4-3.5-4
	c-3.4,0-6.8,0-10.2,0c-0.7,0-1,0.2-1.3,0.8c-1.4,2.6-4.1,3.7-6.8,3c-2.7-0.7-4.5-3.1-4.5-5.8c0-2.8,1.9-5.2,4.6-5.9
	c2.7-0.7,5.3,0.5,6.8,3.1c0.2,0.3,0.5,0.7,0.7,0.7c3.3,0,6.7,0,9.9,0c0.1-1.4,0.2-2.6,0.4-4c-1.9,0-3.9-0.1-5.8,0
	c-1,0-1.6-0.3-2.3-1c-2.1-2.2-4.3-4.2-6.4-6.4c-0.8-0.9-1.7-0.6-2.7-0.7c-1-0.1-1.7,0.1-2.3,1.2c-1.3,2.4-4.1,3.3-6.7,2.6
	c-2.5-0.8-4.3-3.2-4.3-5.9c0-2.7,1.8-5,4.4-5.7c2.6-0.7,5.4,0.3,6.7,2.7c0.5,0.9,1,1.2,1.9,1.1c2.8-0.5,4.8,0.5,6.6,2.7
	c1.4,1.7,3,3.1,4.6,4.6c0.4,0.4,1.1,0.7,1.7,0.7c1.5,0.1,3.1,0,4.6,0c0.7,0,0.9-0.2,1.1-0.9c0.7-3,1.3-6,2.1-8.9
	c3.5-13.3,16-21.8,29.6-20.2c15,1.7,26.6,11.9,30.1,26.5c0.3,1.1,0.4,2.3,0.7,3.6c1.7,0,3.3,0,5,0c0.3,0,0.7-0.3,1-0.6
	c2.2-2.2,4.4-4.5,6.7-6.6c0.4-0.4,1.1-0.6,1.7-0.7c0.4-0.1,0.9-0.1,1.4,0c1.7,0.1,2.9-0.1,4-1.8c1.4-2.2,4.3-2.8,6.7-1.8
	c2.5,1,4,3.4,3.8,6.1c-0.2,2.6-2.1,4.8-4.7,5.4c-2.5,0.6-5.1-0.5-6.5-2.8c-0.3-0.4-0.7-1-1-1c-1.2,0.1-2.5-0.6-3.6,0.5
	c-2.3,2.3-4.5,4.6-6.9,6.8c-0.4,0.4-1.1,0.6-1.6,0.7c-1.9,0.1-3.8,0-5.8,0c-0.1,1.4-0.2,2.6-0.3,4c3.4,0,6.8,0,10.1,0
	c0.3,0,0.7-0.5,1-0.9c1.4-2.4,4.2-3.6,6.9-2.8c2.6,0.7,4.4,3.2,4.4,5.9c-0.1,2.7-1.9,5-4.6,5.7c-2.6,0.6-5.3-0.5-6.6-2.9
	c-0.4-0.7-0.7-0.9-1.5-0.9c-3.4,0-6.8,0-10.3,0c-0.4,1.3-0.8,2.6-1.3,4c2.7,0,5.4,0,8,0c0.6,0,1.3,0.3,1.7,0.7
	c2.3,2.2,4.6,4.5,6.7,6.8c0.8,0.8,1.7,0.5,2.6,0.6c1,0.1,1.6-0.1,2.2-1.1c1.3-2.4,4.1-3.4,6.7-2.7c2.6,0.7,4.4,3.2,4.4,5.9
	c0,2.7-1.9,5-4.5,5.7c-2.6,0.7-5.3-0.4-6.7-2.8c-0.4-0.8-0.9-1.2-1.8-1c-2.9,0.5-5-0.5-6.8-2.8c-1.3-1.7-3-3.1-4.6-4.6
	c-0.3-0.3-0.9-0.6-1.4-0.6c-2.5-0.1-5-0.1-7.5,0c-0.4,0-1.1,0.3-1.3,0.7C89,57.4,85.4,61.2,81,64.3c-0.7,0.5-1,1-0.9,1.9
	C80.1,68.8,80.1,71.5,80.1,74.2z M78.6,61.1c8.3-5.9,12.9-13.7,13.5-23.7c0.8-14.2-8.4-26.9-22-30.7c-8.5-2.3-16.4-1.2-23.4,4.6
	c-0.7,0.6-0.9,1.1-0.6,2c1.8,4.6,3.9,9,6.8,12.9c1.5,2.1,3.3,4.1,5,6.1c4.2-5.6,9.5-8,16.1-6c5.8,1.7,9.3,5.9,9.8,11.9
	c0.7,7.7-3.3,12.6-10.4,15.2C75.2,56.1,76.8,58.6,78.6,61.1z M42.7,16.2c-1,1.1-2.5,4.1-3.2,6.8c-1.2,4.9-2.3,9.8-3.5,14.7
	c-0.3,1.3,0,2.5,1.1,3.4c0.6,0.5,1.3,0.7,1.9,1.2c0.4,0.3,0.8,0.8,0.9,1.3c0.3,2.8,0.6,5.7,0.9,8.5c0.7,6.5,4.6,10,11.1,10
	c7,0,14,0,21.1,0c0.4,0,0.8,0,1.5,0c-1.8-2.7-3.4-5.1-5.1-7.6c-0.2-0.3-0.8-0.6-1.2-0.7c-8.1-1.4-13.2-8.6-11.7-16.7
	c0.1-0.7,0.1-1.1-0.4-1.5c-3.1-2.8-5.7-6-7.9-9.6C46.1,22.8,44.4,19.4,42.7,16.2z M65.9,122.4c0-0.3,0.1-0.5,0.1-0.8
	c0-6.7,0-13.3,0-20c0-0.5-0.3-1.1-0.6-1.5c-2.1-2.8-3.3-6-3.4-9.5c-0.1-3.3,0.9-6.4,2.7-9.2c-0.2,0-0.3-0.1-0.3,0
	c-0.2,0.1-0.4,0.2-0.6,0.2c-9.7,4.7-16,12.2-18.8,22.7c-1.6,5.9-0.9,12-1,18c0,0.1,0.1,0.1,0.2,0.2
	C51.3,122.4,58.6,122.4,65.9,122.4z M90.1,90.2c0-6.5-5.5-12.1-11.8-12C71.4,78.2,66,83.5,66,90.2c0,6.6,5.4,12.1,12.1,12
	C84.7,102.2,90.1,96.8,90.1,90.2z M60,39.9c0,5.6,4.5,10.1,10,10.1c5.5,0,10-4.5,10-10c0-5.6-4.5-10.1-10-10.1
	C64.5,29.8,60,34.3,60,39.9z M86,114.3c0-3.4,0-6.7,0-10.1c-5.3,2.8-10.6,2.8-15.9,0c0,3.4,0,6.7,0,10.1
	C75.4,114.3,80.7,114.3,86,114.3z M86,118.5c-5.4,0-10.7,0-15.9,0c0,1.4,0,2.6,0,3.9c5.4,0,10.6,0,15.9,0C86,121,86,119.8,86,118.5z
	 M56,81.3c1.3-0.8,2.5-1.5,3.5-2.2c0.3-0.2,0.4-0.7,0.4-1.1c0-2.1,0-4.3,0-6.4c0-1.8,0-3.7,0-5.5c-1.4,0-2.7,0-4,0
	C56,71.2,56,76.1,56,81.3z M64,76.9c1.1-0.4,2.1-0.7,3.2-1c0.7-0.2,0.9-0.5,0.8-1.1c0-1.9,0-3.8,0-5.8c0-1,0-2,0-2.9
	c-1.4,0-2.7,0-4,0c0,0.4,0,0.7,0,1C64,70.4,64,73.6,64,76.9z M76.1,66.1c-1.4,0-2.7,0-3.9,0c0,2.9,0,5.8,0,8.7
	c1.4-0.2,2.6-0.4,3.9-0.6c0-0.4,0-0.6,0-0.9C76.1,71,76.1,68.6,76.1,66.1z M7.8,27.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2
	c-1.1,0-2,0.9-2,2C5.8,26.9,6.7,27.8,7.8,27.8z M122.2,25.8c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2
	C121.3,27.8,122.2,26.9,122.2,25.8z M17.8,41.9c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2C16.9,43.9,17.8,43,17.8,41.9z
	 M114.2,41.9c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2C113.2,43.9,114.2,43,114.2,41.9z M7.8,56c-1.1,0-2,0.9-2,2
	c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C9.8,56.9,8.8,56,7.8,56z M120.2,60c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2
	C118.2,59.1,119.1,60,120.2,60z M120.2,23.8c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C122.2,24.7,121.3,23.8,120.2,23.8z
	 M78.1,82.2c4.4,0,8,3.6,8,8.1c0,4.4-3.7,8.1-8.1,8c-4.4,0-7.9-3.6-8-8C70,85.8,73.6,82.1,78.1,82.2z M82.1,90.2c0-2.2-1.8-4-4-4
	c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,3.9,4.1C80.3,94.2,82.1,92.4,82.1,90.2z M70,45.9c-3.3,0-6-2.8-6-6.1c0-3.3,2.8-6,6-6
	c3.3,0,6,2.8,6,6.1C76,43.2,73.3,45.9,70,45.9z M72,39.9c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2C71.1,41.9,72,41,72,39.9
	z"/>
</svg>
                        <h3>Intelligence Driven Analytics</h3>
                        <p>Extend your current performance with the help of AI and Machine Learning technologies to predict future performance, prevent anomalies and identify criteria for success.</p>
                    </Col>
					
					
                    
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default Analytics