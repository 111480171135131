import './digitaltransformation.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const Digitaltransformation = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>Our Services</h1>
    </div>
    <div className='digitaltransformation_page'>
        <Row className='align_margin'>
            <Col xs={{ span: 23 }} lg={{ span: 13 }}>
            <div className='bg_box1'></div>
            
                
            </Col>
            <Col className='services_div_right' xs={{ span: 23 }} lg={{ span: 11 }}>
                <h1>Digital Transformation</h1>
                <div className='line'></div>
                <p>Transform your business process by modernizing your legacy systems with help of our technology experts. We specialize in digital transformation by migrating outdated business applications into state-of-the-art, cost-effective, and cloud-based solutions that are easy to scale and AI integration enabled for your business growth. Unlock the full potential of your business with our expertise. Contact us today!</p>                
            </Col>
        </Row>
        <div className='digitaltransformation_box_container'>
        

        <Row className='digitaltransformation_s2_container align_margin'>
            
            <Col className='digitaltransformation_div_right' xs={{ span: 24,offset:0 }} lg={{ span: 24, offset:0 }}>
                <div className='digitaltransformation_s2_box_container'>
                    <Row>
                    <Col className='digitaltransformation_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M126,85.8c-0.6,1.1-1.5,1.5-2.8,1.5c-2.9-0.1-5.9,0-8.8,0c-0.8,0-1.2,0.1-1.1,1c0.1,1.2,0.1,2.3,0,3.5
	c0,0.7,0.1,1,0.9,1c1-0.1,1.9,0,2.9,0c1.5,0,2.3,0.8,2.3,2.3c0,4.9,0,9.8,0,14.7c0,1.5-0.8,2.2-2.2,2.3c-0.2,0-0.5,0-0.7,0
	c-37.2,0-74.3,0-111.5,0c-0.2,0-0.4,0-0.6,0c-1.6,0-2.4-0.8-2.4-2.4c0-4.8,0-9.7,0-14.5c0-1.5,0.8-2.3,2.3-2.4c1,0,2,0,3,0
	c0.6,0,0.9-0.2,0.8-0.8c0-0.3,0-0.6,0-0.8c0-20.8,0-41.7,0-62.5c0-2.6,0.5-3.2,3.2-3.2c20.1,0,40.1,0,60.2,0c1.1,0,1.4-0.3,1.4-1.4
	c-0.1-1.9,0-3.8,0-5.7c0-1.7,0.7-2.4,2.5-2.4c16.1,0,32.1,0,48.2,0c1.2,0,2,0.5,2.5,1.5C126,40.3,126,63,126,85.8z M60.6,92.8
	c15.6,0,31.2,0,46.9,0c0.2,0,0.5,0,0.7,0c0.7,0.1,0.9-0.1,0.9-0.9c-0.1-1.1-0.1-2.2,0-3.3c0.1-1-0.2-1.4-1.3-1.4
	c-10.6,0.1-21.2,0-31.7,0c-2.7,0-3.2-0.5-3.2-3.2c0-17.7,0-35.4,0-53.2c0-1-0.2-1.3-1.3-1.3c-19.3,0-38.7,0-58,0
	c-1,0-1.2,0.3-1.2,1.3c0,20.2,0,40.4,0,60.7c0,1,0.3,1.2,1.3,1.2C29.3,92.7,44.9,92.8,60.6,92.8z M77.1,51.6c0,10.1,0,20.1,0,30.2
	c0,1,0.2,1.3,1.3,1.3c14,0,28.1,0,42.1,0c1.1,0,1.3-0.2,1.3-1.3c0-20.1,0-40.3,0-60.4c0-1-0.3-1.2-1.3-1.2c-14.1,0-28.2,0-42.3,0
	c-1,0-1.3,0.2-1.2,1.2C77.1,31.5,77.1,41.5,77.1,51.6z M60.7,107.9c17.8,0,35.5,0,53.3,0c1,0,1.3-0.1,1.3-1.2
	c-0.1-2.9-0.1-5.7,0-8.6c0-0.9-0.2-1.1-1.1-1.1c-7.5,0-15,0-22.5,0c-3.1,0-6.2,0-9.3,0c-0.8,0-0.9,0.2-0.9,0.9c0.1,1.4,0,2.8,0,4.2
	c0,1.6-0.8,2.4-2.4,2.4c-12.2,0-24.4,0-36.6,0c-1.6,0-2.4-0.8-2.4-2.4c0-1.5,0-2.9,0-4.4c0-0.7-0.2-0.8-0.8-0.8
	c-10.7,0-21.5,0-32.2,0c-0.7,0-0.9,0.2-0.9,0.9c0,2.9,0.1,5.8,0,8.7c0,1,0.2,1.3,1.3,1.3C25.2,107.8,43,107.9,60.7,107.9z M60.9,97
	c-4.8,0-9.6,0-14.4,0c-2.2,0-2.2,0-2.2,2.2c0,1.1,0.3,1.2,1.3,1.2c9.8,0,19.6,0,29.4,0c2.3,0,2.2,0,2.3-2.2c0-0.9-0.2-1.2-1.1-1.1
	C71,97,65.9,97,60.9,97z M21.5,61.1c0-6,0-12,0-18c0-2.3,0.6-3,2.9-3c12.1,0,24.2,0,36.3,0c2.3,0,2.9,0.7,2.9,3
	c0,12.1,0,24.2,0,36.3c0,2.2-0.6,2.9-2.9,2.9c-12.1,0-24.3,0-36.4,0c-2.3,0-2.8-0.6-2.8-2.9C21.5,73.3,21.5,67.2,21.5,61.1z
	 M42.6,78.1c5.3,0,10.6,0,15.9,0c0.9,0,1-0.2,1-1.1c0-10.5,0-21.1,0-31.6c0-0.8-0.1-1.1-1-1.1c-10.6,0-21.1,0-31.7,0
	c-0.9,0-1,0.3-1,1.1c0,10.5,0,21.1,0,31.6c0,1,0.3,1.1,1.2,1.1C32.1,78,37.4,78.1,42.6,78.1z M99.2,28.7c-5.7,0-11.5,0-17.2,0
	C87.8,28.7,93.5,28.7,99.2,28.7c5.7,0,11.4,0,17.1,0C110.6,28.7,104.9,28.7,99.2,28.7z M82,58.8c5.7,0,11.5,0,17.2,0
	c1.8,0,3.6,0,5.3,0c-1.8,0-3.6,0-5.3,0C93.5,58.8,87.7,58.9,82,58.8z M115.7,64.5c-5.5,0-10.9,0-16.4,0c-5.5,0-11,0-16.5,0
	c5.5,0,11,0,16.5,0C104.7,64.5,110.2,64.5,115.7,64.5z M116.4,34.3c-5.7,0-11.4,0-17.1,0c-5.7,0-11.4,0-17.1,0c5.7,0,11.4,0,17.1,0
	C105,34.3,110.7,34.3,116.4,34.3z M116.2,74.6c-5.6,0-11.3,0-16.9,0c-5,0-9.9,0-14.9,0c5,0,9.9,0,14.9,0
	C104.9,74.6,110.5,74.6,116.2,74.6z M123.3,19.8c0-1.1-0.3-1.3-1.4-1.3c-15.2,0-30.3,0-45.5,0c-1.1,0-1.3,0.2-1.3,1.3
	c0,10.6,0,21.2,0,31.8c0,10.6,0,21.1,0,31.7c0,1.1,0.2,1.4,1.4,1.4c15.1,0,30.2,0,45.4,0c1.1,0,1.4-0.2,1.4-1.4
	C123.3,62.2,123.3,41,123.3,19.8z M79.6,25.5c0.5-1.1,1.5-1.3,2.6-1.3c5.1,0,10.2,0,15.4,0c6.2,0,12.4,0,18.6,0
	c1.6,0,2.5,0.7,2.7,1.8c0.3,1.5-0.8,2.7-2.6,2.7c-5.7,0-11.4,0-17.1,0c-5.7,0-11.5,0-17.2,0C80,28.7,78.8,27.1,79.6,25.5z M117,38.7
	c-0.3,0-0.5,0-0.8,0c-11.4,0-22.8,0-34.1,0c-1.3,0-2.2-0.4-2.6-1.6c-0.3-1.1,0.1-1.9,1.1-2.5c0.5-0.3,1.1-0.3,1.6-0.3
	c5.7,0,11.4,0,17.1,0c5.7,0,11.4,0,17.1,0c1.6,0,2.6,0.8,2.6,2.2C119,37.7,118.2,38.5,117,38.7z M119,46.5c0,1.1-0.5,1.9-1.6,2.2
	c-0.4,0.1-0.9,0.1-1.4,0.1c-5.6,0-11.1,0-16.7,0c-5.7,0-11.4,0-17.1,0c-2,0-3.2-1.3-2.6-3c0.4-1.1,1.3-1.5,2.3-1.5
	c1.3,0,2.5,0,3.8,0c10,0,20,0,30,0c0.5,0,1.1,0,1.6,0.1C118.3,44.7,118.9,45.4,119,46.5z M79.7,55.7c0.5-0.9,1.4-1.2,2.4-1.2
	c4.3,0,8.6,0,12.9,0c7.1,0,14.3,0,21.4,0c1.5,0,2.4,0.7,2.6,1.8c0.3,1.5-0.7,2.6-2.4,2.6c-4,0-8,0-12,0c-1.8,0-3.6,0-5.3,0
	c-5.7,0-11.5,0-17.2,0C80,58.8,78.8,57.2,79.7,55.7z M117.6,68.7c-0.5,0.2-1,0.2-1.5,0.2c-11.3,0-22.6,0-33.9,0
	c-0.5,0-0.9,0-1.4-0.2c-1-0.4-1.5-1.2-1.4-2.2c0.1-1.1,0.7-1.8,1.9-2c0.5-0.1,1-0.1,1.4-0.1c5.5,0,11,0,16.5,0c5.5,0,10.9,0,16.4,0
	c0.5,0,1,0,1.4,0.1c1.1,0.2,1.7,0.8,1.8,1.9C119.1,67.5,118.6,68.3,117.6,68.7z M117.2,78.9c-0.5,0.1-1,0.1-1.4,0.1
	c-11,0-22.1,0-33.1,0c-0.3,0-0.7,0-1,0c-1.3-0.1-2.2-1-2.2-2.2c0-1.2,1-2.1,2.3-2.2c0.9,0,1.8,0,2.7,0c5,0,9.9,0,14.9,0
	c5.6,0,11.3,0,16.9,0c1.1,0,2,0.2,2.5,1.2C119.4,77.1,118.7,78.7,117.2,78.9z M54.5,53c-0.8-1.2-2.3-1.3-3.5-0.1
	c-3.9,3.9-7.8,7.8-11.7,11.7c-0.5,0.5-0.8,0.6-1.3,0c-1.2-1.3-2.5-2.5-3.8-3.8c-1-1-2.2-1-3.1-0.2c-0.9,0.9-0.9,2.1,0.1,3.1
	c1.9,1.9,3.8,3.8,5.7,5.7c1.1,1.1,2.2,1.1,3.3,0c4.6-4.6,9.2-9.2,13.9-13.9c0.4-0.4,0.8-0.9,0.7-1.7C54.9,53.7,54.7,53.3,54.5,53z"
	/>
</svg>
                        <h3>Assess</h3>
                        <p>Let us evaluate together your current state and your business and technology aspirations.</p>
                    </Col>
                    <Col className='digitaltransformation_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
					<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M53.4,8.8c0-2,0-3.9,0-5.8c0-1.9,0.7-2.7,2.6-2.7c5.3,0,10.6,0,15.9,0c2,0,2.7,0.7,2.7,2.8c0,1.9,0,3.7,0,5.7
	c0.6,0,1.1,0,1.6,0c13.3,0,26.7,0,40,0c4.6,0,7.1,2.5,7.1,7.1c0,22.7,0,45.4,0,68c0,1.1,0.2,1.7,1.2,2.3c2,1.1,3,3,3,5.3
	c0,1.4,0,2.7,0,4.1c0,3.9-2.7,6.6-6.6,6.6c-10.4,0-20.9,0-31.3,0c-0.4,0-0.9,0-1.5,0c0.1,0.5,0.2,0.9,0.4,1.3
	c2.3,6.9,4.6,13.7,6.9,20.6c0.8,2.4,0,3.6-2.5,3.6c-2.3,0-4.7,0-7,0c-1.9,0-2.3-0.3-3-2.2c-1.1-3.2-2.1-6.4-3.2-9.6
	c-0.2-0.8-0.6-1-1.4-1c-9.6,0-19.2,0-28.8,0c-0.9,0-1.2,0.3-1.5,1.1c-1,3.2-2.2,6.4-3.2,9.7c-0.4,1.4-1.3,2-2.7,2
	c-2.4,0-4.8,0-7.2,0c-2.5,0-3.4-1.2-2.6-3.5c2.3-6.9,4.6-13.8,6.9-20.7c0.1-0.3,0.2-0.7,0.4-1.2c-0.6,0-1,0-1.5,0
	c-10.3,0-20.6,0-30.9,0c-4.5,0-7.1-2.6-7.1-7.1c0-1.2,0-2.4,0-3.6c0.1-2.4,1.1-4.3,3.2-5.4c0.9-0.5,1-1,1-1.8c0-19.3,0-38.6,0-57.8
	c0-3.6,0-7.3,0-10.9c0-4.1,2.7-6.8,6.8-6.8c13.4,0,26.8,0,40.2,0C52.1,8.8,52.7,8.8,53.4,8.8z M119.2,85.2c0-0.6,0-1,0-1.4
	c0-22.5,0-45.1,0-67.6c0-2.4-0.6-3.1-3-3.1c-34.7,0-69.5,0-104.2,0c-2.5,0-3.1,0.6-3.1,3.2c0,22.5,0,45,0,67.5c0,0.5,0,0.9,0,1.5
	C45.7,85.2,82.3,85.2,119.2,85.2z M64,98c18.7,0,37.4,0,56.1,0c0.4,0,0.8,0,1.2,0c1.3-0.1,2-0.9,2.1-2.1c0.1-1,0-2,0-3.1
	c0-2.8-0.5-3.3-3.3-3.3c-37.4,0-74.8,0-112.2,0c-0.4,0-0.8,0-1.2,0c-1.3,0.1-2,0.8-2.1,2.1c-0.1,1.1,0,2.1,0,3.2
	c0,2.6,0.6,3.2,3.2,3.2C26.5,98,45.3,98,64,98z M50,110.6c9.4,0,18.6,0,28,0c-0.9-2.7-1.7-5.3-2.6-7.9c-0.1-0.3-0.6-0.5-0.9-0.5
	c-7,0-14,0-20.9,0c-0.3,0-0.8,0.3-0.9,0.5C51.7,105.3,50.9,107.9,50,110.6z M48.3,102.2c-1.1,0-2,0.1-2.8,0
	c-0.9-0.1-1.3,0.3-1.6,1.1c-2.1,6.3-4.2,12.6-6.3,19c-0.1,0.3-0.2,0.7-0.3,1.1c1.1,0,2,0,2.9,0c0.8,0.1,1.1-0.2,1.3-1
	c0.6-1.9,1.3-3.8,1.9-5.8C45.1,111.9,46.7,107.2,48.3,102.2z M90.6,123.4c0-0.2,0-0.3,0-0.4c-2.2-6.7-4.5-13.5-6.7-20.2
	c-0.1-0.3-0.4-0.6-0.7-0.6c-1.1-0.1-2.2,0-3.5,0c2.3,7,4.6,13.8,6.9,20.6c0.1,0.2,0.5,0.5,0.7,0.6C88.4,123.5,89.5,123.4,90.6,123.4
	z M70.3,4.7c-4.3,0-8.5,0-12.6,0c0,1.4,0,2.7,0,4.1c4.2,0,8.4,0,12.6,0C70.3,7.3,70.3,6,70.3,4.7z M68.3,43.9
	c-3.2,3.2-6.4,6.4-9.6,9.5c-0.8,0.8-1.1,1.6-1.1,2.7c0,4.9,0,9.9,0,14.8c0,0.5,0,0.9,0,1.5c-2.8,0-5.3,0-7.9,0
	c-1.8,0-2.8,0.8-2.7,2.2c0,1.3,1,2.1,2.7,2.1c3.1,0,6.2,0,9.3,0c2.2,0,2.9-0.7,2.9-3c0-5.4,0-10.9,0-16.3c0-0.6,0.3-1.4,0.8-1.8
	c1.6-1.7,3.3-3.3,4.9-5c1.5-1.6,3-3.1,4.6-4.7c0.1,0.1,0.2,0.1,0.3,0.2c0,0.9,0,1.8,0,2.7c0,1.5,0.8,2.4,2.1,2.4
	c1.2,0,2.1-0.9,2.1-2.3c0-2.7,0-5.4,0-8.1c0-1.4-0.8-2.3-2.2-2.3c-2.8,0-5.6,0-8.3,0c-1.3,0-2.1,0.9-2.2,2.1c0,1.2,0.9,2.1,2.2,2.2
	c1,0,1.9,0,3.2,0C68.9,43.3,68.6,43.6,68.3,43.9z M93.7,30.1c0,4.7-3.9,8.5-8.6,8.4c-4.6,0-8.4-3.8-8.4-8.5c0-4.7,3.8-8.5,8.5-8.5
	C89.9,21.6,93.7,25.4,93.7,30.1z M85.3,34.3c2.3,0,4.2-2,4.2-4.3c0-2.3-1.9-4.2-4.2-4.2c-2.4,0-4.3,2-4.2,4.3
	C81,32.4,83,34.3,85.3,34.3z M56.6,23.4c1.2-1.2,1.3-2.4,0.4-3.4c-0.9-0.9-2.2-0.8-3.4,0.3c-1.1,1.1-2.2,2.3-3.4,3.6
	c-1.3-1.3-2.3-2.4-3.5-3.6c-1.2-1.2-2.4-1.3-3.4-0.4c-0.9,0.9-0.8,2.2,0.3,3.4c1.1,1.1,2.3,2.2,3.6,3.5c-1.4,1.3-2.7,2.4-3.8,3.7
	c-0.5,0.5-0.7,1.6-0.5,2.2c0.1,0.6,1,1.3,1.6,1.3c0.7,0.1,1.5-0.3,2.1-0.8c1.3-1.1,2.4-2.3,3.6-3.5c1.2,1.2,2.3,2.3,3.4,3.4
	c1.2,1.2,2.4,1.3,3.4,0.4c0.9-0.9,0.8-2.2-0.3-3.4c-1.1-1.1-2.3-2.2-3.5-3.4C54.5,25.6,55.6,24.5,56.6,23.4z M40.9,58.8
	c1.2,1.2,2.4,1.3,3.4,0.4c0.9-0.9,0.8-2.2-0.3-3.4c-1.1-1.1-2.3-2.2-3.6-3.4c1.3-1.2,2.5-2.3,3.6-3.4c1.2-1.2,1.3-2.4,0.4-3.4
	c-0.9-0.9-2.2-0.8-3.4,0.3c-1.1,1.1-2.2,2.3-3.4,3.6c-1.3-1.3-2.3-2.5-3.5-3.6c-1.2-1.2-2.4-1.3-3.4-0.4c-0.9,0.9-0.8,2.2,0.3,3.4
	c1.1,1.1,2.3,2.2,3.6,3.4c-1.3,1.2-2.5,2.3-3.6,3.4c-1.2,1.2-1.3,2.4-0.4,3.4c0.9,0.9,2.2,0.8,3.4-0.3c1.1-1.1,2.2-2.3,3.4-3.5
	C38.7,56.5,39.8,57.7,40.9,58.8z M105.6,48.7c0.7-0.7,1.1-1.5,0.7-2.5c-0.6-1.5-2.3-1.8-3.6-0.6c-1.2,1.2-2.4,2.5-3.7,3.8
	c-0.7-0.8-1.2-1.4-1.8-2c-0.6-0.6-1.2-1.2-1.8-1.8c-0.7-0.7-1.5-1.1-2.5-0.7c-1.5,0.6-1.8,2.3-0.6,3.6c1.2,1.2,2.4,2.4,3.8,3.6
	c-1.4,1.3-2.5,2.5-3.7,3.6c-0.7,0.7-1.1,1.5-0.7,2.5c0.6,1.5,2.3,1.8,3.6,0.6c1.2-1.2,2.4-2.4,3.7-3.8c1.3,1.3,2.4,2.5,3.5,3.6
	c1.1,1.1,2.3,1.2,3.3,0.3c1-0.9,0.9-2.2-0.3-3.4c-1.1-1.1-2.3-2.2-3.6-3.5C103.3,51,104.4,49.9,105.6,48.7z M71.1,73
	c-0.9,0.9-1,1.9-0.3,2.9C71.6,77,73,77,74.1,76c1.2-1.2,2.4-2.5,3.7-3.8c0.8,0.9,1.3,1.5,1.9,2.1c0.6,0.6,1.2,1.2,1.8,1.8
	c1.4,1.2,3.4,0.8,3.6-0.9c0.1-0.7-0.3-1.7-0.8-2.2c-1.1-1.2-2.4-2.3-3.7-3.5c0.8-0.8,1.5-1.4,2.1-2c0.6-0.6,1.3-1.2,1.9-1.9
	c1.2-1.4,0.6-3.4-1.1-3.5c-0.7-0.1-1.6,0.3-2.1,0.8c-1.2,1.1-2.3,2.4-3.5,3.6c-1.3-1.3-2.4-2.4-3.5-3.5c-1.2-1.2-2.4-1.3-3.4-0.4
	c-1,0.9-0.9,2.2,0.3,3.4c1.1,1.1,2.3,2.2,3.6,3.4C73.5,70.6,72.3,71.8,71.1,73z"/>
</svg>
                        <h3>Strategize</h3>
                        <p>We collaborate to identify the best technology strategy that will empower your business.</p>
                    </Col>
                    <Col className='digitaltransformation_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M125.7,92.2c-0.6,0.9-1.5,1.2-2.6,1.1c-0.2,0-0.3,0-0.5,0c-2.9,0-2.9,0-3.9,2.7c-0.1,0.3,0,0.9,0.2,1.2
	c0.7,0.8,1.5,1.5,2.2,2.2c0.9,0.9,0.9,1.7,0,2.6c-1.9,2-3.8,3.9-5.8,5.8c-0.9,0.9-1.7,0.8-2.7,0c-0.2-0.2-0.5-0.4-0.7-0.7
	c-1.2-1.8-2.7-2.4-4.7-1.1c-0.6,0.4-1,0.5-0.9,1.3c0.1,1,0,1.9,0,2.9c0,1.4-0.8,2.3-2.2,2.3c-2.7,0-5.5,0-8.2,0c-1.3,0-1.9-0.7-2-2
	c0-0.4-0.1-0.9,0-1.3c0.4-2-0.3-3.1-2.3-3.6c-0.8-0.2-1.4-0.3-1.9,0.4c-0.5,0.7-1.2,1.3-1.8,1.9c-1,0.9-1.9,1-2.9,0.1
	c-2-1.9-3.9-3.8-5.9-5.7c-0.9-0.9-0.9-1.8,0-2.8c0.1-0.1,0.3-0.3,0.4-0.4c2.5-2.5,2.5-2.5,0.8-5.7c-1.2,0-2.5,0-3.8,0
	c-1.4,0-2.1-0.7-2.1-2.1c0-2.7,0-5.4,0-8.1c0-1.4,0.7-2,2.1-2.1c1.1,0,2.2,0,3.3,0c0.3,0,0.8-0.1,0.9-0.4c0.4-0.9,0.8-1.8,1.1-2.7
	c0.1-0.2-0.1-0.6-0.3-0.8c-0.7-0.8-1.5-1.5-2.3-2.3c-1-1.1-1-1.9,0-2.9c1.9-1.9,3.8-3.7,5.7-5.6c1-1,1.9-1,2.9,0
	c0.7,0.7,1.4,1.4,2.2,2.1c0.2,0.2,0.7,0.4,0.9,0.3c0.9-0.3,1.8-0.7,2.6-1.1c0.2-0.1,0.4-0.6,0.4-0.9c0-1,0-2.1,0-3.1
	c0-1.2,0.6-1.9,1.8-2c2.8,0,5.7,0,8.5,0c1.2,0,1.8,0.7,1.8,2c0,1.1,0,2.2,0,3.2c0,0.4,0.4,0.8,0.7,1c1.9,1.4,3.5,0.9,4.8-0.9
	c0.2-0.3,0.6-0.6,0.9-0.8c0.8-0.7,1.6-0.7,2.4,0c2,2,4.1,4,6,6c0.7,0.7,0.6,1.6,0,2.4c-0.2,0.2-0.4,0.5-0.7,0.7
	c-2.2,2.2-2.2,2.2-0.9,4.9c0.2,0.3,0.7,0.7,1,0.7c0.9,0.1,1.8,0.1,2.8,0c1.1-0.1,1.9,0.3,2.4,1.2C125.7,85.6,125.7,88.9,125.7,92.2z
	 M86.9,104.3c0.8-0.9,1.5-1.9,2.3-2.7c0.7-0.8,1.4-0.8,2.4-0.3c1.5,0.7,3,1.4,4.5,1.9c1.1,0.4,1.6,1,1.6,2.2c0,1.2,0,2.5,0,3.7
	c1.7,0,3.3,0,5,0c0-1.2,0-2.2,0-3.3c0-1.1,0.5-1.7,1.5-2.1c1.6-0.5,3.1-1.2,4.6-1.9c1-0.5,1.8-0.5,2.6,0.3c0.8,0.8,1.6,1.5,2.2,2.1
	c1.3-1.2,2.4-2.3,3.7-3.5c-0.7-0.7-1.5-1.5-2.3-2.2c-0.9-0.8-0.9-1.6-0.3-2.7c0.7-1.3,1.3-2.7,1.8-4.2c0.5-1.4,0.9-1.8,2.4-1.8
	c1,0,2,0,3,0c0-1.8,0-3.5,0-5.2c-1.1,0-2.2,0-3.2,0c-1.1,0-1.8-0.5-2.1-1.5c-0.6-1.5-1.2-3-1.9-4.5c-0.5-1-0.5-1.9,0.4-2.7
	c0.8-0.8,1.7-1.5,2.5-2.1c-1.4-1.4-2.6-2.6-3.7-3.8c-0.8,0.8-1.7,1.6-2.5,2.4c-0.7,0.7-1.5,0.8-2.4,0.3c-1.5-0.7-3-1.4-4.5-1.9
	c-1.1-0.4-1.6-1.1-1.6-2.2c0-1.1,0-2.1,0-3.2c-1.8,0-3.4,0-5.1,0c0,1.1,0,2.2,0,3.2c0,1.2-0.5,1.8-1.6,2.2c-1.5,0.5-3,1.1-4.4,1.9
	c-1,0.5-1.8,0.5-2.6-0.4c-0.8-0.9-1.5-1.8-2.2-2.7c-1.5,1.5-2.7,2.7-3.8,3.9c0,0,0.1,0.1,0.1,0.1c0.6,0.6,1.2,1.2,1.8,1.8
	c1.4,1.4,1.4,1.7,0.5,3.4C85,80,84.5,81.2,84,82.5c-0.4,1.4-0.9,1.8-2.3,1.8c-1.1,0-2.1,0-3.2,0c0,1.8,0,3.4,0,5.2
	c1.2,0,2.3,0,3.4,0c1,0,1.6,0.5,2,1.4c0.6,1.6,1.3,3.2,2,4.8c0.4,0.9,0.3,1.6-0.4,2.3c-0.9,0.8-1.7,1.6-2.5,2.3
	C84.3,101.6,85.4,102.8,86.9,104.3z M102.1,46.5c1.5-1.6,3.1-3.1,4.6-4.7c0.3-0.3,0.5-0.5,0.8-0.7c0.8-0.6,1.6-0.6,2.4,0.1
	c0.8,0.7,0.8,1.6,0.1,2.4c-2.9,2.9-5.7,5.9-8.6,8.7c-0.7,0.7-1.9,0.6-2.6-0.1c-2.8-2.8-5.5-5.6-8.3-8.4c-0.9-0.9-0.9-1.8-0.2-2.5
	c0.8-0.8,1.7-0.8,2.6,0.1c1.7,1.7,3.4,3.4,5.2,5.3c0-0.7,0.1-1.1,0.1-1.5c0-6.7,0-13.5,0-20.2c0-4-2-6-6-6c-15.1,0-30.3,0-45.4,0
	c-4.2,0-6.2,2-6.2,6.2c0,3.5,0,6.9,0,10.4c0,0.1,0,0.2,0.1,0.3c1.1,0.2,2.3,0.4,3.5,0.6C54.8,38.9,62.1,48.3,62,59.2
	c0,4.8-1.3,9.2-4.5,12.9C53.1,77.4,52,83.6,52,90.3c2.2,0,4.4,0.1,5.7,2.1c1.3,2,1.2,4,0,5.8c0.3,1.2,0.8,2.1,0.8,3.1
	c0.2,2.8-1.8,5-4.6,5.1c-1.5,0.1-3,0-4.6,0c-0.1,0.3-0.2,0.6-0.4,0.9c-1.3,3-3.5,4.9-6.8,5.2c-2.1,0.2-4.3,0.2-6.4,0
	c-3.2-0.3-5.4-2.2-6.5-5.2c-0.3-0.8-0.6-1-1.4-0.9c-1.1,0.1-2.2,0-3.4,0c-2,0-3.6-0.7-4.5-2.5c-1-1.8-0.9-3.5,0.3-5.2
	c0.1-0.1,0.1-0.2,0.2-0.3c-1.2-1.9-1.5-3.9-0.2-5.9c1.3-2,3.3-2.3,5.6-2.2c-0.1-5.5-0.7-10.8-3.7-15.6c-0.7-1-1.4-2.1-2.1-3.1
	c-6.9-8.6-5.1-22.6,3.6-30.1c3.6-3.1,7.6-5,12.3-5.6c0.4,0,0.7-0.1,1.2-0.2c0-0.4,0-0.8,0-1.2c0-3.3,0-6.7,0-10c0-5.3,3.6-9,8.9-9.2
	c0.3,0,0.6,0,1,0c15.1,0,30.1,0,45.2,0c6.1,0,9.7,3.6,9.7,9.8c0,6.7,0,13.3,0,20c0,0.4,0,0.9,0,1.3C101.9,46.4,102,46.5,102.1,46.5z
	 M48.4,90.3c0.1-0.8,0.1-1.6,0.1-2.4c0.3-6.7,2-12.9,6.4-18.2c2.6-3.1,3.6-6.8,3.6-10.8c0-10.8-9.3-20.9-22-19.5
	c-11.6,1.3-19.6,13-16.7,24.3c0.7,2.6,2.2,4.8,3.8,6.9c2.9,3.8,4.7,8.1,5.2,12.9c0.3,2.2,0.4,4.4,0.7,6.6c2.5,0,5,0,7.5,0
	c0-0.2,0.1-0.4,0.1-0.5c0-4.6,0-9.2,0-13.9c0-0.4-0.4-0.9-0.8-1.2c-1.6-1-3.2-1.9-4.9-2.9c-1.1-0.7-1.5-1.6-1-2.5
	c0.5-1,1.6-1.2,2.8-0.5c1.6,0.9,3.2,1.9,4.9,2.8c0.4,0.2,1.1,0.2,1.5,0c1.7-0.9,3.4-1.9,5-2.8c1.1-0.6,2.2-0.4,2.7,0.4
	c0.5,0.9,0.1,1.9-1,2.6c-1.6,0.9-3.2,1.9-4.8,2.8c-0.6,0.4-0.9,0.8-0.9,1.5c0,4.1,0,8.3,0,12.4c0,0.5,0,1.1,0,1.7
	C43.4,90.3,45.8,90.3,48.4,90.3z M39.1,93.8c-4.9,0-9.8,0-14.7,0c-0.9,0-1.4,0.3-1.5,1.2c0,0.8,0.4,1.3,1.3,1.4
	c0.8,0.1,1.6,0.2,2.4,0.2c8.2,0,16.5,0,24.7,0c0.8,0,1.5-0.1,2.3-0.2c1-0.1,1.6-0.5,1.6-1.6c-0.1-1.1-0.9-1.1-1.8-1.1
	C48.5,93.8,43.8,93.8,39.1,93.8z M39.1,100.1c-4.8,0-9.6,0-14.4,0c-1,0-1.8,0.2-1.7,1.3c0,1.1,0.8,1.4,1.7,1.4c9.5,0,19.1,0,28.6,0
	c1.2,0,1.9-0.5,1.8-1.4c-0.1-1.1-0.9-1.2-1.8-1.2C48.5,100.1,43.8,100.1,39.1,100.1z M32.7,106.4c0.9,1.6,2,2.5,3.6,2.6
	c1.8,0.1,3.7,0.1,5.5,0c1.6-0.1,2.8-1,3.5-2.6C41.2,106.4,37.1,106.4,32.7,106.4z M73.9,43c-0.1,0-0.3,0-0.4,0c-2,1.1-4.1,2.2-6,3.5
	c-0.7,0.5-0.7,1.4-0.2,2.2c0.5,0.7,1.2,1.1,2,0.7c2-1,4-2.1,5.8-3.3c0.4-0.3,0.6-1.3,0.4-1.9c-0.2-0.5-0.9-0.9-1.4-1.3
	C74,42.9,74,42.9,73.9,43z M10.2,46.3c-1.8-1.1-3.6-2.1-5.4-3.1c-0.8-0.4-1.7-0.1-2.2,0.7c-0.5,0.8-0.3,1.8,0.4,2.3
	c1.9,1.2,3.8,2.3,5.7,3.2C9.8,49.9,11,49,11,47.4C10.8,47.2,10.6,46.5,10.2,46.3z M17.4,30.7c0.9,1.8,1.9,3.5,3,5.2
	c0.6,0.9,1.5,1.2,2.4,0.7c0.9-0.5,1.2-1.5,0.7-2.5c-0.9-1.7-1.9-3.4-3-5.1c-0.3-0.5-0.9-0.8-1.2-1C17.6,28.1,16.7,29.5,17.4,30.7z
	 M59.5,28.3c-0.9-0.4-1.6,0-2.1,0.8c-1,1.7-1.9,3.3-2.8,5c-0.5,1-0.2,2.1,0.6,2.5c0.9,0.5,1.8,0.2,2.4-0.9c0.9-1.6,1.9-3.2,2.8-4.8
	c0.2-0.3,0.3-0.7,0.5-1.4C60.5,29.3,60.1,28.6,59.5,28.3z M89.2,87.2c0-6.1,4.8-11,10.9-11c6.1,0,11.1,4.9,11.1,11
	c0,6.1-4.9,11-11,11C94.1,98.2,89.3,93.3,89.2,87.2z M100.1,79.8c-4.1,0-7.4,3.4-7.3,7.5c0,4.1,3.4,7.5,7.4,7.4
	c4.2,0,7.5-3.4,7.5-7.5C107.6,83.1,104.3,79.8,100.1,79.8z M23.7,61.4c-0.1,1.6,0.5,2.5,1.7,2.6c1.1,0.1,1.6-0.8,1.8-2.5
	c0.2-1.8,0.5-3.6,1.2-5.2c2.6-5.6,7.1-8.5,13.2-8.9c1.3-0.1,2.1-0.8,2.1-1.8c0-1.1-0.8-1.8-2.1-1.8c-0.2,0-0.5,0-0.7,0
	c-9.3,0.6-16.6,7.9-17.2,17.2C23.7,61.1,23.7,61.2,23.7,61.4z"/>
</svg>
                        <h3>Implement</h3>
                        <p>We work together to implement them iteratively until full ROI is realized.</p>
                    </Col>
					
                    
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default Digitaltransformation