import React, { useState } from 'react';
import './contact.css';
import { Col, Row } from 'antd';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });
      if (response.ok) {
        console.log('Email sent successfully!');
        // Reset form fields
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setMessage('');
      } else {
        console.error('Email sending failed.');
      }
    } catch (error) {
      console.error('An error occurred while sending the email:', error);
    }
  };

  return (
    <form className='contactform' onSubmit={handleSubmit}>
      <Row>
      <Col className='contactform_rows' xs={{ span: 24,offset:0}} sm={{ span: 24,offset:0}} xl={{ span: 24,offset:0 }}>
        <input
          type="text"
          placeholder='Your Name'
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        </Col>
    </Row>
    <Row>
    <Col className='contactform_rows' xs={{ span: 24,offset:0}} sm={{ span: 24,offset:0}} xl={{ span: 24,offset:0 }}>
            <div>
                <input
                type="email"
                placeholder='Your Email'
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                />
            </div>
            </Col>
    </Row>
    
    <Row>
    <Col className='contactform_rows' xs={{ span: 24,offset:0}} sm={{ span: 24,offset:0}} xl={{ span: 24,offset:0 }}>
            <div>
                <input
                type="phone"
                placeholder='Phone Number'
                id="phone"
                value={phone}
                onChange={(e) => setEmail(e.target.value)}
                required
                />
            </div>
            </Col>
    </Row>
    <Row>
    <Col className='contactform_rows' xs={{ span: 24,offset:0}} sm={{ span: 24,offset:0}} xl={{ span: 24,offset:0 }}>
            <div>
                <input
                type="subject"
                placeholder='Subject'
                id="subject"
                value={subject}
                onChange={(e) => setEmail(e.target.value)}
                required
                />
            </div>
            </Col>
    </Row>
    <Row>
    <Col className='contactform_rows' xs={{ span: 24,offset:0}} sm={{ span: 24,offset:0}} xl={{ span: 24,offset:0 }}>
            <div>
                <textarea
                id="message"
                placeholder='Enter your message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                ></textarea>
            </div>
            </Col>
    </Row>
    <Row>
        <Col className='contactform_rows' xs={{ span: 24,offset:0}} sm={{ span: 24,offset:0}} xl={{ span: 24,offset:0 }}>
            <button type="submit">Submit Now</button>
        </Col>
        
    </Row>
 
    </form>
  );
};

export default Contact;
