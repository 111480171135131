import './servicespage.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const Servicespage = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>Our Services</h1>
    </div>
    <div className='services_page'>
        <Row className='align_margin'>
            <Col xs={{ span: 13 }} lg={{ span: 13 }}>
            <div className='bg_box1'></div>
            <div className='bg_box2'></div>
            <div className='bg_box3'></div>
                
            </Col>
            <Col className='services_div_right' xs={{ span: 11 }} lg={{ span: 11 }}>
                <h1>IT Staffing Solutions</h1>
                <div className='line'></div>
                <p>Grow your technology organization with our staffing solution.  We are experienced technologists providing consulting services on advisory or service implementation partner capacity. We embrace your organization <strong>culture</strong>, up-hold our <strong>competence</strong> and <strong>commit</strong> your success. We are focused on solving your business problems with technology.</p>
                
            </Col>
        </Row>
        <div className='servicespage_box_container'>
        <Row>
        <Col xs={{ span: 8 }} xl={{ span: 6 }}>
            <div className='servicespage_box box_icon1'>
            <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path className='svg_icons' d="M1.9,38c0-4.1,0-8,0-12.1c0.6,0,1.2,0,1.9,0c2.3,0,4.1-1.1,4.9-3c0.9-2.2,0.5-4.3-1.2-6c-0.4-0.4-0.9-0.8-1.3-1.2
                    c2.9-2.9,5.7-5.7,8.7-8.7C15.2,7.5,15.6,8,16,8.5c1.7,1.7,3.7,2,5.9,1.1c1.9-0.8,3-2.6,3-4.9c0-0.6,0-1.2,0-1.8c2.4,0,4.7,0,7,0
                    c0,4,0,8,0,12c-5.7,0.2-10.3,2.4-13.7,7c-2.4,3.3-3.5,7-3.3,11c0.4,9,8.1,16.4,17.1,16.3c0,4,0,7.9,0,12c-2.3,0-4.6,0-7,0
                    c0-0.7,0-1.3,0-2c0-2.2-1.2-4-3-4.8c-2.2-0.9-4.1-0.6-5.9,1c-0.5,0.5-0.9,1-1.4,1.5C11.8,54,9,51.2,6,48.2c0.5-0.4,1-0.8,1.5-1.3
                    c1.6-1.7,2-3.7,1.1-5.9c-0.8-1.8-2.6-3-4.8-3C3.1,38,2.5,38,1.9,38z M12.9,16.3c0.5-0.5,1-0.9,1.3-1.3c-0.4-0.5-0.8-1-1.2-1.4
                    c-0.5,0.5-0.9,0.9-1.4,1.3C12,15.4,12.4,15.8,12.9,16.3z M13,50.5c0.4-0.5,0.9-0.9,1.3-1.3c-0.5-0.5-0.9-0.9-1.3-1.3
                    c-0.4,0.4-0.9,0.8-1.4,1.2C12,49.6,12.4,50,13,50.5z M6.8,31c-0.7,0-1.3,0-2,0c0,0.7,0,1.3,0,1.9c0.7,0,1.3,0,2,0
                    C6.8,32.3,6.8,31.7,6.8,31z M34.1,4.9c0,0.6,0,1.3,0,1.9c6.6-0.1,12.4,1.8,17.3,6.1c5,4.3,7.8,9.7,8.6,16.2c0.7-0.1,1.3-0.2,2-0.2
                    C60.3,13.8,46.8,4.1,34.1,4.9z M61.9,35.1c-0.6-0.1-1.2-0.1-1.9-0.2c-0.9,6.5-3.7,11.9-8.7,16.2c-5,4.2-10.8,6.2-17.3,6
                    c0,0.7,0,1.3,0,2C49.1,59.8,61,47.7,61.9,35.1z M39.9,25.1c-0.4,0.3-0.6,0.5-0.4,0.9c0,0.1,0.1,0.3,0.1,0.4c0.2,1.5,0.4,3,0.6,4.5
                    c2.3,0,4.6,0,6.9,0c-0.3-3.3-1.5-6.1-3.6-8.4C42.3,23.4,41.1,24.3,39.9,25.1z M26.4,38.7c0.1-0.1,0.2-0.4,0.2-0.6
                    c-0.1-0.8-0.3-1.6-0.4-2.5c-0.1-0.9-0.2-1.7-0.2-2.6c-2.3,0-4.6,0-7,0c0.3,3.3,1.5,6.1,3.6,8.4C23.9,40.5,25.1,39.6,26.4,38.7z
                    M26.6,25.6c-1.3-1-2.7-2-4-3c-2.2,2.3-3.4,5.1-3.7,8.4c2.4,0,4.7,0,6.9,0C26.1,29.1,26.4,27.3,26.6,25.6z M47,33.1
                    c-2.3,0-4.6,0-6.9,0c-0.3,1.9-0.5,3.7-0.7,5.4c1.3,1,2.6,2,4,3C45.2,39.9,47.3,35.2,47,33.1z M34.1,10.9c0,0.7,0,1.3,0,2
                    c5.8,0.1,10.6,2.4,14.5,6.7c0.5-0.5,1-0.9,1.6-1.3C45.8,13.4,40.5,11,34.1,10.9z M42.1,49.4c-2.5,1.2-5.2,1.7-8,1.8c0,0.4,0,0.7,0,1
                    c0,0.3,0,0.6,0,1c6.5-0.1,11.8-2.6,16.1-7.4c-0.5-0.5-1-0.9-1.5-1.3C46.7,46.6,44.6,48.2,42.1,49.4z M53.8,24.6
                    c-0.7,0.2-1.2,0.5-1.9,0.7c1.6,4.5,1.6,8.9,0,13.4c0.6,0.2,1.2,0.5,1.9,0.7C55.6,34.4,55.6,29.6,53.8,24.6z M32,37c0-1.3,0-2.6,0-4
                    c-1.3,0-2.6,0-4.1,0c0.2,1.6,0.4,3.1,0.6,4.7C29.8,37.5,30.9,37.3,32,37z M27.9,31c1.4,0,2.7,0,4.1,0c0-1.4,0-2.7,0-4
                    c-1.1-0.2-2.2-0.4-3.4-0.7C28.3,27.8,28.1,29.4,27.9,31z M34.1,27c0,1.3,0,2.6,0,4c1.3,0,2.6,0,4.1,0c-0.2-1.6-0.4-3.1-0.6-4.7
                    C36.3,26.5,35.2,26.8,34.1,27z M37.5,37.7c0.2-1.5,0.4-3.1,0.6-4.7c-1.4,0-2.7,0-4.1,0c0,1.4,0,2.7,0,4
                    C35.2,37.3,36.3,37.5,37.5,37.7z M27.2,23.5c0.7-1.2,1.2-2.3,1.9-3.3c0.7-1,1.6-1.8,2.8-2.2c-2.9,0.2-5.5,1.2-7.8,3.1
                    C25.2,22,26.2,22.8,27.2,23.5z M34.1,18c2.5,1,3.6,3.2,4.6,5.6c1.1-0.8,2.1-1.6,3.2-2.5C40.1,19.4,36,17.8,34.1,18z M27.3,40.4
                    c-1.1,0.9-2.1,1.7-3.2,2.5c2.4,1.9,5,2.9,7.9,3.1C29.4,45,28.3,42.8,27.3,40.4z M34.1,46c2.9-0.2,5.5-1.2,7.9-3.1
                    c-1.1-0.9-2.1-1.7-3.2-2.5C37.7,42.8,36.6,45,34.1,46z M29.2,24.4c1,0.2,1.9,0.4,2.8,0.6c0-1.6,0-3.1,0-4.7
                    C30.5,21.2,29.8,22.7,29.2,24.4z M36.9,24.4c-0.7-1.6-1.3-3.1-2.8-4.2c0,1.6,0,3.1,0,4.8C35,24.7,35.9,24.5,36.9,24.4z M32,43.8
                    c0-1.6,0-3.1,0-4.7c-0.9,0.2-1.8,0.4-2.8,0.6C29.8,41.3,30.5,42.8,32,43.8z M34.1,39.1c0,1.6,0,3.1,0,4.7c1.4-1,2.1-2.5,2.8-4.1
                    C35.9,39.5,35,39.3,34.1,39.1z M52.9,22.5c-0.4-0.7-0.8-1.4-1.2-2.1c-0.6,0.4-1.1,0.8-1.7,1.1c0.4,0.6,0.7,1.2,1.1,1.9
                    C51.8,23.1,52.3,22.8,52.9,22.5z M50,42.5c0.6,0.4,1.1,0.7,1.7,1.1c0.4-0.7,0.8-1.4,1.2-2.1c-0.6-0.3-1.1-0.6-1.8-0.9
                    C50.8,41.3,50.4,41.9,50,42.5z M60.2,33c0.7,0,1.3,0,1.9,0c0-0.7,0-1.3,0-1.9c-0.7,0-1.3,0-1.9,0C60.2,31.7,60.2,32.3,60.2,33z"/>
                </svg>
                
                <h2>Technology Assessment</h2>
                <p>Let us assess your existing technology stack to optimize it for the advancement of your business.</p>

            </div>
            </Col>
            <Col xs={{ span: 8 }} xl={{ span: 6 }}>
            <div className='servicespage_box box_icon2'>
            <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path className='svg_icons' d="M25,52.9c0-0.9,0-1.8,0-2.7c-0.3,0-0.5,0-0.8,0c-4.8,0-9.5,0-14.3,0c-3.5,0-6.3-2.1-7.1-5.4c-0.1-0.6-0.2-1.2-0.2-1.8
	c0-10.6,0-21.1,0-31.7c0-4.1,3-7.1,7.1-7.1c14.8,0,29.7,0,44.5,0c4.1,0,7.1,3,7.1,7.2c0,10.6,0,21.1,0,31.7c0,4.2-3,7.2-7.2,7.2
	c-4.8,0-9.5,0-14.3,0c-0.3,0-0.5,0-0.8,0c0,0.9,0,1.8,0,2.8c0.3,0,0.5,0,0.7,0c3.6,0,7.3,0,10.9,0c2.8,0,5,2.3,5,5
	c0,1.2-0.7,1.9-1.9,2c-0.2,0-0.3,0-0.5,0c-14.2,0-28.4,0-42.6,0c-0.2,0-0.4,0-0.6,0C9,59.8,8.3,59.1,8.3,58
	c-0.1-2.7,2.2-5.1,5.1-5.1c3.6,0,7.2,0,10.9,0C24.5,52.9,24.7,52.9,25,52.9z M32,8.3c-7.3,0-14.7,0-22,0c-2.1,0-3.2,1.1-3.2,3.2
	c0,9,0,18.1,0,27.1c0,2.1,1.1,3.2,3.2,3.2c14.6,0,29.2,0,43.8,0c2.1,0,3.2-1.1,3.2-3.2c0-9,0-18,0-27c0-1.8-1.1-3.3-3.3-3.3
	C46.6,8.3,39.3,8.3,32,8.3z M32,43.9c-1.1,0-2.1,0.9-2.1,2.1c0,1.1,0.9,2.1,2.1,2.1c1.1,0,2.1-1,2.1-2.1
	C34.1,44.8,33.1,43.9,32,43.9z M50.7,24.6c-1.9-1-3.9-2.1-5.8-3.1c-0.6-0.3-1-0.8-1-1.6c0-1.5,0-3,0-4.4c0-0.9,0-1.9,0-2.8
	c-2.7,0-5.6,3-6.2,6.2C37,22.4,39,26,42.5,27.3c3.1,1.2,7.1,0.1,8.7-2.4C51,24.8,50.9,24.7,50.7,24.6z M19.4,26.2
	c0,1.1,0.5,1.6,1.7,1.6c0.7,0,1.4,0,2.2,0c1.3,0,1.8-0.5,1.8-1.8c0-2.3,0-4.5,0-6.8c0-1.7,0-3.4,0-5c0-1.2-0.5-1.7-1.7-1.7
	c-0.7,0-1.5,0-2.2,0c-1.2,0-1.7,0.5-1.7,1.7c0,2,0,4,0,6C19.4,22.2,19.4,24.2,19.4,26.2z M12.9,30.6c-0.3,0-0.6,0-0.9,0.1
	c-0.6,0.2-0.9,0.7-0.9,1.3c0,0.6,0.3,1.1,0.9,1.3c0.3,0.1,0.6,0.1,0.9,0.1c6.2,0,12.5,0,18.7,0c0.3,0,0.5,0,0.8-0.1
	c0.6-0.2,1-0.7,1-1.3c0-0.6-0.4-1.1-1-1.3c-0.3-0.1-0.6-0.1-0.8-0.1c-3.1,0-6.2,0-9.3,0C19.1,30.6,16,30.6,12.9,30.6z M27.8,26.3
	c0,1,0.6,1.5,1.5,1.5c0.8,0,1.7,0,2.6,0c0.9,0,1.5-0.5,1.5-1.4c0-2.8,0-5.5,0-8.3c0-0.9-0.6-1.4-1.5-1.4c-0.8,0-1.6,0-2.4,0
	c-1.2,0-1.7,0.5-1.7,1.7c0,1.3,0,2.6,0,3.9C27.8,23.6,27.8,24.9,27.8,26.3z M12.9,34.8c-0.3,0-0.5,0-0.8,0.1
	c-0.7,0.1-1.1,0.7-1.1,1.4c0,0.6,0.4,1.2,1.1,1.3c0.3,0.1,0.5,0.1,0.8,0.1c4.4,0,8.7,0,13.1,0c0.3,0,0.6,0,0.8-0.1
	c0.6-0.2,1-0.7,1-1.3c0-0.6-0.4-1.2-1-1.3c-0.3-0.1-0.6-0.1-0.8-0.1c-2.2,0-4.4,0-6.5,0S15.1,34.8,12.9,34.8z M11.1,26.3
	c0,0.9,0.6,1.5,1.5,1.5c0.9,0,1.7,0,2.6,0c0.9,0,1.5-0.5,1.5-1.5c0-1.8,0-3.6,0-5.4c0-1-0.6-1.5-1.5-1.5c-0.8,0-1.7,0-2.5,0
	c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0,1.7,0,2.6C11.1,24.5,11.1,25.4,11.1,26.3z M39.2,30.6c-1.1,0-1.7,0.6-1.6,1.5
	c0.1,0.8,0.7,1.3,1.6,1.3c1.8,0,3.6,0,5.4,0c2.2,0,4.4,0,6.6,0c0.6,0,1.1-0.1,1.5-0.7c0.6-1-0.1-2.1-1.4-2.1c-2,0-4.1,0-6.1,0
	C43.3,30.6,41.2,30.6,39.2,30.6z M46.7,12.7c0,2.1,0,4.2,0,6.3c0,0.2,0.2,0.4,0.4,0.5c0.8,0.5,1.7,0.9,2.5,1.4c1,0.5,2,1,3,1.6
	C54.1,17.7,50.6,13.1,46.7,12.7z M39.1,34.8c-0.9,0-1.5,0.6-1.5,1.4c0,0.8,0.6,1.4,1.5,1.4c2.7,0,5.5,0,8.2,0c0.6,0,1-0.2,1.3-0.8
	c0.5-1-0.2-2-1.4-2c-1.3,0-2.7,0-4,0C41.8,34.8,40.5,34.8,39.1,34.8z"/>
</svg>
                <h2>IT Strategy</h2>
                <p>Let us help you define the best IT strategy roadmap that aligns seamlessly with your business strategy.</p>

            </div>
            </Col>
            <Col xs={{ span: 8 }} xl={{ span: 6 }}>

            <div className='servicespage_box box_icon3'>
            <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path className='svg_icons' d="M61,23.5c-0.4,0-0.8,0-1.2,0c-2.3,0-4.6,0-7,0c-0.2,0-0.4,0-0.7,0c0,1.2,0,2.3,0,3.5c0,1.8-1.6,3-3.3,2.5
	c-1-0.3-1.8-1.3-1.8-2.4c0-1.2,0-2.3,0-3.6c-3,0-5.9,0-8.8,0c0-2.8,0-5.5,0-8.3c0.8,0,1.6,0,2.4,0c1.3,0,2.3-1.1,2.4-2.3
	c0.1-1.3-0.7-2.5-2-2.7c-0.6-0.1-1.2-0.1-1.7-0.1c-0.3,0-0.7,0-1,0c0-0.1-0.1-0.2-0.1-0.3c0-2.2,0-4.4,0-6.6c0-0.1,0-0.2,0.1-0.3
	C45.8,3,53.4,3,61,3C61,9.8,61,16.7,61,23.5z M47.5,61c0-7.4,0-14.8,0-22.2c-2.6,0-5.1,0-7.7,0c0,0.2,0,0.4,0,0.6c0,0.9,0,1.9,0,2.8
	c0,1.4-1,2.5-2.4,2.6c-1.5,0.1-2.7-1-2.7-2.5c0-1.2,0-2.3,0-3.5c-2.6,0-5.1,0-7.8,0c0,3.3,0,6.6,0,10c-0.3,0-0.5,0-0.7,0
	c-1.2,0-2.3,0-3.5,0c-1,0-1.8,0.8-1.9,1.7c-0.1,1,0.5,1.9,1.5,2.1c0.3,0.1,0.6,0.1,1,0.1c1.2,0,2.4,0,3.6,0c0,2.8,0,5.5,0,8.3
	C33.8,61,40.7,61,47.5,61z M3,37c1.6,0,3.2,0,4.8,0c0.4-3.8,2.3-5.9,5.3-6c1.4,0,2.6,0.4,3.6,1.3c1.4,1.2,2,2.8,1.9,4.7
	c1.6,0,3.2,0,4.9,0c0-2.6,0-5.1,0-7.7c0.7,0,1.3,0,1.8,0c0.6,0,1.2,0,1.8,0c1.6-0.1,2.7-1.6,2.3-3.1c-0.3-1.2-1.3-1.9-2.5-1.9
	c-1.1,0-2.3,0-3.4,0c0-2.6,0-5.1,0-7.7c-6.9,0-13.7,0-20.6,0C3,23.4,3,30.2,3,37z M21.3,55.9c-0.7-0.2-1.4-0.6-2-1.1
	c-0.5-0.5-1-1.1-1.3-1.7c-0.3-0.7-0.5-1.4-0.5-2.2c-0.1-1.3,0.3-2.4,1.1-3.4c1.2-1.6,2.9-2.2,4.9-2.1c0-1.7,0-3.3,0-4.9
	c-2.8,0-5.5,0-8.3,0c0-1.4,0-2.7,0-4c0-1.1-0.8-2-1.9-2c-1.1,0-2,0.8-2,2c0,0.9,0,1.9,0,2.8c0,0.4,0,0.9,0,1.4c-2.8,0-5.5,0-8.3,0
	C3,47.4,3,54.2,3,61c6.9,0,13.7,0,20.5,0c0-1.6,0-3.2,0-4.8C22.8,56.1,22,56.1,21.3,55.9z"/>
</svg>
                <h2>Analytics</h2>
                <p>Hire our talented Data Analysts and Data Scientists to measure what it matters for you.</p>

            </div>
            </Col>
            <Col xs={{ span: 8 }} xl={{ span: 6 }}>

            <div className='servicespage_box box_icon4'>
            <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path className='svg_icons' d="M42.7,23.4c0-4.3,0-8.5,0-12.7c0.9,0,1.7-0.1,2.5,0c1,0.1,1.8,1,1.8,2.1c0,1,0,1.9,0,2.9c0,0.2,0.2,0.5,0.4,0.6
	c1.1,0.7,2.3,1.3,3.5,2c0.2,0.1,0.5,0.1,0.7,0c0.9-0.4,1.7-1,2.6-1.4c1.2-0.6,2.3-0.3,3,0.8c1.4,2.4,2.8,4.8,4.2,7.2
	c0.7,1.2,0.4,2.4-0.8,3.1c-0.8,0.5-1.6,0.9-2.4,1.4c-0.3,0.2-0.4,0.3-0.4,0.7c0,1.3,0,2.5,0,3.8c0,0.3,0,0.5,0.3,0.7
	c0.8,0.4,1.7,0.9,2.5,1.4c1.2,0.7,1.5,1.9,0.8,3.1c-1.4,2.4-2.8,4.8-4.2,7.2c-0.7,1.2-1.8,1.5-3,0.8c-0.9-0.5-1.7-1-2.6-1.4
	c-0.2-0.1-0.5-0.1-0.7,0c-1.2,0.6-2.3,1.3-3.5,2c-0.2,0.1-0.4,0.4-0.4,0.6c0,0.9,0,1.8,0,2.7c0,1.4-0.9,2.3-2.3,2.3
	c-0.7,0-1.3,0-2,0c0-4.3,0-8.5,0-12.8c2.7-0.1,4.9-1.1,6.6-3.2c1.4-1.7,2-3.6,1.9-5.8C51,27.7,48,23.7,42.7,23.4z M40.5,8.7
	c0-3.8-2.8-6.6-6.6-6.6c-8.4,0-16.8,0-25.2,0c-3.8,0-6.6,2.8-6.6,6.6c0,15.5,0,31,0,46.5c0,3.8,2.8,6.6,6.6,6.6c8.4,0,16.8,0,25.2,0
	c0.6,0,1.2-0.1,1.8-0.2c2.9-0.8,4.8-3.2,4.8-6.3c0-7.8,0-15.5,0-23.3C40.5,24.3,40.5,16.5,40.5,8.7z M4.3,10c0-3.6,2.2-5.8,5.8-5.8
	c7.5,0,15.1,0,22.6,0c3.5,0,5.7,2.2,5.7,5.7c0,14.7,0,29.3,0,44c0,3.5-2.2,5.7-5.8,5.7c-7.6,0-15.1,0-22.7,0c-3.5,0-5.7-2.2-5.7-5.7
	c0-7.4,0-14.7,0-22.1C4.3,24.7,4.3,17.3,4.3,10z M9.8,6.4c-2,0-3.3,1.4-3.3,3.4c0,14.8,0,29.7,0,44.5c0,2,1.4,3.3,3.3,3.3
	c7.8,0,15.5,0,23.3,0c1.9,0,3.3-1.4,3.3-3.3c0-14.9,0-29.8,0-44.6c0-1.9-1.4-3.3-3.3-3.3c-2.3,0-4.5,0-6.8,0c-0.2,0-0.3,0-0.5,0
	c-0.4,2.7-2.1,4.3-4.5,4.2C19,10.6,17.3,9,17,6.4C14.6,6.4,12.2,6.4,9.8,6.4z M14,34.4c1.6,1.4,3.1,2.7,4.7,4.1
	c0.4,0.3,0.6,0.7,0.5,1.3c-0.3,0.8-1.1,1-1.8,0.4c-1.2-1.1-2.5-2.2-3.7-3.2c-1.5-1.3-3-2.6-4.5-3.9c-0.8-0.7-0.8-1.3,0-2
	c2.7-2.4,5.4-4.7,8.1-7.1c0.6-0.6,1.3-0.6,1.7-0.1c0.4,0.5,0.3,1.1-0.3,1.7c-2.2,1.9-4.4,3.9-6.7,5.8c-0.2,0.2-0.5,0.4-0.7,0.7
	C12.2,32.8,13.1,33.6,14,34.4z M30.5,31.2c-2.2-1.9-4.3-3.8-6.5-5.7c-0.4-0.4-0.7-0.8-0.5-1.4c0.3-0.7,1.1-0.9,1.8-0.3
	c1.2,1,2.4,2.1,3.6,3.2c1.5,1.3,3,2.6,4.5,3.9c0.9,0.8,0.9,1.3,0,2.1c-2.7,2.4-5.4,4.7-8.1,7.1c-0.6,0.5-1.1,0.6-1.6,0.1
	c-0.5-0.5-0.5-1.2,0.2-1.7c2.2-1.9,4.4-3.9,6.7-5.8c0.2-0.2,0.5-0.4,0.8-0.7C31.1,31.7,30.8,31.4,30.5,31.2z"/>
</svg>
                <h2>Application Development</h2>
                <p>Allow us to strengthen your development capabilities with top-tier software engineers who possess expertise across various technologies.</p>

            </div>
            </Col>
    

            <Col xs={{ span: 8 }} xl={{ span: 6 }}>

            <div className='servicespage_box  box_icon5'>
            <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path className='svg_icons' d="M33.2,47.9c0,1.6,0,3,0,4.6c1.4,0,2.8,0,4.1,0c2.2,0,3.8,0.9,4.6,3c0.2,0.5,0.5,0.4,0.8,0.4c5.3,0,10.5,0,15.8,0
	c0.2,0,0.3,0,0.5,0c0.9,0,1.4,0.4,1.4,1.1c0,0.7-0.5,1.1-1.4,1.1c-5.4,0-10.8,0-16.2,0c-0.5,0-0.8,0.2-1,0.6
	c-0.8,1.8-2.2,2.7-4.1,2.8c-3.8,0-7.6,0-11.5,0c-2,0-3.4-1-4.1-2.8c-0.2-0.5-0.5-0.6-0.9-0.6c-5.3,0-10.6,0-16,0c-0.2,0-0.4,0-0.6,0
	c-0.7,0-1.1-0.5-1.1-1.1s0.4-1.1,1.1-1.1c0.2,0,0.4,0,0.6,0c5.3,0,10.6,0,16,0c0.5,0,0.7-0.1,0.9-0.6c0.8-1.8,2.2-2.8,4.2-2.8
	c1.5,0,3,0,4.5,0c0-1.5,0-3,0-4.6c-0.3,0-0.5,0-0.8,0c-7,0-14.1,0-21.1,0c-3.3,0-5.4-2-5.4-5.4c0-1.1,0-2.2,0-3.3
	c0-2.8,2.1-4.9,4.9-4.9c15.7,0,31.4,0,47.1,0c2.8,0,4.9,2.1,4.9,4.9c0,1.2,0,2.5,0,3.7c0,2.9-2.1,5-5.1,5c-7.1,0-14.3,0-21.4,0
	C33.7,47.9,33.5,47.9,33.2,47.9z M17,40c-0.6,0-1.1,0-1.7,0c0,0,0,0-0.1,0c-0.7,0-1.2,0.5-1.2,1.1c0,0.7,0.5,1.1,1.2,1.1
	c1.2,0,2.4,0,3.5,0c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.1-1.1-1.1C18.2,39.9,17.6,40,17,40z M24.2,42.2c0.6,0,1.2,0,1.8,0
	c0.7,0,1.2-0.5,1.2-1.1c0-0.7-0.5-1.1-1.2-1.1c-1.2,0-2.4,0-3.5,0c-0.7,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.1,1.1,1.1
	C23,42.2,23.6,42.2,24.2,42.2z M10.3,42.2c0.4,0,0.8,0,1.1,0c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.1-1.2-1.1c-0.8,0-1.5,0-2.3,0
	C8.4,39.9,8,40.4,8,41s0.5,1.1,1.2,1.1C9.6,42.2,9.9,42.2,10.3,42.2z M30.8,42.2c0.4,0,0.8,0,1.2,0c0.7,0,1.1-0.5,1.1-1.2
	c0-0.6-0.5-1.1-1.1-1.1c-0.8,0-1.6,0-2.3,0c-0.7,0-1.1,0.5-1.1,1.2c0,0.6,0.5,1.1,1.1,1.1C30.1,42.2,30.4,42.2,30.8,42.2z M32,18.3
	c7.7,0,15.4,0,23.1,0c3.3,0,5.3,2,5.3,5.4c0,1.1,0,2.2,0,3.3c0,2.8-2.1,4.9-4.9,4.9c-15.7,0-31.4,0-47.1,0c-2.8,0-4.9-2.2-4.9-4.9
	c0-1.1,0-2.2,0-3.3c0-3.3,2-5.4,5.3-5.4C16.6,18.3,24.3,18.3,32,18.3z M17,24c-0.6,0-1.1,0-1.7,0h-0.1c-0.7,0.1-1.1,0.5-1.1,1.1
	s0.4,1.1,1.1,1.1c1.2,0,2.4,0,3.6,0c0.7,0,1.1-0.5,1.2-1.1c0-0.7-0.4-1.1-1.2-1.2C18.2,24,17.6,24,17,24z M24.3,26.3
	c0.6,0,1.1,0,1.7,0c0.7,0,1.2-0.5,1.2-1.1c0-0.7-0.4-1.1-1.2-1.1c-1.2,0-2.4,0-3.5,0c-0.7,0-1.2,0.5-1.2,1.1c0,0.7,0.5,1.1,1.2,1.1
	C23.1,26.3,23.7,26.3,24.3,26.3z M10.3,26.3c0.4,0,0.8,0,1.2,0c0.7,0,1.1-0.5,1.1-1.2c0-0.6-0.5-1.1-1.1-1.1c-0.8,0-1.6,0-2.3,0
	c-0.6,0-1.1,0.5-1.1,1.1s0.4,1.1,1.1,1.2C9.5,26.3,9.9,26.3,10.3,26.3z M30.9,24c-0.4,0-0.9,0-1.3,0c-0.6,0.1-1,0.5-1,1.1
	s0.4,1.1,1,1.2c0.8,0,1.7,0,2.5,0c0.6,0,1.1-0.6,1-1.2c0-0.6-0.4-1-1-1.1C31.7,24,31.3,24,30.9,24z M31.9,16.1c-7.7,0-15.4,0-23.1,0
	c-3.3,0-5.4-2-5.4-5.4c0-1.1,0-2.2,0-3.3c0-2.9,2.1-5,5-5c9.1,0,18.2,0,27.3,0c6.4,0,12.9,0,19.3,0c3.3,0,5.4,2,5.4,5.4
	c0,1.1,0,2.2,0,3.3c0,2.9-2.1,5-5,5C47.6,16.1,39.8,16.1,31.9,16.1z M16.9,10.4c0.6,0,1.2,0,1.8,0c0.7,0,1.2-0.5,1.2-1.1
	c0-0.7-0.4-1.1-1.2-1.1c-1.2,0-2.4,0-3.5,0c-0.7,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.1,1.1,1.1C15.7,10.4,16.3,10.4,16.9,10.4z M24.3,8.1
	c-0.6,0-1.2,0-1.8,0c-0.7,0-1.1,0.5-1.1,1.1s0.4,1.1,1.1,1.1c1.2,0,2.5,0,3.7,0c0.7,0,1.1-0.5,1.1-1.1s-0.4-1.1-1.1-1.1
	S24.9,8.1,24.3,8.1z M10.3,10.4c0.4,0,0.7,0,1.1,0c0.7,0,1.2-0.5,1.2-1.1s-0.5-1.1-1.2-1.1s-1.5,0-2.2,0S8.1,8.7,8,9.3
	c0,0.6,0.5,1.1,1.2,1.2C9.6,10.4,10,10.4,10.3,10.4z M30.9,10.4c0.4,0,0.7,0,1.1,0c0.7,0,1.1-0.5,1.1-1.1S32.7,8.2,32,8.2
	c-0.8,0-1.5,0-2.3,0c-0.7,0-1.1,0.5-1.1,1.1s0.4,1.1,1.1,1.1C30.1,10.4,30.5,10.4,30.9,10.4z"/>
</svg>
                
                <h2>Data Engineering</h2>
                <p>Recruit exceptional data engineers who possess extensive experience in working with cloud-based data stores.</p>
            </div>
            </Col>
            <Col xs={{ span: 8 }} xl={{ span: 6 }}>
            <div className='servicespage_box box_icon6'>
            <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path className='svg_icons' d="M24.4,55.7c0-4.3,0-8.7,0-13.1c10.3,0,20.7,0,31.1,0c0-0.3,0-0.6,0-0.8c0-4.9,0-9.8,0-14.7c0-0.9,0.4-1.7,0.7-2.5
	c0.1-0.4,1.2-0.6,1.6-0.5c0.7,0.2,1,0.6,1,1.4c0,6.5,0,13.1,0,19.6c0,2.1-1.1,3.7-3,4.1c-0.5,0.1-1,0.1-1.5,0.1c-4.2,0-8.3,0-12.5,0
	c-0.3,0-0.5,0-0.8,0c0,2.1,0,4.2,0,6.4c0.2,0,0.5,0,0.7,0c1.7,0,3.5,0,5.2,0c0.7,0,1.5,0.2,2.2,0.5c1,0.4,1.5,1.3,1.4,2.4
	c0,0.6,0,1.2,0,1.8c0,0.5-0.2,0.8-0.8,0.8c-0.5,0-1,0-1.4,0c-10.7,0-21.4,0-32.1,0c-1.1,0-1.2-0.1-1.2-1.2c0-0.3,0-0.7,0-1
	c0-1.7,0.9-2.8,2.6-3.1c0.9-0.1,1.9-0.1,2.8-0.1C21.7,55.8,23,55.8,24.4,55.7C24.4,55.8,24.4,55.7,24.4,55.7z M46.5,22.1
	c0.8-0.5,1.6-0.6,2.4,0c0.3,0.2,0.8,0.3,1.2,0.5c0.2,0.1,0.4,0.2,0.5,0.4c0.2,1.5,1.2,1.7,2.4,1.5c0.2,0,0.5,0,0.7,0
	c0.2-0.1,0.5-0.2,0.5-0.4c0.2-1.1,0.9-1.7,2-1.9h0.1c0.8-0.6,1.7-0.6,2.5,0c0.3,0.2,0.5,0.1,0.8-0.1c0.4-0.4,0.8-0.8,1.2-1.2
	c0.4-0.4,0.6-0.7,0.2-1.2c-0.5-0.7-0.5-1.3,0-2c0.3-0.4,0.3-0.9,0.6-1.4c0.1-0.2,0.3-0.5,0.4-0.5c1.3-0.2,1.7-1,1.5-2.3
	c0-0.2,0-0.4,0-0.7c0.1-0.5-0.1-0.7-0.6-0.8c-0.9-0.2-1.4-0.7-1.6-1.6c-0.1-0.4-0.4-0.8-0.6-1.2c-0.1-0.2-0.1-0.5,0-0.7
	c0.8-1.1,0.4-1.9-0.5-2.6l-0.1-0.1c-0.8-0.8-0.8-0.9-1.9-0.3c-0.5,0.2-0.9,0.2-1.3,0c-0.5-0.3-1.1-0.5-1.7-0.7
	c-0.2-0.1-0.5-0.3-0.5-0.4c-0.2-1.4-1.1-1.7-2.3-1.5c-0.1,0-0.3,0-0.5,0c-0.6-0.1-0.9,0.1-1,0.7c-0.2,0.8-0.6,1.3-1.4,1.4
	c-0.6,0-1,0.3-1.5,0.5c-0.2,0.1-0.5,0.1-0.6,0.1c-1.2-0.9-2.1-0.4-2.8,0.6c0,0.1-0.1,0.1-0.1,0.1c-0.6,0.4-0.7,0.9-0.3,1.5
	s0.4,1.2,0,1.8c-0.3,0.4-0.3,0.9-0.6,1.4c-0.1,0.2-0.3,0.5-0.5,0.6c-1.4,0.6-1.4,0.6-1.4,2.1c0,0.1,0,0.1,0,0.2c0,1.2,0,1.1,1.1,1.6
	c0.3,0.1,0.6,0.4,0.8,0.6c0.3,0.6,0.6,1.3,0.8,2c0.1,0.2,0.1,0.5,0,0.7c-0.8,1.2-0.4,2,0.6,2.7c0.2,0.2,0.4,0.4,0.6,0.6
	c0.1,0.1,0.2,0.2,0.3,0.2C46.2,22.3,46.3,22.2,46.5,22.1z M47,13.6c0-3.1,2.5-5.6,5.6-5.6c3,0,5.5,2.5,5.6,5.5
	c0,3.1-2.5,5.6-5.6,5.6C49.5,19.2,47,16.7,47,13.6z M0.5,45.2c-0.1,0.8,0.2,1.2,1,1.4c0.6,0.1,0.9,0.4,1.1,1.1
	c0.1,0.6,0.5,1.1,0.7,1.6c0.1,0.2,0.1,0.5,0,0.6c-0.8,1.1-0.4,2,0.6,2.7C4,52.8,4.1,52.9,4.2,53c0.3,0.5,0.7,0.6,1.2,0.2
	c0.7-0.5,1.3-0.5,2,0c0.4,0.3,0.9,0.4,1.4,0.6c0.2,0,0.5,0.2,0.5,0.3c0.2,1.5,1.2,1.7,2.4,1.5c0.1,0,0.3,0,0.5,0
	c0.5,0.1,0.8-0.1,0.9-0.6c0.2-0.9,0.7-1.2,1.5-1.6c1.2-0.5,2.2-0.9,3.4,0.1c0.7-0.7,1.3-1.3,1.9-2C20,51.4,20,51.1,20,51
	c-0.6-1-0.6-1.9,0.1-2.9c0,0,0,0,0-0.1c0.1-1.1,0.8-1.6,1.8-1.8c0.4-0.1,0.5-0.3,0.4-0.7c0-0.6,0-1.2,0-1.8c0-0.5-0.1-0.7-0.6-0.8
	c-0.9-0.2-1.4-0.7-1.6-1.6c-0.1-0.4-0.4-0.8-0.5-1.3c-0.1-0.2-0.1-0.5-0.1-0.6c0.9-1.2,0.3-2-0.7-2.8c-0.2-0.1-0.3-0.4-0.5-0.5
	s-0.5-0.2-0.6-0.1c-0.9,0.6-1.8,0.6-2.7,0c-0.1,0-0.1-0.1-0.2-0.1c-1-0.1-1.6-0.7-1.8-1.7c-0.1-0.4-0.3-0.5-0.7-0.5
	c-0.6,0-1.1,0-1.7,0c-0.6-0.1-0.9,0.1-1,0.7c-0.2,0.8-0.6,1.3-1.4,1.4c-0.5,0.1-0.9,0.4-1.4,0.6c-0.2,0.1-0.5,0.1-0.6,0.1
	c-1.2-0.9-2-0.3-2.8,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.3-0.5,0.6-0.2,1.1c0.5,0.7,0.4,1.4,0,2.2c-0.5,1.2-1,2.2-2.4,2.3
	C0.5,44,0.6,44.6,0.5,45.2z M11.4,50.4c-3.1,0-5.6-2.5-5.6-5.6c0-3,2.5-5.5,5.5-5.6c3.1,0,5.6,2.4,5.6,5.5
	C17,47.9,14.5,50.4,11.4,50.4z M40.5,16.8c-0.9-0.1-0.9-0.7-0.8-1.3c0-0.6,0-1.2,0-1.9c-0.2,0-0.4,0-0.5,0c-9.5,0-19,0-28.4,0
	c-2.5,0-4.1,1.7-4.2,4.2c0,5.3,0,10.7,0,16c0,0.2,0,0.4,0,0.5c0.7-0.1,1.2-0.5,1.4-1.2c0.2-1,0.7-1.6,1.8-1.4c0,0,0.1,0,0.2-0.1
	c0-0.2,0-0.5,0-0.7c0-4.3,0-8.5,0-12.8c0-1.2,0-1.2,1.2-1.2c9.5,0,19,0,28.5,0C40,16.9,40.2,16.9,40.5,16.8
	C40.4,16.9,40.4,16.9,40.5,16.8z M24.3,29.2c1.3-1.1,2.6-2.2,3.9-3.3c0.4-0.4,0.5-0.8,0.3-1.3c-0.2-0.4-0.6-0.6-1.1-0.5
	c-0.3,0.1-0.5,0.3-0.8,0.5c-1.7,1.4-3.4,2.9-5.1,4.3c-0.8,0.7-0.8,1.3,0,2c1.5,1.2,2.9,2.5,4.4,3.7c0.4,0.3,0.7,0.6,1.1,0.9
	c0.5,0.3,1,0.3,1.4-0.1c0.4-0.4,0.4-1,0-1.4c-0.2-0.2-0.4-0.3-0.5-0.5c-1.4-1.2-2.7-2.3-4.2-3.5C23.9,29.6,24.1,29.4,24.3,29.2z
	 M37.3,33.6c-0.6,0.5-0.7,1.1-0.3,1.6s1,0.5,1.6,0c1.7-1.5,3.5-2.9,5.2-4.4c0.9-0.7,0.9-1.3,0-2c-1.6-1.4-3.2-2.7-4.8-4
	c-0.2-0.2-0.5-0.4-0.8-0.6c-0.5-0.3-0.9-0.2-1.2,0.2c-0.3,0.4-0.4,0.8-0.1,1.3c0.1,0.2,0.3,0.3,0.5,0.5c1.4,1.2,2.9,2.4,4.4,3.7
	C40.3,31.1,38.8,32.4,37.3,33.6z M30.8,37.3c0.6,0.1,1-0.1,1.2-0.7c0.1-0.1,0.1-0.3,0.1-0.4c1.1-4,2.2-8.1,3.3-12.1
	c0.3-1,0-1.5-0.6-1.7c-0.7-0.2-1.1,0.2-1.4,1.2c0,0,0,0,0,0.1c-0.8,2.9-1.6,5.8-2.3,8.7c-0.3,1.3-0.7,2.6-1,3.7
	C29.9,36.7,30.3,37.2,30.8,37.3z"/>
</svg>
                <h2>DevOps Engineering</h2>
                <p>Hire experienced and certified SRE engineers to help ensure reliability, availability, security and automation.</p>

            </div>
            </Col>
            <Col xs={{ span: 8 }} xl={{ span: 6 }}>
            <div className='servicespage_box box_icon7'>
            <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path className='svg_icons' d="M54.5,16.6c0.9,2.1,1.7,4.2,2.6,6.3c0.2,0.4,0.2,0.8,0,1.3c-0.5,0.9-0.9,1.7-1.4,2.6c-0.7,1.3-0.7,2.5,0.1,3.5
	c0.8,1,1.9,1.2,3.3,0.7c0.1,0,0.1,0,0.3-0.1c0.1,1,0.1,2,0.2,3c0.3,5.1,0.5,10.2,0.8,15.3c0.1,2,0.2,4,0.3,5.9c0,0.6,0,1.1-0.1,1.7
	c-0.5,1.6-1.7,2.1-3,2c-1.1,0-2.3-1.1-2.4-2.4c-0.2-1.9-0.2-3.8-0.3-5.6c-0.2-3.4-0.3-6.8-0.5-10.3c0-0.1,0-0.2-0.1-0.3
	c-0.1,0.2-0.1,0.4-0.2,0.6c-1.5,4.8-2.9,9.7-4.4,14.5c-0.4,1.3-1.4,2.1-2.6,2.1c-1.2,0-2.3-0.7-2.7-1.8c-0.1-0.5-0.2-1.2,0-1.7
	c1.7-5.8,3.4-11.5,5.1-17.3c0-0.1,0.1-0.1,0.1-0.2c0.7,0.4,1.4,0.8,2.1,1.1c0.5,0.2,1.2,0.3,1.7,0.4c0.8,0.1,0.9,0,0.8-0.9
	c-0.3,0-0.5,0-0.8-0.1c-2.5-0.3-4.4-1.9-4.9-4.4c-0.9-4.1-2.4-7.9-4.2-11.6c-0.8-1.7-1.1-3.3-0.3-5.1c0.8-2,3.2-3.6,5.4-3.5
	c2.2,0.1,4.4,0,6.6,0.5c1.9,0.5,3.7,1.2,5,2.7c1.6,1.7,1.9,3.8,1.6,6.1c-0.4,2.8-1.6,5.3-3,7.7c-0.5,0.9-1.6,1.1-2.5,0.6
	c-0.9-0.5-1.2-1.6-0.7-2.5c0.9-1.7,1.9-3.3,2.3-5.2c0.1-0.3,0.2-0.6,0.2-0.9c0.3-2.5-0.7-3.9-3.2-4.6C55.2,16.8,54.9,16.7,54.5,16.6
	z M16.6,14.8c1.2,0,1.7-0.5,1.7-1.7c0-2.6,0-5.1,0-7.7c0-1.2-0.5-1.7-1.7-1.7c-3.7,0-7.5,0-11.2,0c-1.1,0-1.7,0.5-1.7,1.7
	c0,2.6,0,5.1,0,7.7c0,1.2,0.5,1.7,1.7,1.7c1.9,0,3.7,0,5.6,0c0.4,0,0.8,0.1,1.2,0.3c1.3,0.7,2.5,1.4,3.8,2.1
	c-0.3-0.9-0.6-1.6-0.9-2.4C15.7,14.8,16.1,14.8,16.6,14.8z M30.3,46c1.2-0.3,2.5-0.8,3.7-1.3c0.9-0.4,1.3-1.5,1-2.5
	c-0.2,0-0.4,0-0.6,0c-4,0-8,0-12,0c-0.7,0-1.5,0.2-2.1,0.5c-1.1,0.5-1.6,1.5-1.4,2.7c0.3,1.7,0.5,3.4,0.8,5.1c0.5,3,0.9,6.1,1.4,9.1
	c0.2,1.5,1.7,2.4,3.2,2.2c1.4-0.2,2.5-1.6,2.3-3.1c-0.3-2.1-0.6-4.3-0.9-6.4c-0.2-1.7-0.5-3.4-0.7-5.1C26.7,46.9,28.5,46.5,30.3,46z
	 M44,44.2c0.9,0,1.6,0,2.5,0c0-0.7,0-1.3,0-1.9c-3.5,0-6.9,0-10.3,0c-0.2,0.7-0.2,1.4-0.5,1.9c-0.6,1.3-1.8,1.9-3.1,2.2
	c-2.2,0.5-4.4,1.1-6.7,1.6c0.1,0.4,0.1,0.8,0.2,1.2c0.4,0,0.7,0.1,1,0.1c0.7,3.9,1.4,7.9,2.1,11.8c0.3-0.1,0.6-0.1,0.9-0.2
	c-0.7-3.9-1.4-7.8-2.1-11.7c2.4,0,4.7,0,7.1,0c-0.7,3.9-1.4,7.8-2.1,11.7c0.3,0.1,0.6,0.1,0.9,0.2c0.7-4,1.4-7.9,2.1-11.9
	c0.6,0,1.2,0,1.9,0c0.7,4,1.4,7.9,2.1,11.9c0.3-0.1,0.6-0.1,0.9-0.2c0-0.2,0-0.3,0-0.5c-0.3-1.8-0.7-3.6-1-5.4
	c-0.4-2.7-1.2-5.3-0.9-8.1c0.1-0.9,0.1-1.8,0.1-2.7c1,0,2,0,3,0c0,5.9,0,11.7,0,17.6c0.6,0,1.2,0,1.9,0C44,55.9,44,50.1,44,44.2z
	 M47.6,41.1c0-0.9,0-1.8,0-2.7c-15.3,0-30.6,0-45.9,0c0,0.9,0,1.8,0,2.7C17.1,41.1,32.3,41.1,47.6,41.1z M20.8,36.3c-2,0-4,0-6,0
	c-0.5,0-0.7-0.2-0.8-0.6c-0.7-2.1-1.5-4.1-2.2-6.2c-0.2-0.4-0.4-0.6-0.9-0.6c-2.9,0-5.8,0-8.8,0c-0.8,0-0.9,0.2-0.6,0.9
	c0.9,2.4,1.8,4.7,2.7,7.1c0.1,0.4,0.3,0.5,0.8,0.5c5.3,0,10.7,0,16,0c0.2,0,0.4,0,0.6,0c0-0.4,0-0.7,0-1.1
	C21.3,36.3,21,36.3,20.8,36.3z M7.5,33.6c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.5-0.8,0.9-0.8c0.5,0,0.8,0.4,0.8,0.9
	C8.3,33.2,7.9,33.6,7.5,33.6z M49,7.5c0-3-2.3-5.3-5.3-5.3c-3,0-5.3,2.3-5.3,5.3c0,2.9,2.3,5.3,5.3,5.3C46.6,12.8,49,10.4,49,7.5z
	 M25.7,18.7c0,2.9,2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3c0-2.9-2.4-5.3-5.3-5.3C28.1,13.4,25.7,15.8,25.7,18.7z M30.5,10.1
	c0.3-0.2,0.7-0.3,1.1-0.3c1.3,0,2.6,0,3.8,0c0.7,0,1-0.3,1-0.9c0-1.9,0-3.9,0-5.8c0-0.6-0.4-1-1-1c-2.7,0-5.4,0-8.1,0
	c-0.6,0-1,0.4-1,1c0,1.9,0,3.8,0,5.7c0,0.7,0.3,1,1,1c0.3,0,0.7,0,1.1,0c-0.2,0.6-0.3,1.1-0.5,1.7C28.9,11,29.6,10.5,30.5,10.1z
	 M36.9,30.9c-0.2-3.4-2.8-5.6-6.1-5.4c-2.5,0.2-4.9,2.5-5,4.9c0.2-0.1,0.4-0.2,0.5-0.3c1-0.5,1.9-1.1,2.9-1.6c1.4-0.8,3.1-0.3,3.8,1
	c0.8,1.4,0.4,3.1-1,3.9c-2.2,1.3-4.5,2.5-6.7,3.8c-0.1,0.1-0.2,0.1-0.4,0.2c3.8,0,7.5,0,11.3,0C36.7,35.3,37.1,33.1,36.9,30.9z
	 M21.6,26.7c1.1,0,1.4-0.3,1.4-1.4c0-1.5,0-3,0-4.5c0-0.9-0.3-1.3-1.3-1.3c-2.3,0-4.6,0-7,0c-0.9,0-1.2,0.3-1.2,1.2
	c0,1.6,0,3.2,0,4.8c0,0.9,0.3,1.2,1.2,1.2c1.2,0,2.3,0,3.5,0c0.3,0,0.6,0.1,0.8,0.2c0.8,0.4,1.5,0.8,2.4,1.3c-0.2-0.6-0.3-1-0.5-1.5
	C21.2,26.7,21.4,26.7,21.6,26.7z M6.2,44.2c0.3,0,0.5,0,0.8,0c3.5,0,7,0,10.5,0c0.2,0,0.5,0,0.6-0.2c0.4-0.6,0.7-1.2,1-1.8
	c-5.1,0-10.3,0-15.6,0c0,0.6,0,1.3,0,1.9c0.3,0,0.6,0.1,0.9,0.1c0,5.8,0,11.5,0,17.3c0.6,0,1.1,0,1.8,0C6.2,55.7,6.2,50,6.2,44.2z
	 M23.8,31.9c-0.9-0.8-1.8-1.7-2.8-2.5c-0.8-0.7-2-0.5-2.6,0.2c-0.7,0.8-0.5,1.9,0.3,2.6c0.7,0.6,1.4,1.2,2.1,1.9
	c0.8,0.7,1.6,1.4,2.4,2c0.6,0.5,1.3,0.5,2,0.1c2.2-1.2,4.4-2.4,6.5-3.7c0.3-0.2,0.6-0.5,0.8-0.9c0.3-0.7,0.1-1.6-0.4-2.1
	c-0.6-0.5-1.5-0.6-2.2-0.2c-1.8,1-3.5,2-5.3,3C24.2,32.2,24,32.1,23.8,31.9z M42.4,31.3c1.4-1.1,2.5-2.6,3.3-4.1
	c0.1-0.1,0.1-0.3,0.1-0.4c-0.6-1.6-1.3-3.2-1.9-4.8c-0.2,0.2-0.2,0.3-0.3,0.5c-0.6,2.5-1.8,4.6-3.8,6.3c-0.7,0.6-1.1,1.2-0.7,2.2
	c-0.4,0-0.7,0-0.9,0c-0.2,2.1-0.5,4.2-0.7,6.3c0.8,0,1.6,0,2.5,0c0.2-1.7,0.3-3.4,0.4-4.8C41.2,32,41.8,31.8,42.4,31.3z"/>
</svg>
                <h2>Project Management</h2>
                <p>Bring onboard highly experienced project and program managers who demonstrate a strong sense of accountability.</p>

            </div>
            </Col>
           
            
        </Row> 
            
        </div>
        <div className='services_tech'>
            <Row className='services_tech_container'>
                
                <Col className='services_div_right' xs={{ span: 10 }} lg={{ span: 10 }}>
                    <h1>Technologies</h1>
                    <div className='line'></div>
                    <p>AWS, Microsoft, .NET, J2EE, Python, Go, PostgreSQL, Oracle, MS SQL Server, DataBricks, Informatica, Talend, Power BI, Azure DevOps, React, Angular, Java Script Front-end Developer, 
</p><p>Etc. 
</p>
                    
                </Col>
                <Col xs={{ span: 14 }} lg={{ span: 13 , offset: 1}}>
                    <Row>
                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                        
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>
                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                        
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>
                        
                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>
                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                        
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>
                        
                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>
                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                        
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>

                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>
                        
                        <Col className='brand_list' xs={{ span: 6 }} lg={{ span: 6}}>
                            
                        </Col>
                        


                    </Row>

                    
                </Col>
            </Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default Servicespage