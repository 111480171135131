import React from 'react';
import './footer.css';
import { Menu, Col, Divider, Row } from 'antd';
import LogoFooter from '../../assets/logo-footer.svg'
import { BsTwitter, BsFacebook, BsLinkedin, BsInstagram, BsPinterest } from "react-icons/bs";



const Footer = () => {
  return (
    <>
    <div className='footer'>
      <div className='container'>
        
        <Row className='footer_columns'>
            <Col xs={{ span: 8 }} lg={{ span: 7 }}>
              <img className='logo_footer' src={LogoFooter} alt='' />
              <h2>Corporate Office</h2>
              <p>Dhansol Software Solutions, LLC</p>
              <p>1 Gasek Farms Road,</p>
			        <p>Ellington, CT 06029</p>
                  
            </Col>
            <Col xs={{ span: 4 }} lg={{ span: 5 }}>

              <h1>Menu</h1>
              <ul className='footer_menu'>
                  <li><a href='#'>Home</a></li>
                  <li><a href='#'>Services</a></li>
                  <li><a href='#'>Industries</a></li>
                  <li><a href='#'>Insights</a></li>
                  <li><a href='#'>Career</a></li>
                  <li><a href='#'>About Us</a></li>
                  <li><a href='#'>Contact Us</a></li>

              </ul>

            </Col>
            <Col xs={{ span: 6 }} lg={{ span: 6 }}>
            <h1>Contacts</h1>
            <p className='menu_p'><span>Toll Free:</span> (866) 342-6765</p>
            <p className='menu_p'><span>T: </span>(203) 294 0870</p>
            <p className='menu_p'><span>F: </span>(860) 350 2050</p>
            <p className='menu_p'><span>E: </span>www.dhansol.com</p>
                  
            </Col>
            <Col xs={{ span: 6 }} lg={{ span: 6 }}>
            <h1>Social Media</h1>
            <a className='sm'><BsTwitter /></a>
            <a className='sm'><BsFacebook /></a>
            <a className='sm'><BsLinkedin /></a>
            <a className='sm'><BsInstagram /></a>
            <a className='sm'><BsPinterest /></a>
            </Col>
        </Row>

        
      </div>
      <div className='container container_copyrights'>
        <Row className='copyrights'>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                <p>COPYRIGHT <a href='#'>DHANSOL</a> @2023</p>

            </Col>

          </Row>
        </div>
    </div>
    </>
  )
}

export default Footer