import './contactus.css';
import { Headersub, Footer} from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';
import { BiHeadphone, BiMailSend } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { Contact } from '../../components';



const Contactus = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>contactus</h1>
    </div>
    <div className='contactus_page'>
        
        <div className='contactus_box_container'>
        <Row>
          <Col className='contactus_box' xs={{ span: 24,offset:0}} sm={{ span: 8,offset:0}} xl={{ span: 7,offset:0 }}>
              <div className='contactus_icons'><BiHeadphone /></div>
              <h2>Contact With Phone Number</h2>
              <p>T: (203) 294 0870</p>
			        <p>F: (203) 294 0873</p>

          </Col>
          <Col className='contactus_box' xs={{ span: 24,offset:0}} sm={{ span: 8,offset:0}} xl={{ span: 7,offset:0 }}>
              <div className='contactus_icons'><BiMailSend /></div>
              <h2>Email Address</h2>
              <p>T: (203) 294 0870</p>
			        <p>F: (203) 294 0873</p>

          </Col>
          <Col className='contactus_box' xs={{ span: 24,offset:0}} sm={{ span: 8,offset:0}} xl={{ span: 7,offset:0 }}>
              <div className='contactus_icons'><HiLocationMarker /></div>
              <h2>Location</h2>
              <p>Dhansol Software Solutions, LLC</p>
              <p>    1 Gasek Farms Road, Ellington, CT 06029</p>
              

          </Col>
        </Row>
        
        </div>
        <div className='contactus_box_container'>
        <Row>
          <Col className='contactus_form' xs={{ span: 24,offset:0}} sm={{ span: 12,offset:0}} xl={{ span: 12,offset:0 }}>
              <h1>Contact Us.</h1>
              <p>Reach out to us for any inquiries or assistance today!</p>
              <Contact />
          </Col>
          <Col className='contactform_rows' xs={{ span: 24,offset:0}} sm={{ span: 12,offset:0}} xl={{ span: 12,offset:0 }}>
            <div className='contactus_image'></div>
         </Col>
        </Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default Contactus