import './career.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const Career = () => {

    
  return (
    <><Headersub />
    
    <div className='services_banner'>
        <h1>Career</h1>
    </div>
    <div className='career_page'>
    <div className='container fix'>
        <Row className='career_page_container'>
            <Col xs={{ span: 23 }} lg={{ span: 11, offset:2 }}>
            <h1>Why should you be part of Dhansol?</h1>
            
                
            </Col>
            <Col className='services_div_right' xs={{ span: 23 }} lg={{ span: 11 }}>
                
            </Col>
			<Col xs={{ span: 23 }} lg={{ span: 9 }}>
            
                
            </Col>
            <Col className='services_div_right' xs={{ span: 23 }} lg={{ span: 12, offset:-1 }}>
                <p>At Dhansol, we understand and follow a unique business philosophy in building our family. We believe our success in meeting our customer’s and our own high standards depends on our ability to attract and reward highly talented, motivated and experienced individuals. </p>
            </Col>
        </Row>
        <div className='career_box_container'>
        

        <Row className='career_s2_container'>
            
            <Col className='career_div_right' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 10,offset:2 }}>
                <div className='career_s2_box_container'>
                     
					
				<p>We're searching for the very best and brightest, experienced professionals who know how to lead a team to success, early-career professionals with innovative ideas, customer-focused associates adept at nurturing business relationships, students graduating from the top of their class, and people who can help us achieve the new heights in the technology industry.</p><br/>
				
					
				
                </div>
            </Col>
            <Col className='career_div_right' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset: -2}}>
			<div className='bg_box1'></div>
			<div className='overlay'></div>
			</Col>
        </Row>

		<Row className='career_s2_container'> 
		<Col className='career_div_right' xs={{ span: 23,offset:-1}} sm={{ span: 24,offset:0}} xl={{ span: 20,offset: 2}}>
			<div className='career_s2_box_container'>
				<br/><p>If you're motivated and results-driven, energized and hard-working, Dhansol may be the place for you. And if you're looking for an employer that is strongly committed to developing talent and rewarding accomplishment, you're looking in the right place. </p><br/>
					<p>We perform every means to make our employees successful, because employee success fuels company success. It's that simple. We value our employees as our customers. </p><br/>
					<p>Dhansol has an open policy with our employees and builds a trust worthy relationship that unifies us every minute. As a means to build a close-knit family, we become the best employer to work with. We provide many benefits to employees including H1B and Green Card sponsorships for foreign nationals.</p><br/>
					<p>We have constant requirements in various technologies at various experience levels. <a href='#'><strong>Send us your resumes</strong></a> to contact one of our Human Resources Managers</p>
				
			</div>
		</Col>
		</Row>
        </div>
    </div>
    </div>
    <Footer /></>

  )
}
export default Career