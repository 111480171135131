import React from 'react';
import './services.css';
import { Menu, Col, Divider, Row } from 'antd';
import { Servicescircle } from '../../components';


const Services = () => {
  return (
    <>
    <div className='services'>
      <div className='container'>
        <div className='services_container'>
            <Row>
            <Col xs={{ span: 12 }} lg={{ span: 24 }}>
                  <h1>Our Services</h1>
                  
            </Col>
            </Row>
            <Row>
            <Col xs={{ span: 12 }} lg={{ span: 24 }}>
                  <svg height="40" width="100">
                    <g fill="none">
                      <path stroke-width="2" stroke="#353535" d="M5 20 l215 0" />
                    </g>
                  </svg>
            </Col>
            </Row>
            <Row>
            <Col xs={{ span: 12 }} lg={{ span: 24 }}>

                <Servicescircle />
            </Col>
            </Row>
            </div>
        </div>
    </div>
    </>
  )
}

export default Services