import './appdevelopment.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const appdevelopment = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>Our Services</h1>
    </div>
    <div className='app_development_page'>
        <Row className='align_margin'>
            <Col xs={{ span: 13 }} lg={{ span: 13 }}>
            <div className='bg_box1'></div>
            
                
            </Col>
            <Col className='services_div_right' xs={{ span: 11 }} lg={{ span: 11 }}>
                <h1>App Development</h1>
                <div className='line'></div>
                <p>Transform your concepts to reality, accelerate your product's time-to-market, and overcome operational obstacles within your business processes by leveraging the expertise of our highly skilled engineering team to build enterprise grade systems that will scale as your <strong>business grows.</strong></p>                
            </Col>
        </Row>
        <div className='dataintegration_box_container'>
        

        <Row className='dataintegration_s2_container align_margin'>
            
            <Col className='dataintegration_div_right' xs={{ span: 24,offset:0 }} lg={{ span: 24, offset:0 }}>
                <div className='dataintegration_s2_box_container'>
                    <Row>
                    <Col className='dataintegration_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M2.6,122.1c1-3.1,2-6.2,3.1-9.3c2.4-6.9,4.9-13.8,8.3-20.2c3.4-6.3,10.8-9.3,17.6-7.6
	c6.9,1.7,11.9,7.9,11.9,15.1c0,6-2.5,10.7-7.9,13.7c-5.2,2.9-10.7,5.1-16.3,7c-4.2,1.5-8.4,2.8-12.6,4.2c-0.3,0.1-0.6,0.3-0.8,0.4
	c-0.5,0-1,0-1.4,0c-0.6-0.6-1.3-1.3-1.9-1.9C2.6,123,2.6,122.5,2.6,122.1z M2.6,125.4c0.6,0,1.3,0,1.9,0c-0.6-0.6-1.3-1.3-1.9-1.9
	C2.6,124.1,2.6,124.8,2.6,125.4z M67.8,97.7c0.9,6.2,1.8,12.5,2.7,18.7c0.2,1.1,0.7,1.9,1.7,2.3c1.1,0.4,2,0.1,2.8-0.6
	c0.2-0.2,0.5-0.4,0.7-0.7C86.9,105.6,93,91.6,93.8,75.2c0-0.8,0-1.5,0.7-2.2c9.6-8.8,17.1-19.2,22.5-31c5.3-11.5,8.4-23.5,8.4-36.2
	c0-2.3-0.9-3.2-3.3-3.2c-6,0-11.9,0.7-17.7,2.1C85.1,9.3,68.5,18.8,54.9,33.4c-0.4,0.4-1.1,0.7-1.6,0.8c-4,0.5-8,0.7-11.9,1.6
	c-12.1,2.6-22.5,8.4-31.4,17c-0.9,0.8-1.3,1.8-0.9,2.9c0.3,1.1,1.2,1.6,2.3,1.8c4.7,0.7,9.3,1.3,14,2c1.7,0.2,3.5,0.5,5.4,0.8
	c-0.8,0.9-1.4,1.6-2,2.2c-1.5,1.7-1.4,2.9,0.1,4.5c2.8,2.8,5.6,5.6,8.4,8.4C45.3,83.2,53.2,91.1,61,99c1.6,1.6,2.8,1.6,4.5,0.1
	c0.7-0.6,1.4-1.2,2.2-1.9C67.8,97.5,67.8,97.6,67.8,97.7z M90.2,50.5c-7,0-12.8-5.9-12.7-12.9c0.1-7,5.8-12.7,12.8-12.7
	c7,0,12.8,5.8,12.8,12.8C103.1,44.8,97.3,50.6,90.2,50.5z"/>
</svg>
                        <h3>Speed-to-Market</h3>
                        <p>Rollout your products iteratively with incremental futures that matters for your customers.</p>
                    </Col>
                    <Col className='dataintegration_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
					<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M4.8,126.1c-2.1-1-2.7-2.6-2.7-4.9c0.1-8.1,0.1-16.3,0-24.4c0-2,0.7-3.3,2.4-4.2c10.5-6,20.9-12.1,31.4-18.1
	c6.6-3.8,13.7-5,21.3-3.7c8.9,1.6,17.9,3.2,26.8,4.8c5.2,0.9,8.6,5.5,7.8,10.4c-0.9,5.3-5.6,8.5-11,7.6C73,92.1,65,90.7,57.1,89.3
	c-2.2-0.4-4.2,0.8-4.5,2.8c-0.4,2.2,0.9,4,3.3,4.4c8,1.4,16.1,2.8,24.1,4.3c9.8,1.8,19.1-5.8,19.2-15.8c0-0.9,0.3-1.4,1.1-1.8
	c4-2.3,8-4.7,12.1-6.9c3.1-1.7,6.2-1.6,9.2,0.2c3,1.8,4.5,4.6,4.3,8.1c-0.2,3.4-1.9,5.9-4.8,7.6c-8.3,4.8-16.7,9.6-25,14.4
	c-2.7,1.5-5.3,3.1-8,4.6c-4.4,2.5-9,3.6-14,3.6c-11.1,0-22.2-0.1-33.2-0.2c-1.4,0-2.5,0.4-3.5,1.4c-2.7,2.6-5.5,5.2-8.2,7.7
	c-0.9,0.8-1.9,1.5-2.9,2.3C19,126.1,11.9,126.1,4.8,126.1z M91.2,25.1c-0.3-0.3-0.6-0.6-0.9-0.9c-2.8-2.8-5.6-5.6-8.4-8.4
	c-2.1-2.1-3.3-2.3-5.9-0.8c-10.6,5.9-21.1,11.7-31.7,17.6c-3.7,2-7.4,4.1-11,6.1c-1.7,1-2.4,2.7-1.8,4.4c0.6,1.7,2.3,2.7,4.1,2.3
	c0.7-0.1,1.4-0.5,2-0.9c12-6.7,24.1-13.4,36.1-20.1c1.4-0.8,2.8-1.6,4.3-2.4c3.2,3.2,6.4,6.4,9.6,9.6c2.1,2.1,3.6,2.2,6,0.5
	c7.5-5.2,14.9-10.4,22.4-15.5c0.3-0.2,0.7-0.4,1.2-0.8c-0.2,1.2-0.3,2.1-0.5,3c-0.2,1-0.4,1.9-0.5,2.9c-0.2,2.6,2,4.6,4.5,3.9
	c1.7-0.5,2.5-1.8,2.8-3.4c0.7-4.4,1.5-8.8,2.3-13.1c0.5-2.9-0.6-4.5-3.5-5c-4.4-0.8-8.8-1.5-13.2-2.3c-2.3-0.4-4.2,0.7-4.6,2.8
	c-0.4,2.1,1,4,3.3,4.4c1.6,0.3,3.2,0.6,5.2,0.9C105.5,15.2,98.3,20.2,91.2,25.1z M113.8,58.5c0,2.4,1.5,4,3.6,4c2.1,0,3.7-1.6,3.7-4
	c0-7.9,0-15.8,0-23.6c0-2.3-1.5-4-3.7-4c-2.1,0-3.6,1.7-3.6,4c0,3.9,0,7.8,0,11.6C113.8,50.6,113.8,54.5,113.8,58.5z M77.4,37.5
	c0-2.5-1.5-4.1-3.6-4.1c-2.2,0-3.7,1.6-3.7,4.1c0,7,0,14,0,21c0,2.4,1.5,4.1,3.7,4.1c2.1,0,3.6-1.7,3.6-4c0-3.5,0-7,0-10.5
	C77.4,44.5,77.4,41,77.4,37.5z M99.2,58.6c0,2.3,1.5,3.9,3.6,3.9c2.1,0,3.7-1.6,3.7-3.9c0-6.3,0-12.5,0-18.8c0-2.3-1.5-4-3.7-4
	c-2.1,0-3.6,1.7-3.6,4c0,3.1,0,6.2,0,9.3C99.2,52.3,99.2,55.5,99.2,58.6z M62.8,44.5c0-2.2-1.6-3.9-3.7-3.8c-2.1,0-3.6,1.6-3.6,3.9
	c0,4.7,0,9.4,0,14c0,2.3,1.5,3.9,3.6,3.9c2.1,0,3.7-1.6,3.7-3.9c0-2.3,0-4.6,0-6.9C62.8,49.3,62.8,46.9,62.8,44.5z M91.9,44.7
	c0-2.4-1.5-4-3.6-4c-2.1,0-3.7,1.6-3.7,4c0,4.6,0,9.3,0,13.9c0,2.3,1.5,4,3.7,4c2.1,0,3.6-1.6,3.6-3.9c0-2.3,0-4.7,0-7
	C91.9,49.3,91.9,47,91.9,44.7z M41,58.9c0.1,2.1,1.6,3.6,3.6,3.6c2,0,3.6-1.5,3.7-3.6c0.1-2.5,0.1-4.9,0-7.4c0-2-1.7-3.6-3.7-3.6
	c-2,0-3.5,1.6-3.6,3.6c0,1.2,0,2.4,0,3.6C41,56.4,40.9,57.7,41,58.9z"/>
</svg>
                        <h3>Sustainable development</h3>
                        <p>Start small and scale to an enterprise seamlessly with API and micro services driven applications.</p>
                    </Col>
                    <Col className='dataintegration_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M90.6,103.5c1.9,3.2,3.6,6.1,5.3,9.1c0.3,0.5,0.7,1.1,0.9,1.6c0.8,1.9-0.4,3.9-2.4,4.1c-0.4,0-0.8,0-1.2,0
	c-19.5,0-39,0-58.4,0c-0.2,0-0.3,0-0.5,0c-2.9,0-4.2-2.3-2.7-4.9c1.7-2.9,3.4-5.9,5.1-8.8c0.2-0.3,0.4-0.7,0.7-1.3c-0.6,0-1,0-1.5,0
	c-8.7,0-17.4,0-26.1,0c-5.7,0-9.2-3.5-9.2-9.2c0-25.2,0-50.3,0-75.5c0-5.7,3.5-9.2,9.2-9.2c36.1,0,72.3,0,108.4,0
	c5.8,0,9.2,3.4,9.2,9.2c0,25.2,0,50.3,0,75.5c0,5.7-3.5,9.2-9.2,9.2c-8.7,0-17.3,0-26,0C91.8,103.5,91.3,103.5,90.6,103.5z
	 M35.1,88.8c0-0.6,0-1,0-1.5c0-7,0-14.1,0-21.1c0-1.8,0.7-2.4,2.5-2.5c2.2,0,4.4,0,6.6,0c2.1,0,2.7,0.6,2.7,2.6c0,7,0,14,0,21
	c0,0.4,0,0.9,0,1.4c0.7,0,1.2,0,1.9,0c0-0.6,0-1.1,0-1.6c0-9.2,0-18.4,0-27.6c0-2.5,0.5-3,3.1-3c1.9,0,3.9,0,5.8,0
	c2.3,0,2.8,0.6,2.8,2.9c0,9.3,0,18.5,0,27.8c0,0.5,0,1,0,1.5c21.1,0,42,0,62.9,0c0-0.3,0-0.6,0-0.8c0-23.2,0-46.4,0-69.6
	c0-2.9-1.9-4.6-4.9-4.6c-36.3,0-72.6,0-108.9,0c-3.3,0-5,1.7-5,5c0,23,0,45.9,0,68.9c0,0.4,0,0.9,0,1.3c1.2,0,2.2,0,3.3,0
	c0-0.5,0-1,0-1.4c0-2.3,0-4.6,0-6.9c0-1.7,0.7-2.4,2.4-2.4c1.7,0,3.5,0,5.2,0c4,0,4.1,0.2,4.1,4.1c0,2.2,0,4.4,0,6.7
	c0.7,0,1.2,0,1.8,0c0-0.5,0-1,0-1.4c0-4.7,0-9.3,0-14c0-1.7,0.7-2.4,2.4-2.4c2.2,0,4.5,0,6.7,0c2,0,2.6,0.6,2.6,2.7
	c0,4.6,0,9.2,0,13.8c0,0.5,0,1,0.1,1.4C34,88.8,34.5,88.8,35.1,88.8z M123.4,92.9c-39.7,0-79.3,0-118.8,0c0,1-0.1,1.9,0,2.8
	c0.3,2.4,2.2,3.8,5.1,3.8c36.2,0,72.4,0,108.6,0c0.3,0,0.7,0,1,0c2.3-0.2,4-1.9,4.2-4.2C123.5,94.5,123.4,93.7,123.4,92.9z
	 M35.7,114.3c18.9,0,37.7,0,56.7,0c-1.9-3.3-3.8-6.4-5.6-9.6c-0.5-0.9-1.1-1.3-2.2-1.3c-12.8,0.1-25.7,0-38.5,0c-1.4,0-3-0.4-4,0.2
	c-1,0.6-1.4,2.1-2.1,3.3C38.5,109.4,37.1,111.8,35.7,114.3z M52.8,60.6c0,9.4,0,18.8,0,28.1c1.2,0,2.4,0,3.7,0c0-9.4,0-18.7,0-28.1
	C55.2,60.6,54.1,60.6,52.8,60.6z M42.8,88.7c0-7,0-14,0-20.9c-1.3,0-2.4,0-3.6,0c0,7,0,13.9,0,20.9C40.4,88.7,41.5,88.7,42.8,88.7z
	 M29.2,74.9c-1.3,0-2.4,0-3.6,0c0,4.7,0,9.3,0,13.8c1.3,0,2.4,0,3.6,0C29.2,84.1,29.2,79.5,29.2,74.9z M11.9,88.7c1.3,0,2.5,0,3.7,0
	c0-2.3,0-4.5,0-6.7c-1.3,0-2.5,0-3.7,0C11.9,84.3,11.9,86.5,11.9,88.7z M112.7,47.6c-1.5,0-3,0-4.5,0c-1.7,0-2.4-0.7-2.4-2.4
	c-0.3-7.5-5.4-13.6-12.7-15.1c-9.5-2-18.8,5.3-19.1,14.9c-0.1,2.1-0.6,2.7-2.7,2.7c-2.9,0-5.8,0-8.7,0c-1.8,0-2.5-0.7-2.5-2.5
	c0.1-13.6,10.3-25.9,23.7-28.6c17.1-3.4,33.1,7.9,35.6,25.1c0.2,1.2,0.2,2.5,0.3,3.7c0,1.5-0.8,2.2-2.2,2.2
	C115.9,47.7,114.3,47.6,112.7,47.6z M104.6,24.2c-1.2,2.1-2.1,3.7-2.8,4.9c1.6,1.7,3.1,3.1,4.7,4.8c1.3-0.8,3-1.7,4.9-2.9
	C109.1,28.7,106.9,26.5,104.6,24.2z M78,29.1c-0.7-1.3-1.7-2.9-2.9-4.9c-2.3,2.3-4.5,4.5-6.8,6.8c2,1.2,3.7,2.1,4.9,2.8
	C74.9,32.1,76.4,30.7,78,29.1z M91.9,25.7c2.2,0.6,4.1,1.1,6.2,1.7c0.9-1.6,1.9-3.2,2.9-4.9c-3-1.4-5.9-2.2-9.1-2.4
	C91.9,22.1,91.9,23.9,91.9,25.7z M78.7,22.5c1,1.8,2,3.4,2.9,4.9c2.2-0.6,4.2-1.1,6.2-1.7c0-1.8,0-3.7,0-5.7
	C84.6,20.3,81.7,21,78.7,22.5z M71.5,37.4c-1.6-0.9-3.2-1.8-4.9-2.8c-1.4,2.9-2.2,5.8-2.4,9.1c1.9,0,3.6,0,5.3,0
	c0.2,0,0.6-0.4,0.6-0.7C70.6,41.2,71,39.4,71.5,37.4z M115.5,43.6c-0.2-3.2-1-6.1-2.4-9c-1.7,1-3.4,2-4.9,2.9
	c0.6,2.2,1.1,4.1,1.7,6.2C111.7,43.6,113.6,43.6,115.5,43.6z M25.6,51.4c-9.8,0-17.8-8-17.7-17.8c0.1-9.7,8-17.6,17.8-17.7
	c9.8,0,17.8,8,17.8,17.9C43.3,43.5,35.3,51.4,25.6,51.4z M23.7,20.2c-5,0.4-9.9,4.7-11.3,9.9C11,34.8,12.7,41,16,43.2
	c1-1.3,2-2.7,3-4c-3.7-5.6-2.1-11,4-13.7c0.3-0.1,0.6-0.5,0.6-0.8C23.7,23.2,23.7,21.7,23.7,20.2z M38.8,37.2
	c2.3-7.1-2.7-16-11.2-17c0,1.4,0,2.8,0,4.2c0,0.7,0.2,1,0.9,1.2c3,1.1,4.9,3.2,5.5,6.3c0.2,1,0.2,2.1,0.2,3.3
	C35.7,35.8,37.2,36.5,38.8,37.2z M37.2,40.8c-1.4-0.6-2.7-1.2-4.1-1.7c-0.3-0.1-0.8,0-1,0.2c-2.6,2.7-5.7,3.5-9.3,2.5
	c-0.3-0.1-0.7-0.1-0.9,0.1c-1,1.2-1.9,2.5-2.9,3.8C25.5,49.4,34,46.7,37.2,40.8z M30.2,33.6c0-2.5-2.1-4.6-4.6-4.6
	c-2.5,0-4.7,2.1-4.7,4.7c0,2.5,2.1,4.6,4.6,4.6C28.2,38.3,30.2,36.2,30.2,33.6z M43.9,48.7c-5.7,3.3-11.4,6.6-17.1,9.9
	c-4.6,2.6-9.2,5.3-13.8,7.9c-1.1,0.7-1.5,1.8-0.9,2.8c0.6,1,1.7,1.2,2.8,0.6c0.4-0.2,0.7-0.4,1.1-0.6C26.1,63.6,36,57.8,46,52.1
	c0.3-0.2,0.7-0.3,1.2-0.6C47,52,47,52.2,46.9,52.4c-0.5,1.5-0.1,2.5,1.1,3c1.2,0.4,2.1-0.2,2.7-1.7c0.7-1.9,1.4-3.9,2-5.9
	c0.5-1.5-0.1-2.5-1.5-2.8c-2.2-0.5-4.5-0.9-6.7-1.3c-1.1-0.2-2,0.5-2.3,1.5c-0.3,1.1,0.3,2.1,1.4,2.4c0.5,0.1,0.9,0.2,1.6,0.4
	C44.6,48.3,44.3,48.5,43.9,48.7z M71.5,78.6c-0.4,0-0.8,0-1.2,0c-1,0.1-1.6,0.7-1.7,1.7c-0.1,1,0.3,1.7,1.3,2
	c0.5,0.2,1.1,0.2,1.7,0.2c12.2,0,24.4,0,36.5,0c0.6,0,1.2,0,1.7-0.2c0.9-0.3,1.4-1.1,1.3-2c-0.1-1-0.7-1.6-1.7-1.7
	c-0.4-0.1-0.8,0-1.2,0c-6.1,0-12.2,0-18.3,0C83.8,78.6,77.7,78.6,71.5,78.6z M108.1,63.8c2.1,0,3.1-0.6,3.1-2c0-1.3-1-2-3-2
	c-12.2,0-24.3,0-36.5,0c-0.5,0-1.1,0-1.6,0.1c-1,0.3-1.4,1-1.4,2c0.1,1,0.6,1.6,1.6,1.8c0.4,0.1,0.9,0.1,1.4,0.1
	c6.1,0,12.2,0,18.3,0C95.9,63.8,102,63.8,108.1,63.8z M108.2,73.2c0.4,0,0.7,0,1.1,0c1.1-0.1,1.7-0.8,1.8-1.9c0.1-1-0.7-1.9-1.8-2.1
	c-0.4-0.1-0.7,0-1.1,0c-12.3,0-24.5,0-36.8,0c-0.4,0-0.8,0-1.1,0c-1.1,0.2-1.8,1-1.7,2.1c0.1,1.1,0.7,1.7,1.8,1.9c0.4,0,0.7,0,1.1,0
	c6.1,0,12.2,0,18.3,0C95.9,73.2,102.1,73.2,108.2,73.2z M94.2,40.9c1.9-1.1,3.8-2.2,5.7-3.3c1.4-0.8,2.5-0.6,3.1,0.5
	c0.6,1.1,0.2,2.1-1.2,2.9c-1.6,1-3.3,1.9-4.9,2.9c-0.3,0.2-0.6,0.8-0.6,1.2c0.1,3.1-1.7,5.8-4.6,6.6c-2.9,0.9-6-0.4-7.5-3
	c-1.4-2.6-0.9-5.8,1.3-7.8c2.2-2,5.4-2.2,8-0.5C93.8,40.6,94,40.7,94.2,40.9z M92.3,45.7c0-1.3-1.1-2.4-2.4-2.4
	c-1.3,0-2.4,1-2.4,2.3c-0.1,1.3,1.1,2.5,2.4,2.5C91.1,48.1,92.3,47,92.3,45.7z"/>
</svg>
                        <h3>Reliability, Availability and Security</h3>
                        <p>Well defined SRE process to ensure your application is deployed correctly in with insights to its performance.</p>
                    </Col>
					<Col className='dataintegration_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M58.3,125.4c0-1.7,0-3.4,0-5.2c4.5,0,8.9,0,13.4,0c0,1.7,0,3.5,0,5.2C67.3,125.4,62.8,125.4,58.3,125.4z
	 M78.4,100c0-3.3,0.1-6.6,0-10c0-1.5,0.4-2.3,1.8-3c13.2-6.6,20.6-21.8,17.6-36.1C94.1,33,77.5,21.6,59.4,24.4
	c-14.9,2.4-27,15.7-27.6,30.7c-0.6,14.5,5.8,25.3,18.6,32.1c0.9,0.5,1.3,1,1.3,2.1c0,3.6,0,7.1,0,10.7c0,0.4,0,0.8,0,1.3
	c9,0,17.7,0,26.7,0C78.4,100.8,78.4,100.4,78.4,100z M69,65.8c0-1.3,0.4-1.7,1.7-1.7c3.3,0,6.6,0,9.9,0c1.2,0,1.7,0.4,1.7,1.7
	c0,3.4,0,6.7,0,10.1c0,1.1-0.5,1.6-1.6,1.6c-3.4,0-6.8,0-10.2,0c-1,0-1.6-0.5-1.5-1.6c0-1.7,0-3.4,0-5.1C69,69,69,67.4,69,65.8z
	 M47.7,41.8c0-1.4,0.4-1.8,1.8-1.8c3.3,0,6.6,0,9.8,0c1.3,0,1.7,0.4,1.7,1.7c0,3.3,0,6.6,0,9.8c0,1.4-0.4,1.7-1.7,1.7
	c-3.3,0-6.6,0-10,0c-1.3,0-1.6-0.4-1.7-1.7c0-1.7,0-3.4,0-5C47.7,45,47.6,43.4,47.7,41.8z M81,43.9c-1.7,0-3.4,0-5.2,0
	c0-1.7,0-3.4,0-5.2c1.7,0,3.4,0,5.2,0C81,40.5,81,42.1,81,43.9z M75.7,46.8c1.7,0,3.4,0,5.2,0c0,1.7,0,3.4,0,5.1c-1.7,0-3.4,0-5.2,0
	C75.7,50.2,75.7,48.5,75.7,46.8z M75.8,59.9c0-1.7,0-3.4,0-5.2c1.7,0,3.4,0,5.2,0c0,1.7,0,3.4,0,5.2C79.2,59.9,77.5,59.9,75.8,59.9z
	 M67.8,43.9c0-1.7,0-3.4,0-5.2c1.7,0,3.4,0,5.1,0c0,1.7,0,3.4,0,5.2C71.2,43.9,69.5,43.9,67.8,43.9z M67.7,52c0-1.7,0-3.4,0-5.2
	c1.7,0,3.4,0,5.2,0c0,1.7,0,3.4,0,5.2C71.2,52,69.5,52,67.7,52z M88.9,46.7c0,1.7,0,3.4,0,5.2c-1.7,0-3.4,0-5.1,0c0-1.7,0-3.4,0-5.2
	C85.5,46.7,87.2,46.7,88.9,46.7z M67.8,59.9c0-1.7,0-3.4,0-5.2c1.7,0,3.4,0,5.1,0c0,1.7,0,3.4,0,5.2C71.2,59.9,69.5,59.9,67.8,59.9z
	 M83.8,54.8c1.7,0,3.4,0,5.2,0c0,1.7,0,3.4,0,5.2c-1.7,0-3.4,0-5.2,0C83.8,58.2,83.8,56.5,83.8,54.8z M46.2,57.4c0,1.8,0,3.4,0,5.2
	c-1.7,0-3.4,0-5.2,0c0-1.7,0-3.4,0-5.2C42.9,57.4,44.5,57.4,46.2,57.4z M54.2,57.4c0,1.8,0,3.4,0,5.2c-1.7,0-3.4,0-5.2,0
	c0-1.8,0-3.4,0-5.2C50.8,57.4,52.5,57.4,54.2,57.4z M62.3,57.4c0,1.7,0,3.4,0,5.2c-1.7,0-3.4,0-5.2,0c0-1.7,0-3.4,0-5.2
	C58.9,57.4,60.5,57.4,62.3,57.4z M41,65.4c1.7,0,3.4,0,5.2,0c0,1.7,0,3.4,0,5.2c-1.7,0-3.4,0-5.2,0C41,68.8,41,67.2,41,65.4z
	 M49,65.4c1.7,0,3.4,0,5.2,0c0,1.7,0,3.4,0,5.2c-1.7,0-3.4,0-5.2,0C49,68.8,49,67.2,49,65.4z M62.3,65.4c0,1.8,0,3.4,0,5.2
	c-1.8,0-3.4,0-5.2,0c0-1.8,0-3.4,0-5.2C58.8,65.4,60.5,65.4,62.3,65.4z M49,78.6c0-1.7,0-3.4,0-5.2c1.7,0,3.4,0,5.2,0
	c0,1.7,0,3.4,0,5.2C52.5,78.6,50.8,78.6,49,78.6z M57.1,78.6c0-1.7,0-3.4,0-5.2c1.7,0,3.4,0,5.2,0c0,1.7,0,3.4,0,5.2
	C60.5,78.6,58.9,78.6,57.1,78.6z M19.5,89.3c0.9,1.2,1.8,2.3,2.7,3.5c0.5,0.7,1.2,0.8,1.9,0.3c0.7-0.5,0.7-1.2,0.2-1.9
	c-0.2-0.3-0.5-0.6-0.8-0.9C13.2,77,9.6,62.1,13.2,45.7c5-22.8,25.6-39.8,49-40.4C73,5,83,7.8,92.2,13.6c22,14.1,30.1,43.3,18.5,66.6
	c-1.9,3.8-4.2,7.4-6.9,10.6c-0.8,0.9-0.8,1.8,0,2.3c0.9,0.5,1.6,0,2.1-0.7c13.9-17.9,16.8-37.4,7.6-58.2
	C102.4,9.4,73.3-3.3,47.4,5.2c-22.8,7.4-38,28.1-38.2,52.1C9.3,69,12.7,79.7,19.5,89.3z M51.7,112.1c0,1.8,0,3.5,0,5.3
	c0.5,0,0.9,0,1.3,0c5.1,0,10.3,0,15.4,0c3,0,5.9,0,8.9,0c0.5,0,1,0,1-0.8c0-1.5,0-2.9,0-4.5C69.4,112.1,60.6,112.1,51.7,112.1z
	 M78.3,109.3c0-1.8,0-3.5,0-5.1c-8.9,0-17.7,0-26.6,0c0,1.8,0,3.4,0,5.1C60.6,109.3,69.5,109.3,78.3,109.3z M79.6,66.8
	c-2.7,0-5.2,0-7.8,0c0,2.7,0,5.2,0,7.9c2.6,0,5.2,0,7.8,0C79.6,72,79.6,69.3,79.6,66.8z M50.4,42.7c0,2.7,0,5.2,0,7.8
	c2.6,0,5.2,0,7.8,0c0-2.6,0-5.2,0-7.8C55.6,42.7,53,42.7,50.4,42.7z"/>
</svg>
                        <h3>Insights driven</h3>
                        <p>Collect data to drive business intelligence. </p>
                    </Col>
					
                    
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
        <div className='app_development_page margin_top'>
        <Row className='app_dev_s2_container align_margin'>
            <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                <h2>
                Make us part of your team
                </h2>
                <div className='line'></div>

                <p>While you focus on what you do best; business growth, let us focus on solving your tech challenges.  </p>
                
            </Col>
            <Col className='app_dev_div_right' xs={{ span: 15,offset:1 }} lg={{ span: 15, offset:1 }}>
                <h2>Managed IT Services</h2>
                <div className='app_dev_s2_box_container'>
                    <Row>
                    <Col className='app_dev_s2_box' xs={{ span: 11,offset:-1}} lg={{ span: 11,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M80.7,113.1c0,2.6,0,4.9,0,7.3c0,1.9-0.7,2.5-2.5,2.5c-16,0-32,0-48,0c-1.9,0-2.5-0.7-2.5-2.5
	c-0.2-11.8-3.4-22.5-10.2-32.2c-6.8-9.8-9.8-20.7-9.4-32.5c0.8-22.6,17-42.9,38.9-48.7c25.6-6.8,51.2,5.1,62.4,29.1
	c2.9,6.2,4.2,12.7,4.8,19.4c0.3,3.3,0.9,6.5,1.6,9.8c1.3,6.5,2.8,13,4.2,19.5c0.4,1.7-0.4,2.7-2.2,2.7c-3.8,0-7.5,0-11.5,0
	c0,0.5,0,0.9,0,1.4c0,7.2,0,14.3,0,21.5c0,2.1-0.6,2.7-2.7,2.7c-7.1,0-14.2,0-21.4,0C81.7,113.1,81.3,113.1,80.7,113.1z M76.7,119
	c0-2,0-3.9,0-5.9c-2,0-3.9,0-5.8,0c0-1.3,0-2.6,0-3.9c10.5,0,20.9,0,31.4,0c0-0.6,0-1,0-1.5c0-7.2,0-14.4,0-21.6
	c0-1.8,0.7-2.4,2.4-2.5c2.9,0,5.8,0,8.7,0c0.7,0,1.4,0,2.1,0c-1.2-5.7-2.4-11.3-3.6-16.8c-0.7-3.1-1.3-6.2-1.8-9.4
	c-0.4-2.6-0.4-5.2-0.8-7.8c-3.5-21-20.7-37.6-41.9-40.2C52.4,7.5,39,11.6,27.8,22C15.5,33.4,10.5,47.7,12.4,64.4
	c1,8.5,4.3,16,9.2,23c4.5,6.4,7.4,13.6,8.8,21.3c0.6,3.4,0.9,6.8,1.4,10.3C46.7,119,61.7,119,76.7,119z M76.8,50.3
	c0,0.2,0,0.4,0,0.6c0.2,2.2-0.7,3.7-3,4.4c-0.8,0.2-1.6,0.8-2.1,1.4c-0.7,0.8-0.5,1.7-0.1,2.7c1.5,3.5,1.5,3.5-1.3,6.2
	c-0.1,0.1-0.3,0.2-0.4,0.3c-1.5,1.8-3.2,2.2-5.3,0.9c-0.8-0.5-1.8-1.1-2.8-0.3C61.4,67,61,67.5,60.5,68c0,0,0,0.1-0.1,0.1
	c-1.6,3.8-1.6,3.8-5.8,3.7c-0.7,0-1.4,0-2.1-0.2c-0.7-0.3-1.3-0.7-1.8-1.3c-0.5-0.6-0.6-1.4-0.9-2.1c-0.7-1.8-2-2.3-3.8-1.5
	c-3.7,1.5-3.7,1.5-6.4-1.3c-0.1-0.1-0.2-0.3-0.3-0.4c-1.7-1.4-2.1-3.1-1-5.1c0.4-0.7,0.6-1.7,0.5-2.5c-0.1-1.1-0.9-1.6-2-2.1
	c-3.4-1.3-3.4-1.4-3.5-5.1c0-0.2,0-0.3,0-0.5c-0.2-2.3,0.6-4,3-4.6c0.8-0.2,1.7-0.4,2.1-1.4c0.4-1,0.4-1.8,0-2.8
	c-1.5-3.4-1.4-3.4,1.2-6c0.1-0.1,0.2-0.2,0.3-0.3c1.6-1.9,3.4-2.5,5.7-1.1c0.9,0.6,2,0.8,3,0.1c0.3-0.2,0.7-0.6,0.9-0.9
	c0.2-0.2,0.2-0.5,0.3-0.8c1.4-3.4,1.5-3.5,5.2-3.5c0.3,0,0.7,0,1,0c1.9-0.1,3.3,0.7,3.8,2.7c0.3,1.1,0.6,2.5,2.1,2.7
	c0.5,0.1,1.1,0,1.6,0c0.2,0,0.5-0.1,0.7-0.3c2.3-1.6,4.2-0.9,5.9,1.1c0.1,0.1,0.2,0.2,0.3,0.3c2.5,2.5,2.5,2.6,1.1,5.9
	c-0.9,2.2-0.5,3.3,1.7,4.2C76.7,46.4,76.8,46.6,76.8,50.3z M66.7,63.4c0.7,0,1.8-1.5,1.5-2.1c-0.3-0.8-0.6-1.5-0.9-2.3
	c-0.5-1.3,1-5.6,2.2-6.3c0.2-0.1,0.4-0.2,0.7-0.3c0.5-0.2,1-0.4,1.5-0.6c0.8-0.3,1.4-0.6,1.3-1.7c0-1-0.6-1.3-1.3-1.6
	c-0.5-0.2-1-0.4-1.5-0.6c-1-0.3-1.4-1.1-1.9-2c-1.3-2.4-1.4-4.6,0-6.9c0.2-0.3-0.3-1-0.6-1.3c-0.3-0.3-1-0.6-1.3-0.5
	c-2.3,1.4-4.5,1.4-6.8,0c0,0-0.1,0-0.1,0c-1.1-0.2-1.7-0.9-2-1.9c-0.2-0.5-0.5-1-0.6-1.6c-0.2-0.8-0.6-1.2-1.6-1.2
	c-0.9,0-1.4,0.3-1.6,1.1c-0.5,2.1-1.7,3.6-3.9,4c-0.1,0-0.2,0-0.2,0.1c-1.3,0.9-2.7,0.8-4,0.1c-0.3-0.2-0.7-0.2-1-0.4
	c-0.8-0.5-1.2-0.1-1.8,0.4c-0.6,0.6-0.8,1-0.4,1.8c1.1,1.9,1.4,3.8,0.1,5.7c-0.2,0.3-0.2,0.7-0.3,0.9c-0.5,0.6-1,1.3-1.7,1.8
	c-0.5,0.4-1.1,0.6-1.7,0.7c-0.9,0.2-1.2,0.7-1.2,1.6c0,0.9,0.4,1.3,1.2,1.5c0.7,0.2,1.4,0.5,2,0.9c0.5,0.3,1,0.7,1.2,1.2
	c1.2,2.4,2.2,4.9,0.3,7.4c-0.1,0.2-0.2,0.6,0,0.9c0.6,1.1,1.4,1.4,2.6,0.8c0.6-0.3,1.3-0.6,1.9-0.8c0.5-0.1,1.1-0.2,1.6,0
	c2.6,0.8,4.9,1.9,5.5,5c0.1,0.3,0.9,0.6,1.3,0.6c0.5,0,1.3-0.3,1.3-0.6c0.6-2.6,2.2-4.1,4.8-4.7c0,0,0.1,0,0.1,0
	c0.9-0.6,1.8-0.6,2.8-0.1C65,62.8,65.9,63.1,66.7,63.4z M74.6,23.7c2.9-2.9,2.9-3,0-5.9c-1.4-1.4-2.8-2.9-4.2-4.2
	c-1.1,1-2,1.8-3.2,2.9c0.9,0.7,1.8,1.4,2.8,2.2c-0.6,0-0.8,0.1-1,0.1c-4.8,0-9.7-0.2-14.5,0.1c-3.1,0.2-6.3,0.6-9.3,1.5
	c-15.1,4.9-24,20.2-21.1,35.8c0.5,2.9,1.5,5.7,2.9,8.4c1.2-0.6,2.3-1.2,3.4-1.8c-0.1-0.3-0.2-0.5-0.3-0.6c-2.3-4.8-3.1-9.9-2.5-15.2
	c1.4-13.2,13.1-24,26.4-24.2c5-0.1,10,0,15,0c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.8,0.6-1.7,1.3-2.5,2c1.1,1.1,2,2,3,3
	C71.8,26.6,73.2,25.1,74.6,23.7z M40.4,73.6c-0.7,1-1.3,2.1-2.1,3.3c2.6,1.2,5.1,2.3,7.6,3.5c0.5-1.4,0.8-2.6,1.2-3.8
	C44.8,75.6,42.6,74.6,40.4,73.6z M82.4,66.4c1.1-2.6,2.2-5.1,3.3-7.7c-1.4-0.4-2.6-0.7-3.8-1.1c-1,2.3-1.9,4.4-2.9,6.8
	C80,65,81.1,65.6,82.4,66.4z M61.6,77.2c0.3,1.2,0.6,2.4,0.9,3.8c2.7-1,5.2-2,7.8-3c-0.7-1.3-1.3-2.4-1.9-3.5
	C66.1,75.4,63.9,76.3,61.6,77.2z M50.6,77.6c-0.2,1.1-0.4,2.3-0.6,3.6c2.8,0.5,5.5,0.7,8.3,0.2c-0.1-1.3-0.3-2.5-0.4-3.9
	C55.4,77.6,53.1,77.6,50.6,77.6z M37.4,71.4c-1.7-1.9-3.3-3.6-4.9-5.4c-1,0.7-2,1.4-3.2,2.2c1.9,2.1,3.7,4.1,5.7,6.2
	C35.8,73.3,36.6,72.3,37.4,71.4z M73.9,75.7c2-2,4-3.9,6-5.8c-1.2-0.9-2.1-1.7-3.1-2.5c-1.7,1.8-3.3,3.6-5,5.5
	C72.3,73.5,73,74.5,73.9,75.7z M51.2,109.3c-1.3,0-2.5,0-3.8,0c0,1.3,0,2.5,0,3.8c1.3,0,2.5,0,3.8,0
	C51.2,111.7,51.2,110.5,51.2,109.3z M55.3,113c1.3,0,2.5,0,3.7,0c0-1.3,0-2.6,0-3.8c-1.3,0-2.5,0-3.7,0
	C55.3,110.5,55.3,111.8,55.3,113z M66.9,109.3c-1.3,0-2.5,0-3.8,0c0,1.3,0,2.6,0,3.8c1.3,0,2.5,0,3.8,0
	C66.9,111.7,66.9,110.5,66.9,109.3z M45.4,50.2c0-5.4,4.5-9.8,9.9-9.8c5.4,0,9.8,4.5,9.7,9.9c0,5.4-4.5,9.7-9.8,9.7
	C49.7,60,45.3,55.6,45.4,50.2z M61.1,50.2c0-3.2-2.7-5.8-5.9-5.9c-3.3,0-5.9,2.7-5.9,5.9c0,3.2,2.7,5.8,5.9,5.9
	C58.4,56.1,61.1,53.5,61.1,50.2z"/>
</svg>
                        <h3>Capability Maturing</h3>
                        <p>Our agile Project Management team will focus on capability delivery iteratively. </p>
                    </Col>
                    <Col className='app_dev_s2_box' xs={{ span: 11,offset:-1}} lg={{ span: 11,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M43.7,109.8c0,1.4,0,2.7,0,4.1c-1.4,0-2.7,0-3.9,0c-4.5-0.1-9,0.1-13.5-0.2C14.3,112.5,4,102.9,1.8,91.1
	C-0.9,75.7,8.4,61.3,23.6,58c2-0.5,4.1-0.6,6-0.6s3.9,0.2,6,0.5c0.7-8.9,4.6-16.3,12-21.6c5.3-3.8,11.5-5.6,17.9-5.1
	C80.1,32,91.3,43.5,92.2,58c0.8-0.1,1.7-0.2,2.5-0.3c15.5-1.6,28.8,8.3,31.5,23.7c2.5,14.7-8,29.6-22.8,32.2c-2,0.3-3.9,0.5-5.9,0.5
	c-4.4,0.1-8.9,0-13.4,0c0-1.4,0-2.7,0-4.1c0.5,0,0.9,0,1.3,0c4.2,0,8.2,0,12.4,0c14.2,0,25-11,24.7-25.2c-0.2-11.5-9.6-21.8-21-23
	c-3.6-0.5-6.9-0.1-10.4,0.8c-2.1,0.6-3.1-0.2-3-2.3c0.2-14.1-10.8-25.2-24.8-25c-13,0-23.7,11.1-23.5,24.4c0,2.8-0.8,3.5-3.6,2.7
	C22.9,58.4,9.1,66.5,6,80.1c-3.1,13.3,5.6,26.7,19,29.3c1.7,0.3,3.5,0.5,5.3,0.5C34.7,109.9,39.2,109.8,43.7,109.8z M77.4,122
	c0.8-1.3,1.6-2.7,2.4-4.1c0.8-1.6,0.5-3.1-0.9-4.3c-1-0.8-1.2-2.8-0.1-3.6c1.5-1.2,1.9-2.8,0.9-4.5c-0.7-1.3-1.5-2.5-2.3-3.8
	c-1-1.5-2.7-2-4.3-1.4c-1.3,0.5-3.1-0.6-3.4-1.9c-0.3-1.9-1.6-2.9-3.6-2.9c-1.4,0-2.9,0-4.3,0c-2,0-3.1,1-3.5,3
	c-0.2,1.3-2.2,2.3-3.4,1.9c-1.7-0.7-3.4-0.2-4.3,1.3c-0.8,1.3-1.6,2.7-2.4,4.1c-0.8,1.6-0.5,3.1,0.9,4.3c1.2,0.9,1,2.8,0,3.7
	c-1.4,1-1.7,2.7-0.9,4.2c0.7,1.4,1.6,2.8,2.4,4.1c1,1.5,2.5,1.9,4.3,1.3c1.3-0.5,3.1,0.6,3.4,1.9c0.3,1.9,1.6,2.9,3.6,2.9
	c1.4,0,2.9,0,4.3,0c2,0,3.1-1,3.5-3c0.1-0.3,0.2-0.9,0.5-1c1-0.6,2.1-1.6,3.4-0.6c0.2,0.1,0.6,0.1,0.8,0.1
	C75.8,123.5,76.7,123,77.4,122z M53.8,104.5c1.7,1,3.4,0.6,4.9-0.6c0.3-0.2,0.7-0.5,1.2-0.6c1.6-0.6,2.5-1.7,2.7-3.5
	c1,0,2.1,0,3.1,0c1.2,3.6,5.2,5.8,8.8,4.7c0.5,0.8,0.9,1.6,1.5,2.5c-1.7,1-2.2,2.7-1.9,4.5c0.1,0.3,0,0.8,0,1.2
	c-0.3,1.7,0.3,3,1.9,3.9c-0.6,0.9-1,1.7-1.6,2.5c-1.7-1-3.4-0.6-4.9,0.6c-0.3,0.2-0.7,0.5-1.2,0.6c-1.6,0.6-2.5,1.6-2.7,3.5
	c-1,0-2.1,0-3.1,0c-1.4-3.7-5.3-5.9-8.8-4.7c-0.5-0.8-1-1.6-1.5-2.4c2.7-2.7,2.7-7.3,0-9.8C52.8,106.1,53.2,105.3,53.8,104.5z
	 M93.9,79c-0.8,0.5-1.4,0.3-2.1-0.3c-2.7-2.5-6.6-2.9-9.7-1c-3.1,1.9-4.5,5.4-3.7,8.9c1.5,6,8.9,8.1,13.4,3.8
	c0.3-0.3,0.9-0.6,1.3-0.5c1.7,0.7,3.5,1.5,5.2,2.3c0,0.3,0,0.7,0,1c0.2,2.7,2.2,4.9,4.7,5.4c2.7,0.6,5.3-0.6,6.7-3
	c1.3-2.3,0.9-5.2-0.9-7.2c-1.9-2-4.9-2.8-7.1-1.3c-1.9,1.3-3.1,0.8-4.7,0c-0.7-0.3-1.4-0.7-2.2-0.9c-1.3-0.5-0.5-1.5-0.6-2.3
	c0-0.3,0.3-0.9,0.7-1c1.2-0.6,2.4-1,3.7-1.6c0.9-0.5,1.5-0.5,2.3,0.2c2.2,1.7,5.3,1.4,7.4-0.5c2.1-1.9,2.5-4.9,1.3-7.4
	c-1.4-2.4-4.2-3.6-6.9-2.9c-2.7,0.7-4.2,2.8-4.5,6.4C96.9,77.6,95.4,78.2,93.9,79z M86.3,88.6c-2.2,0-4.1-1.9-4.1-4.1
	s1.9-4.1,4.2-4.1c2.2,0,3.9,1.9,3.9,4.1S88.5,88.6,86.3,88.6z M106.6,76.4c0,1-0.8,2-2,2.1c-1.2,0-2.1-0.9-2.1-2s0.9-2,2-2
	C105.5,74.4,106.5,75.3,106.6,76.4z M102.5,92.7c0-1.2,0.9-2.1,2.1-2.1c1,0,2,0.9,2,2.1c0,1-0.9,2-2,2
	C103.5,94.7,102.5,93.7,102.5,92.7z M39.8,77.5c-0.1-0.9-0.2-2-0.5-2.9c-1.3-3.6-5-5.8-8.7-5.2c-3.9,0.6-6.8,3.8-6.9,7.6
	c0,1.5,0,2.9,0,4.4c-0.6,0-0.9,0-1.2,0c-2.2,0-2.9,0.6-2.9,2.9c0,4.3,0,8.6,0,12.8c0,1.7,0.7,2.4,2.4,2.4c6.5,0,12.8,0,19.3,0
	c1.7,0,2.5-0.7,2.5-2.5c0-4.2,0-8.3,0-12.5c0-2.7-0.5-3.1-3-3.1c-0.2,0-0.6,0-1,0C39.8,80.1,39.8,78.8,39.8,77.5z M39.8,94.9
	c0,0.2,0,0.3,0,0.7c-5.3,0-10.6,0-16.1,0c0-3.2,0-6.6,0-10c5.3,0,10.6,0,16.1,0C39.8,88.8,39.8,91.9,39.8,94.9z M28.7,81.5
	c-0.2,0-0.6,0-0.9,0c0-1.6-0.1-3.2,0-4.7c0.2-2.1,2.2-3.5,4.2-3.4c2,0.1,3.6,1.7,3.8,3.7c0.1,1,0,2,0,3c0,0.3,0,0.7,0,1.3
	C33.3,81.5,31,81.5,28.7,81.5z M62.1,47.1c1.3,0,2.5,0,3.8,0c0-1.4,0-2.7,0-3.9c-1.3,0-2.5,0-3.8,0C62.1,44.4,62.1,45.7,62.1,47.1z
	 M62.1,55.1c1.4,0,2.7,0,3.8,0c0-1.4,0-2.7,0-3.9c-1.3,0-2.5,0-3.8,0C62.1,52.5,62.1,53.8,62.1,55.1z M66.1,59.4c-1.4,0-2.7,0-3.9,0
	c0,1.4,0,2.7,0,3.9c1.3,0,2.5,0,3.9,0C66.1,61.9,66.1,60.6,66.1,59.4z M66,71.3c0-1.3,0-2.5,0-3.8c-1.4,0-2.7,0-3.9,0
	c0,1.3,0,2.5,0,3.8C63.4,71.3,64.7,71.3,66,71.3z M66,75.6c-1.4,0-2.7,0-3.9,0c0,1.4,0,2.5,0,3.9c1.3,0,2.5,0,3.9,0
	C66,78.1,66,76.8,66,75.6z M66,87.5c0-1.3,0-2.5,0-3.8c-1.4,0-2.7,0-3.9,0c0,1.3,0,2.5,0,3.8C63.4,87.5,64.7,87.5,66,87.5z
	 M57.1,111.9c0,3.9,3.1,7.1,7.1,7.1c3.8,0,7.1-3.1,7.1-6.9c0-3.9-3.1-7.2-7.1-7.2C60.2,104.7,56.9,108,57.1,111.9z M64,114.9
	c-1.6,0-3-1.4-3-3c0-1.6,1.4-3,3-3c1.6,0,3,1.4,3,3C67.1,113.5,65.7,114.9,64,114.9z"/>
</svg>
                        <h3>Capability Maturing</h3>
                        <p>Our agile Project Management team will focus on capability delivery iteratively. </p>
                    </Col>
                    <Col className='app_dev_s2_box' xs={{ span: 11,offset:-1}} lg={{ span: 11,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M60.4,119.1c2.4,0,4.7,0,7.2,0c0-0.7,0-1.2,0-1.8c0-12.8,0-25.5,0-38.3c0-4.5,1.9-6.4,6.5-6.4
	c2.9,0,5.8,0,8.7,0c3.8,0,5.9,2.1,5.9,5.9c0,13,0,26.1,0,39.1c0,0.5,0,1,0,1.5c2.4,0,4.7,0,7.2,0c0-0.6,0-1.2,0-1.7
	c0-25.3,0.1-50.6-0.1-75.9c0-4.2,2.7-6.5,6.4-6.3c2.9,0.2,5.9,0,8.9,0c3.8,0,5.9,2.1,5.9,5.9c0,25.4,0,50.8,0,76.2
	c0,0.6,0,1.1,0,1.9c1.7,0,3.3-0.1,4.9,0c1.8,0.1,2.8,1.7,1.9,3.1c-0.3,0.5-1,0.9-1.6,1.1c-0.4,0.2-1,0.1-1.5,0.1
	c-37.9,0-75.7,0-113.6,0c-0.5,0-1,0-1.5-0.1c-1.2-0.2-1.8-0.9-1.9-2.1c-0.1-1.1,0.8-2,2-2.1c1.6-0.1,3.3,0,5.1,0c0-0.7,0-1.2,0-1.7
	c0-7.4,0-14.9,0-22.3c0-4,2-6,6-6c3.2,0,6.4,0,9.5,0c3.3,0,5.5,2.2,5.5,5.5c0,7.6,0,15.1,0,22.7c0,0.5,0,1.1,0,1.7
	c2.4,0,4.8,0,7.2,0c0-0.6,0-1.1,0-1.6c0-18,0-35.9,0-53.9c0-4.1,2-6.1,6.1-6.1c3,0,6,0,9,0c4,0,6,2,6,6.1c0,16.8,0,33.6,0,50.4
	C60.4,115.6,60.4,117.3,60.4,119.1z M112.6,119.2c0-0.4,0.1-0.7,0.1-1c0-25.6,0-51.3,0-76.9c0-1.2-0.5-1.7-1.7-1.6
	c-3,0.1-5.9,0-8.9,0c-1.9,0-1.9,0.1-1.9,2c0,25.4,0,50.8,0,76.1c0,0.5,0,0.9,0,1.5C104.4,119.2,108.4,119.2,112.6,119.2z
	 M56.1,119.1c0-0.6,0-1.1,0-1.6c0-11.4,0-22.8,0-34.2c0-6.6,0-13.2,0-19.7c0-1.6-0.2-1.8-1.8-1.8c-2.9,0-5.7,0-8.6,0
	c-2.1,0-2.1,0-2.1,2.2c0,17.9,0,35.7,0,53.6c0,0.5,0,1,0,1.5C47.8,119.1,51.8,119.1,56.1,119.1z M84.3,119.1c0-0.3,0.1-0.4,0.1-0.6
	c0-13.4,0-26.8,0-40.1c0-1.1-0.5-1.5-1.6-1.5c-3,0-6,0-9,0c-1.8,0-2,0.2-2,2c0,12.9,0,25.9,0,38.8c0,0.5,0.1,0.9,0.1,1.4
	C76.2,119.1,80.2,119.1,84.3,119.1z M27.6,119.2c0.1-0.2,0.1-0.3,0.1-0.4c0-8,0-16,0-24c0-1-0.6-1.2-1.5-1.2c-3.1,0-6.2,0-9.3,0
	c-1.6,0-1.7,0.1-1.7,1.8c0,7.5,0,15,0,22.5c0,0.4,0.1,0.8,0.1,1.3C19.5,119.2,23.5,119.2,27.6,119.2z M102.3,19.4
	c-5.6,5-11.1,9.9-16.5,14.9c-0.3,0.3-0.3,1-0.2,1.5c1.4,4.4-0.9,9-5.3,10.4c-4.4,1.3-9-1.2-10.1-5.7c-0.3-1.1-0.2-2.3-0.4-3.6
	c-2.3-1.1-4.7-2.3-7.1-3.5c-2-1-4.1-2-6.2-3c-0.4-0.2-1-0.1-1.3,0.2c-2.8,2.3-5.8,2.7-9.1,1.3c-0.4-0.2-1,0-1.3,0.2
	c-5.2,4.6-10.3,9.3-15.4,14c-0.6,0.5-0.6,0.9-0.3,1.6c1.3,3.8-0.2,7.8-3.6,9.7c-3.3,1.9-7.4,1.3-9.9-1.5c-2.6-2.8-2.9-7.1-0.6-10.3
	c2.3-3.1,6.3-4.2,9.9-2.6c0.8,0.4,1.3,0.2,1.9-0.4c5-4.6,10-9.2,15.1-13.7c0.7-0.6,0.6-1.1,0.4-1.9c-1.4-4.3,0.9-9,5.2-10.3
	c4.4-1.4,9,1.2,10.2,5.6c0.3,1.1,0.3,2.4,0.4,3.7c4.4,2.1,8.8,4.4,13.4,6.5c0.3,0.2,1,0.1,1.3-0.2c2.8-2.4,5.9-2.8,9.3-1.3
	c0.3,0.1,1,0.1,1.2-0.2c5.4-4.7,10.7-9.5,16-14.3c0.3-0.3,0.3-1,0.2-1.5c-1.4-4.5,1-9.1,5.5-10.4c4.4-1.3,9,1.3,10.1,5.8
	c0.8,3.2-0.1,6-2.6,8.1c-2.5,2.1-5.4,2.5-8.4,1.3C103.2,19.9,102.8,19.7,102.3,19.4z M106.9,16.4c2.1-0.1,3.7-2,3.6-4.1
	c-0.1-2.1-1.9-3.6-4-3.5c-2,0.1-3.6,1.9-3.5,4C103.1,14.9,104.9,16.5,106.9,16.4z M53.3,24.6c0-2.1-1.6-3.8-3.7-3.8
	c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.7,3.8,3.7C51.6,28.3,53.3,26.7,53.3,24.6z M74.2,38.3c0,2.1,1.6,3.8,3.7,3.8
	c2,0,3.9-1.8,3.8-3.8c0-2-1.7-3.8-3.8-3.8C75.8,34.6,74.2,36.2,74.2,38.3z M21.2,54.1c2.1,0,3.8-1.6,3.8-3.7c0-2.1-1.7-3.9-3.8-3.9
	c-2,0-3.7,1.7-3.7,3.8C17.5,52.4,19.2,54.1,21.2,54.1z"/>
</svg>
                        <h3>Capability Maturing</h3>
                        <p>Our agile Project Management team will focus on capability delivery iteratively. </p>
                    </Col>
                    
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default appdevelopment