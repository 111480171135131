import React from 'react';
import './servicesbox.css';
import BoxIcon1 from '../../assets/box-icon1.svg';
import BoxIcon2 from '../../assets/box-icon2.svg';
import BoxIcon3 from '../../assets/box-icon3.svg';
import { Menu, Col, Divider, Row } from 'antd';


const Servicesbox = () => {
  return (
    <div className='serviceboxes'>
      <div className='container'>

        <div className='box'>
          <Row>
            <Col xs={{ span: 3 }} lg={{ span: 6 }}>
              <img className='boxicon' src={BoxIcon1} alt='' />
            </Col>
            <Col xs={{ span: 9 }} lg={{ span: 18 }}>
              <h2>IT Staffing Solutions</h2>
              
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 3 }} lg={{ span: 18, offset: 6 }}>
            <p>Experienced, dedicated team that fits our customer’s culture.Flexible and collaborative engineers focused on organization values..</p>
            </Col>
        
          </Row>
            
        </div>

        <div className='box'>
          <Row>
            <Col xs={{ span: 3 }} lg={{ span: 6 }}>
              <img className='boxicon' src={BoxIcon2} alt='' />
            </Col>
            <Col xs={{ span: 9 }} lg={{ span: 18 }}>
              <h2>Cloud Technology Services</h2>
              
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 3 }} lg={{ span: 18, offset: 6 }}>
            <p>Experienced, dedicated team that fits our customer’s culture.Flexible and collaborative engineers focused on organization values..</p>
            </Col>
        
          </Row>
            
        </div>

        <div className='box'>
          <Row>
            <Col xs={{ span: 3 }} lg={{ span: 6 }}>
              <img className='boxicon' src={BoxIcon3} alt='' />
            </Col>
            <Col xs={{ span: 9 }} lg={{ span: 18 }}>
              <h2>IT Staffing Solutions</h2>
              
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 3 }} lg={{ span: 18, offset: 6 }}>
            <p>Experienced, dedicated team that fits our customer’s culture.Flexible and collaborative engineers focused on organization values..</p>
            </Col>
        
          </Row>
            
        </div>
      </div>
    </div>
  )
}

export default Servicesbox