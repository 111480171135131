import React, { useState } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd'
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import Megamenu from '../megamenu/Megamenu'


import './navi.css';

const items: MenuProps['items'] = [
  {
    label: (
      <Link to='/'>Home</Link>
    ),
    key: 'home',
  },
  
  {
    label: 'Services',
    key: 'services',
    children: [
      {
        label: <Megamenu />,
        key: 'Megamenu',
        style: {
          height: "fit-content",
          backgroundColor: "white",
          padding: '0'
          
        }
      },
    ],
  },
  {
    label: 'Insight',
    key: 'Insight',
  },
  {
    label: 'Industries',
    key: 'Industries',
  },
  {
    label: (
      <CustomLink to='/career'>Career</CustomLink>
    ),
    key: 'career',
  },
  {
    label: (
      <CustomLink to='/about'>About Us</CustomLink>
    ),
    key: 'about-us',
  },
  {
    label: (
      <CustomLink to='/contactus'>Contact Us</CustomLink>
    ),
    key: 'Contact Us',
  },
  
  
];

const Navi: React.FC = () => {

  
  const [current, setCurrent] = useState('home');

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
};

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}

export default Navi;
