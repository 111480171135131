import React from 'react';
import './casestudies.css';
import { Menu, Col, Divider, Row } from 'antd';


const Casestudies = () => {
  return (
    <>
    <div className='casestudies'>
      <div className='container'>
        <div className='casestudies_container'>
        <Row>
        <Col xs={{ span: 12 }} lg={{ span: 24 }}>
              <h1>case studies</h1>
              
        </Col>
        </Row>
        <Row>
        <Col xs={{ span: 12 }} lg={{ span: 24 }}>
              <svg height="40" width="100">
                <g fill="none">
                  <path stroke-width="2" stroke="#353535" d="M5 20 l215 0" />
                </g>
              </svg>
        </Col>
        </Row>
        <Row>
        <Col xs={{ span: 6 }} lg={{ span: 12 }}>

            <img className='casestudies_box' src={require('../../assets/case-study-1.png')} alt='' />
              
        </Col>
        <Col xs={{ span: 6 }} lg={{ span: 12 }}>
            <img className='casestudies_box' src={require('../../assets/case-study-2.png')} alt='' />
        </Col>
        </Row>
        </div>
      </div>  
    </div>
    </>
  )
}

export default Casestudies