import './aboutus.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const Aboutus = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>aboutus</h1>
    </div>
    <div className='aboutus_page'>
        
        <div className='fix'>
        

        <Row className='container aboutus_s1_container'>
            
            <Col className='aboutus_div_right' xs={{ span: 23,offset:-1}} sm={{ span: 16,offset:0}} xl={{ span: 16,offset:0 }} >
                <Row>
                    <Col xs={{ span: 23,offset:-1}} sm={{ span: 23,offset:1}} xl={{ span: 23,offset:1 }}>
                        <div className='aboutus_s2_box_container'>

                        <p>Dhansol Software Solutions, LLC is a full-service Information Technology firm specializing in IT consulting, cloud technology solutions, and Data Integration and Analytics. We provide short and long-term staff augmentation, custom software development, and managed services in cloud application development. We develop easy-to-use custom applications in state-of-the-art cloud technologies for a wide range of businesses.</p><br/>

                        </div>
                    </Col>
                    
                </Row>
            </Col>
            <Col className='aboutus_div_right' xs={{ span: 23,offset:-1}} sm={{ span: 8,offset:0}} xl={{ span: 8,offset: 0}}>
			<div className='bg_box1'></div>
			<div className='overlay'></div>
			</Col>
        </Row>

		<Row className='aboutus_s2_container'> 
            <div className='container'>
                    <Col className='aboutus_s2_container_box1' xs={{ span: 12,offset:-1}} sm={{ span: 12,offset:1}} xl={{ span: 10,offset:1 }}>
                        <h2>MISSION STATEMENT</h2>
                        <p>Dhansol’s mission is to simplify technologies, so they become easier to access and use to solve customer’s business problems and empower their business.</p>
                    </Col>
                    <Col className='aboutus_s2_container_box2' xs={{ span: 12,offset:-1}} sm={{ span: 12,offset:1}} xl={{ span: 12,offset: -1 }}>
                        <h2>VISION</h2>
                        <p>Creation of technology is a ubiquitous event that happens very often. But knowing their existence and their capabilities and using them productively to solve business problem is an ever-challenging task. </p>
                        <br/><p><strong>Our vision is to be the only solution provider, businesses can think of first when they are in need of any technology services to solve complex business problem and exploit the technologies to the best of its capabilities, irrespective of their size.</strong></p>
                    </Col>
            </div>
		
		</Row>
        <Row className='container aboutus_s3_container'> 
        
		<Col className='aboutus_div_right' xs={{ span: 23,offset:-1}} sm={{ span: 24,offset:0}} xl={{ span: 20,offset: 2}}>
			<div className='aboutus_s2_box_container'>
				<br/><p>In the field of information technology, everything depends on how efficiently information can be communicated. The effective communication depends on the interpretation of the communicable medium. The ingenuity in interpreting the communicable medium into meaningful information is what makes every technology unique and supersede each other. A technology is as good as the usability of its interpretation methodology and its availability, and it should be understandable by all parties that are involved in the communications. Our job is to make all technologies easier to use irrespective of their complexity.</p><br/>
					<p>At Dhansol we have an appreciation for all technologies and we embrace them for their innovativeness. Our mission is to learn, understand and create technologies and empower them by identifying and implementing ingenious techniques that exists within them and make every business utilize them to the best of their extent.</p><br/>
					<p>Our focus is to provide cost-effective solutions to our customers to help support their technology needs in the cloud, e-commerce, and application developments. </p><br/>
					<p>Our unique business strategy allows us to provide affordable yet state-of-the-art technology solutions to our customers.<a><strong> Learn more…</strong></a></p><br/>
                    <p>Whether your need is e-commerce solution, database management or application support, we have the knowledge and experience. We work closely with our customers to help them grow with their business and create long-term partnership that guarantees a stable technology platform.</p>
			</div>
		</Col>
		</Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default Aboutus