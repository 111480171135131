import React from 'react';
import { Navi, Servicesbox } from '../../components';
import './headersub.css';
import Logo from '../../logo.svg';

import { Col, Row } from 'antd';


const Headersub = () => {
  return (
    <>
    <div className='headersub'>
      <Row className='top__navi container'>
          <Col xs={{ span: 6 }} lg={{ span: 6 }}>
            <img className='app__logo' src={Logo} alt='' />
          </Col>
          <Col className='navi__align' xs={{ span: 18 }} lg={{ span: 18, offset:0 }}>
            <Navi />
          </Col>
      </Row>
    <div className='headersub__banner'>
      


    </div>
    </div>
      
    </>
  )
}

export default Headersub