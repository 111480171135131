import React, { useState } from 'react';
import './news.css';
import { Menu, Col, Divider, Row, Button, Space } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';


const News = () => {
  return (
    <>
    <div className='news'>
    <div className='container'>
        <div className='news_container'>
        <Row>
        <Col xs={{ span: 12 }} lg={{ span: 24 }}>
              <h1>News and Blogs</h1>
              
        </Col>
        </Row>
        <Row>
        <Col xs={{ span: 12 }} lg={{ span: 24 }}>
              <svg height="40" width="100">
                <g fill="none">
                  <path stroke-width="2" stroke="#353535" d="M5 20 l215 0" />
                </g>
              </svg>
        </Col>
        </Row>
        <Row className='blog_container' gutter={16}>
        <Col className="gutter-row" span={8}>
            <div className='blog_content'>
            <img className='blog_image' src={require('../../assets/blog1.jpg')} alt='' />  
                <div className='blog_heading'>
                  <span>June 12, 2022 ..</span>
                  <p><a href='#'>Editorial</a></p>
                  <h3><a href='#'>Reasons Why Quality Assurance is Important and the Business Benefits It Brings to Your Business </a></h3>
                  <Button type="default">Read Article</Button>
                </div>                    


            </div>
              
        </Col>
        <Col className="gutter-row" span={8}>
            <div className='blog_content'>
            <img className='blog_image' src={require('../../assets/blog2.jpg')} alt='' />  
                <div className='blog_heading'>
                  <span>June 12, 2022 ..</span>
                  <p><a href='#'>Editorial</a></p>
                  <h3><a href='#'>Using ChatGPT for Software Testing and Test Automation</a></h3>
                  <Button type="default">Read Article</Button>
                </div>                    


            </div>
              
        </Col>
        <Col className="gutter-row" span={8}>
            <div className='blog_content'>
            <img className='blog_image' src={require('../../assets/blog3.jpg')} alt='' />  
                <div className='blog_heading'>
                  <span>June 12, 2022 ..</span>
                  <p><a href='#'>Editorial</a></p>
                  <h3><a href='#'>Theo Schnitfink on Forbes:  Exploring the Business Competitive Advantage of Amazon’s RedShift and Google’s BigQuery </a></h3>
                  <Button type="default">Read Article</Button>
                </div>                    


            </div>
              
        </Col>
        </Row>
        </div>
        </div>
    </div>
    </>
  )
}

export default News