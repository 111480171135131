import './dataintegration.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const Dataintegration = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>Our Services</h1>
    </div>
    <div className='dataintegration_page'>
        <Row className='align_margin'>
            <Col xs={{ span: 23 }} lg={{ span: 13 }}>
            <div className='bg_box1'></div>
            
                
            </Col>
            <Col className='services_div_right' xs={{ span: 23 }} lg={{ span: 11 }}>
                <h1>Data Integration</h1>
                <div className='line'></div>
				<p>
				Unify multi-platform data with our data integration experts. We specialize in bringing together a Peta byte of business and user data from multiple data sources and unify the for real time business access with surety data integrity and data quality. Our experts can help with small data integration to enterprise-wide real-time data access for business services, AI & Machine Learning and Business Analytics.

</p><br/><p>Schedule a meeting with us to find out how we can help with your data integration need.

				</p>
            </Col>
        </Row>
        <div className='dataintegration_box_container'>
        

        <Row className='dataintegration_s2_container align_margin'>
            
            <Col className='dataintegration_div_right' xs={{ span: 24,offset:0 }} lg={{ span: 24, offset:0 }}>
                <div className='dataintegration_s2_box_container'>
                    <Row>
                    <Col className='dataintegration_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M54.9,79.2c0-0.8,0-1.4,0-1.9c0-12.3,0-24.5,0-36.8c0-4.5,3.1-7.7,7.2-7.5c3.2,0.1,6,2.6,6.4,5.8
	c0.1,0.7,0.1,1.5,0.1,2.3c0,8,0,15.9,0,23.9c0,0.6,0,1.1,0,1.9c3.7-2.2,6.7-1.4,9.3,1.6c3.9-4,7.6-3.3,10.8,0
	c1.2-1.5,2.7-2.6,4.7-2.8c3.7-0.4,7,2.4,7.4,6.3c0.1,0.7,0.1,1.5,0.1,2.3c0,12,0,24,0,35.9c0,1.7-0.3,3.4-0.7,5
	c-0.8,3.3-1.8,6.6-2.8,9.8c-0.3,1-0.8,1.5-1.9,1.3c-1-0.3-1.2-1.1-1-2c0.1-0.2,0.1-0.5,0.2-0.7c0.9-3.3,1.9-6.5,2.8-9.8
	c0.2-0.9,0.4-1.9,0.4-2.8c0-7.3,0-14.7,0-22c0-0.5,0-1,0-1.6c-13.4,0-26.7,0-40.1,0c0,0.6-0.1,1.1-0.1,1.6c0,4,0,8,0,11.9
	c0,1.9-0.4,2.2-2.2,2.2c-2.4,0-4.8,0-7.3,0c0.3,0.5,0.5,0.9,0.8,1.4c3.7,6.3,7.5,12.5,11.2,18.8c0.2,0.4,0.6,0.8,0.6,1.3
	c0,0.5-0.2,1.3-0.6,1.6c-0.4,0.2-1.2,0.1-1.7-0.1c-0.3-0.1-0.5-0.6-0.7-1c-4.4-7.3-8.8-14.7-13.2-22c-0.4-0.7-0.6-1.5-0.6-2.3
	c0-7.2,0-14.4,0-21.6c0-1.8,0.4-2.2,2.3-2.3C49.3,76.8,52.2,77.1,54.9,79.2z M58,84.2c2.6,0,5.2,0,7.6,0c0-8,0-15.8,0-23.6
	c-2.6,0-5.1,0-7.6,0C58,68.6,58,76.5,58,84.2z M65.7,57.6c0-6.2,0-12.2,0-18.3c0-1.5-1.3-2.8-2.8-3.2c-2.6-0.7-4.9,1.2-4.9,4.2
	c0,5.4,0,10.9,0,16.3c0,0.3,0.1,0.6,0.2,1C60.6,57.6,63.1,57.6,65.7,57.6z M54.9,100.1c0-0.5,0.1-0.9,0.1-1.4c0-4.2,0-8.3,0-12.5
	c0-4.3-3.5-7.1-7.8-6.1c0,6.6,0,13.3,0,20C49.7,100.1,52.2,100.1,54.9,100.1z M98.1,84.2c0-4,0.1-7.8,0-11.7
	c-0.1-2.3-1.8-3.9-3.8-3.9c-1.9,0-3.7,1.7-3.9,4c-0.2,2.5-0.1,5.1-0.1,7.6c0,1.3,0,2.6,0,4C92.9,84.2,95.4,84.2,98.1,84.2z
	 M76.5,84.2c0-3.6,0-7.2,0-10.7c0-2.7-1.7-4.9-3.9-5c-2.1,0-3.9,2.1-3.9,4.9c0,3.2,0,6.4,0,9.7c0,0.4,0.1,0.7,0.1,1.1
	C71.4,84.2,73.9,84.2,76.5,84.2z M79.6,84.2c2.6,0,5.1,0,7.7,0c0-3.6,0-7.1,0-10.6c0-0.3,0-0.7,0-1c-0.2-2.1-1.9-3.8-3.8-3.8
	c-1.8-0.1-3.8,1.5-3.9,3.5C79.5,76.2,79.6,80.2,79.6,84.2z M106.3,20.7c-1.4-3.4-1.1-5.9,1.1-7.9c2.2-2,5.4-2.1,7.8-0.3
	c2.3,1.8,3.1,5,1.7,7.6c-1.4,2.7-4.4,4-7.2,3c-1-0.3-1.5-0.1-2.2,0.6c-2.6,2.7-5.3,5.3-8,7.9c-0.5,0.5-1.5,0.9-2.2,0.9
	c-7.2,0.1-14.4,0-21.6,0c-1.1,0-2.1-0.2-2.1-1.5c0-1.2,1-1.4,2-1.4c6.8,0,13.6,0,20.4,0c0.7,0,1.6-0.4,2.1-0.9
	C101,26.1,103.6,23.4,106.3,20.7z M114.6,17.3c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.2,1.4-3.2,3.1c0,1.8,1.5,3.3,3.2,3.2
	C113.3,20.4,114.7,19,114.6,17.3z M106.3,59.4c-2.9-2.9-5.6-5.7-8.3-8.4c-0.4-0.4-1.2-0.6-1.8-0.6c-6.7,0-13.3,0-20,0
	c-0.4,0-1,0.1-1.2-0.1c-0.5-0.4-1.1-1-1.2-1.5c0-0.4,0.7-0.9,1.2-1.3c0.3-0.2,0.7-0.1,1.1-0.1c7,0,14,0,21,0c1.1,0,2,0.3,2.7,1.1
	c2.6,2.7,5.3,5.3,7.9,7.9c0.5,0.5,0.9,0.7,1.7,0.5c2.9-1,5.9,0.1,7.3,2.7c1.4,2.5,0.9,5.7-1.3,7.6c-2.1,1.9-5.3,2.1-7.6,0.4
	c-2.4-1.7-3.2-4.8-2-7.6C106,59.8,106.2,59.6,106.3,59.4z M114.6,62.7c0-1.7-1.4-3.2-3.1-3.2c-1.8,0-3.3,1.4-3.2,3.2
	c0,1.7,1.4,3.1,3.2,3.1C113.3,65.8,114.6,64.5,114.6,62.7z M91.4,41.7c-5.1,0-10.1,0-15.2,0c-0.4,0-0.9,0.1-1.3-0.1
	c-0.5-0.3-1.2-0.9-1.2-1.3c0-0.5,0.6-1.1,1.1-1.5c0.3-0.2,0.9-0.1,1.4-0.1c10.2,0,20.4,0,30.5,0c0.9,0,1.4-0.2,1.8-1.1
	c1.2-2.7,4-4,6.8-3.4c2.8,0.6,4.8,3.1,4.8,5.9c0,2.9-1.9,5.4-4.8,6.1c-2.7,0.7-5.6-0.7-6.8-3.4c-0.4-1-1-1.1-1.9-1.1
	C101.7,41.7,96.5,41.7,91.4,41.7z M114.2,43.4c1.7,0,3.1-1.5,3.1-3.2c0-1.7-1.5-3.2-3.2-3.2c-1.7,0-3.3,1.6-3.2,3.3
	C111,42.1,112.5,43.4,114.2,43.4z M21.7,59.5c2.4,4.6-0.7,8.5-3.8,9.2c-2.5,0.6-5.1-0.4-6.6-2.6c-1.4-2.2-1.3-4.9,0.2-7
	c1.5-2,4.2-3,6.6-2.1c1,0.4,1.6,0.2,2.3-0.6c2.6-2.6,5.2-5.3,7.9-7.8c0.6-0.5,1.5-1,2.3-1c5.6-0.1,11.2,0,16.7,0c1,0,2.1,0,2.1,1.4
	c0,1.5-1.1,1.5-2.2,1.5c-5.2,0-10.4,0-15.6,0c-0.6,0-1.3,0.2-1.6,0.5C27.3,53.7,24.6,56.5,21.7,59.5z M16.5,65.8
	c1.7,0,3.1-1.4,3.2-3.1c0-1.8-1.5-3.3-3.2-3.2c-1.7,0-3.1,1.5-3.1,3.2C13.4,64.5,14.8,65.8,16.5,65.8z M21.7,20.6
	c2.9,2.9,5.5,5.7,8.3,8.4c0.4,0.4,1.2,0.6,1.8,0.6c5.3,0,10.5,0,15.8,0.1c0.6,0,1.5,0.3,1.8,0.7c0.7,0.9-0.1,2.1-1.4,2.1
	c-2.9,0-5.8,0-8.7,0c-2.9,0-5.8,0.1-8.7,0c-0.8,0-1.7-0.4-2.2-0.9c-2.8-2.6-5.5-5.4-8.2-8.1c-0.5-0.5-0.9-0.7-1.7-0.4
	c-2.9,1-5.9-0.1-7.3-2.7c-1.5-2.5-0.9-5.7,1.3-7.6c2.2-1.9,5.4-2.1,7.7-0.3c2.4,1.8,3.1,4.8,1.8,7.6C21.9,20.2,21.8,20.4,21.7,20.6z
	 M16.5,14.1c-1.7,0-3.1,1.4-3.2,3.1c0,1.7,1.3,3.2,3,3.3c1.7,0.1,3.3-1.4,3.3-3.2C19.7,15.6,18.3,14.1,16.5,14.1z M19.8,38.7
	c9.1,0,18,0,26.9,0c0.5,0,1.1-0.2,1.4,0.1c0.6,0.4,1.2,0.9,1.3,1.5c0.1,0.4-0.7,1-1.3,1.3c-0.4,0.2-0.9,0.1-1.4,0.1
	c-8.6,0-17.1,0-25.7,0c-0.9,0-1.4,0.2-1.8,1.1c-1.2,2.7-4.1,4-6.8,3.4c-2.9-0.7-4.8-3.1-4.8-6c0-2.8,2-5.3,4.8-5.9
	c2.8-0.6,5.6,0.8,6.9,3.5C19.5,38.1,19.7,38.4,19.8,38.7z M17.1,40.2c0-1.7-1.5-3.2-3.1-3.2c-1.7,0-3.3,1.5-3.2,3.2
	c0,1.7,1.5,3.1,3.2,3.1C15.6,43.4,17.1,41.9,17.1,40.2z M69,9.4c0,2.8,0,5.6,0,8.3c0,1.7,0,3.4,0,5.1c0,1-0.4,1.7-1.4,1.7
	c-1.1,0-1.5-0.8-1.5-1.8c0-4.8,0-9.7,0-14.5c0-1.4,0.5-1.9,2-2c2.8,0,5.7,0,8.5,0c0.8,0,1.1-0.2,1.5-0.9c1.3-2.9,4.2-4.4,7.2-3.7
	C88,2.4,90,5,90,7.9c0,2.9-2.1,5.5-4.8,6.1c-3,0.7-5.8-0.8-7.2-3.7c-0.2-0.4-0.8-0.9-1.2-0.9c-2.2-0.1-4.4,0-6.7,0
	C69.8,9.4,69.5,9.4,69,9.4z M87,7.8c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.2,1.4-3.2,3.2c0,1.9,1.4,3.3,3.3,3.4C85.5,11.2,87,9.7,87,7.8z
	 M44.8,6.4c3.2,0,6.2,0,9.3,0c1.8,0,2.2,0.4,2.2,2.2c0,4.7,0,9.4,0,14.1c0,1-0.2,2-1.4,2c-1.3,0-1.5-0.9-1.5-2c0-3.8,0-7.7,0-11.5
	c0-0.5,0-1,0-1.7c-2.8,0-5.6,0-8.3,0.1c-0.3,0-0.6,0.7-0.8,1.1c-1.3,2.6-4.1,4-7,3.4c-2.5-0.5-4.7-2.9-4.9-5.4
	c-0.3-3,1.4-5.6,4.1-6.6C39.3,1,43.2,1.9,44.8,6.4z M38.7,11.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.4-3.2-3.2-3.3c-1.9,0-3.4,1.4-3.4,3.2
	C35.3,9.6,36.8,11.1,38.7,11.2z M73.7,117.4c0-0.9-0.1-1.7,0-2.5c0.6-3.7-0.6-6.7-3.7-8.9c-0.8-0.6-1.3-1-2.4-0.7
	c-2.6,0.6-4.9-1-5.5-3.6c-0.5-2.3,1-4.8,3.3-5.4c2.5-0.7,5,0.6,5.7,3.1c0.2,0.7,0.3,1.7,0.1,2.4c-0.3,1,0,1.6,0.7,2.2
	c4,2.8,5.4,6.5,4.8,11.4c-0.4,2.8-0.1,5.7-0.1,8.5c0,0.3,0,0.6,0,0.9c0,1.1-0.3,2-1.6,1.9c-1.1,0-1.4-0.9-1.4-1.9
	C73.7,122.1,73.7,119.8,73.7,117.4z M66.7,102.4c1.1-0.1,1.7-0.7,1.8-1.7c0.1-1-0.8-1.8-1.8-1.8c-1.1,0.1-1.7,0.7-1.7,1.7
	C65,101.7,65.7,102.3,66.7,102.4z M79.9,116.4c0-2.6-0.1-5.1,0-7.7c0-0.8,0.5-1.6,0.9-2.2c0.5-0.7,1.2-1.3,1.9-1.9
	c1.1-1.1,2-2,1.7-3.9c-0.4-2.6,2.2-4.9,4.8-4.7c2.7,0.2,4.7,2.5,4.4,5.2c-0.3,2.7-2.8,4.7-5.4,4c-1.3-0.3-2,0.2-2.9,0.9
	c-2.1,1.6-2.7,3.6-2.5,6.1c0.2,4,0,8,0.1,12.1c0,1.1-0.2,2.1-1.5,2.1c-1.2,0-1.4-1-1.4-2C79.9,121.7,79.9,119,79.9,116.4z
	 M90.7,100.8c-0.1-1-0.6-1.7-1.6-1.8c-1-0.1-1.7,0.5-1.8,1.6c-0.1,1,0.7,1.9,1.7,1.8C90,102.4,90.6,101.8,90.7,100.8z M111.5,14.1
	c-1.7,0-3.2,1.4-3.2,3.1c0,1.8,1.5,3.3,3.2,3.2c1.7,0,3.1-1.5,3.1-3.2C114.6,15.5,113.2,14.1,111.5,14.1z M83.7,4.6
	c-1.8,0-3.2,1.4-3.2,3.2c0,1.9,1.4,3.3,3.3,3.4c1.8,0,3.3-1.5,3.3-3.3C87,6,85.5,4.6,83.7,4.6z"/>
</svg>
                        <h3>Micro Services</h3>
                        <p>Let us help define your micro services layer that can support your complex business process.</p>
                    </Col>
                    <Col className='dataintegration_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
					<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M2.9,112.2c40.8,0,81.5,0,122.2,0c0.6,3.6-0.2,5-3.3,6.4c-3.3,1.5-6.8,2.2-10.4,2.2c-31.6,0-63.2,0-94.8,0
	c-3.5,0-7-0.7-10.2-2.1C3.1,117.3,2.4,116,2.9,112.2z M4.9,114.3c-0.1,1.3,0.7,1.8,1.6,2.2c3.2,1.5,6.5,2.2,10.1,2.2
	c8.7,0,17.4,0,26.1,0c23,0,46,0,68.9,0c3.5,0,6.9-0.8,10-2.3c0.9-0.4,1.7-0.9,1.4-2.2C83.7,114.3,44.3,114.3,4.9,114.3z M20.2,106.2
	c0-17.8,0-35.6,0-53.4c0-2,0.2-2.1,2.1-2.1c9.7,0,19.3,0,29,0c0.5,0,1.1,0,1.6,0c0-0.8,0-1.4,0-2.1c-0.5,0-1,0-1.4,0
	c-9.8,0-19.5,0-29.3,0c-3,0-4.1,1.2-4.1,4.1c0,18.5,0,37,0,55.5c0,0.5,0,1,0,1.4c30.7,0,61.3,0,91.7,0c0-11,0-21.9,0-32.8
	c-1.6,0.5-2,1.4-2,3c0.1,8.8,0,17.5,0,26.3c0,0.5,0,1,0,1.6c-29.3,0-58.4,0-87.7,0C20.2,107.1,20.2,106.7,20.2,106.2z M105.4,105.4
	c0-8.6,0-17.2,0-25.8c-1.6,0.5-2,1.4-2,3c0.1,6.4,0,12.9,0,19.3c0,0.5,0,1,0,1.4c-26.4,0-52.6,0-78.8,0c0-16.1,0-32.1,0-48.2
	c9.5,0,18.9,0,28.3,0c0-0.8,0-1.4,0-1.9c-10.2,0-20.3,0-30.4,0c0,17.5,0,34.8,0,52.2C50.2,105.4,77.8,105.4,105.4,105.4z
	 M123.4,46.8c0,3.6,0.1,7.2,0,10.8c-0.2,5.1-2.4,9.1-6.6,12c-4.1,2.8-8.5,5.4-12.7,8c-0.3,0.2-1,0.2-1.3,0
	c-4.2-2.6-8.4-5.1-12.5-7.8c-4.3-2.9-6.6-7-6.8-12.2c-0.3-7.5-0.5-14.9-0.8-22.4c0-0.7,0.2-1,0.8-1.3c6.4-2.9,12.8-5.8,19.2-8.6
	c0.4-0.2,1.1-0.2,1.5,0c6.4,2.9,12.8,5.8,19.2,8.7c0.6,0.3,0.8,0.6,0.8,1.3c0,3.9,0,7.7,0,11.6C123.9,46.8,123.7,46.8,123.4,46.8z
	 M121.8,46.8c0.1,0,0.2,0,0.3,0c0-3.4,0-6.7,0-10.1c0-0.8-0.2-1.3-1-1.7c-5.5-2.5-11-5-16.6-7.5c-0.6-0.3-1.5-0.3-2.2,0
	c-5.5,2.4-11,5-16.4,7.4c-0.9,0.4-1.2,0.9-1.1,1.9c0.2,6.7,0.4,13.4,0.6,20.1c0.2,4.8,2.2,8.6,6.3,11.2c3.7,2.4,7.4,4.7,11.1,6.9
	c0.3,0.2,0.9,0.2,1.2,0.1c4.1-2.5,8.2-5,12.1-7.7c3.1-2.1,4.9-5.3,5.2-9.1C121.5,54.6,121.6,50.7,121.8,46.8z M81.5,79.3
	c0,9.6-7.9,17.5-17.5,17.5c-9.5,0-17.4-7.9-17.4-17.4c0-9.6,7.9-17.5,17.5-17.5C73.6,61.8,81.5,69.7,81.5,79.3z M64,63.9
	c-8.5,0-15.4,6.9-15.4,15.4c0,8.5,6.9,15.4,15.4,15.4c8.5,0,15.4-6.9,15.4-15.4C79.4,70.8,72.5,63.9,64,63.9z M81.3,18
	c-6.7,0-10.7,4.1-10.7,10.7c0,10,0,20.1,0,30.1c0,1.6,0.5,2.3,2.1,2.5c0-0.5,0-1,0-1.5c0-10.5,0-21.1,0-31.6c0-4.9,3.2-8.1,8.1-8.2
	c0.9,0,1.8,0,2.8,0c0-0.7,0-1.3,0-2.1C82.8,18,82.1,18,81.3,18z M57.4,58.9c-0.1-10.1,0-20.2-0.1-30.4c0-1.2-0.1-2.4-0.4-3.5
	c-1.5-5.2-6.5-7.9-12.5-6.9c0,0.6,0,1.2,0,1.9c1,0,2,0,2.9,0c4.6,0,7.9,3.3,7.9,7.9c0,5.8,0,11.7,0,17.5c0,5.2,0,10.5,0,15.8
	C56.8,61.2,57.4,60.5,57.4,58.9z M61.8,57.9c0-14.2,0-28.4,0-42.6c0-0.5,0-1,0-1.6c-0.8,0-1.4,0-2,0c0,15.4,0,30.7,0,46.1
	C61.8,59.8,61.8,59.8,61.8,57.9z M68.2,13.7c-0.7,0-1.3,0-2,0c0,0.6,0,1.1,0,1.7c0,14.2,0,28.3,0,42.5c0,1.9,0,1.9,2,1.9
	C68.2,44.4,68.2,29.1,68.2,13.7z M80.8,55c0-0.7,0-1.3,0-1.9c-2,0-3.9,0-5.8,0c0,0.7,0,1.3,0,1.9C77,55,78.9,55,80.8,55z M80.8,50.7
	c0-0.7-0.1-1.3-0.1-1.9c-1.9,0-3.8,0-5.6,0c0,0.7,0,1.2,0,1.9C77,50.7,78.8,50.7,80.8,50.7z M18.1,18.1c0,0.7,0,1.3,0,1.9
	c1.4,0,2.8,0,4.2,0c0-0.7,0-1.3,0-1.9C20.9,18.1,19.5,18.1,18.1,18.1z M26.9,20c1.4,0,2.8,0,4.1,0c0-0.7,0-1.3,0-1.9
	c-1.4,0-2.7,0-4.1,0C26.9,18.8,26.9,19.4,26.9,20z M35.7,18.1c0,0.7,0,1.3,0,1.9c1.4,0,2.7,0,4.1,0c0-0.6,0-1.2,0-1.9
	C38.4,18.1,37,18.1,35.7,18.1z M92.3,20c0-0.7,0-1.3,0-1.9c-1.4,0-2.7,0-4.1,0c0,0.6,0,1.2,0,1.9C89.6,20,91,20,92.3,20z M97,20
	c1.4,0,2.8,0,4.1,0c0-0.7,0-1.3,0-2c-1.4,0-2.7,0-4.1,0C97,18.8,97,19.4,97,20z M105.7,20c1.4,0,2.8,0,4.1,0c0-0.7,0-1.3,0-2
	c-1.4,0-2.7,0-4.1,0C105.7,18.8,105.7,19.4,105.7,20z M59.8,7.2c0,0.7,0,1.3,0,1.9c0.7,0,1.3,0,1.9,0c0-0.7,0-1.2,0-1.9
	C60.9,7.2,60.3,7.2,59.8,7.2z M68.2,9.1c0-0.7,0-1.3,0-1.9c-0.7,0-1.3,0-1.9,0c0,0.7,0,1.3,0,1.9C67.1,9.1,67.7,9.1,68.2,9.1z
	 M11.6,20c0.7,0,1.3,0,1.9,0c0-0.7,0-1.3,0-1.9c-0.7,0-1.3,0-1.9,0C11.6,18.9,11.6,19.5,11.6,20z M116.4,18.1c-0.7,0-1.3,0-1.9,0
	c0,0.7,0,1.3,0,1.9c0.7,0,1.2,0,1.9,0C116.4,19.3,116.4,18.7,116.4,18.1z M94.1,41.7c-4.8,4.9-4.9,12.6-0.4,17.7
	c4.5,5.1,12.3,5.7,17.7,1.5c4.5-3.6,6.5-11,3.9-14.7c-1,0.6-1.3,1.4-1,2.6c0.3,1.3,0.3,2.6,0,3.9c-0.7,4.6-4.7,8.4-9.1,9
	c-5,0.7-9.7-1.8-11.8-6.3c-2-4.3-1-9.5,2.5-12.8c3.5-3.3,8.7-4,12.9-1.5c1.2,0.7,1.9,0.5,2.4-0.6C106.5,36.5,98.7,37.1,94.1,41.7z
	 M103.5,52.5c-1.6-1.9-3.1-3.7-4.7-5.6c-0.6,0.5-1,0.8-1.6,1.3c1.7,2,3.3,3.9,4.9,5.9c1.1,1.4,1.5,1.4,2.7,0.1
	c3.6-3.9,7.1-7.8,10.7-11.7c0.2-0.2,0.4-0.5,0.6-0.8c-0.5-0.4-0.9-0.8-1.4-1.3C110.9,44.4,107.2,48.4,103.5,52.5z M76,79.2
	c0,6.6-5.5,12-12,12c-6.5,0-11.9-5.4-11.9-12c0-6.6,5.5-12,12-12C70.6,67.3,76,72.7,76,79.2z M64,89.2c5.5,0,9.9-4.4,9.9-9.9
	c0-5.5-4.4-9.9-9.9-9.9c-5.5,0-9.9,4.4-9.9,9.9C54.1,84.7,58.5,89.2,64,89.2z"/>
</svg>
                        <h3>Service Gateways</h3>
                        <p>Let us help you implement a secure service gateway for sharing data across yours and your customers data platforms.</p>
                    </Col>
                    <Col className='dataintegration_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M65.8,96.1c0,4.8,0,9.5,0,14.2c4.8,2,7,5.4,6.1,9.6c-0.8,3.9-4.2,6.6-8.2,6.5c-3.8-0.1-7.1-3-7.7-6.9
	c-0.7-4,1.7-7.4,6.2-9.1c0-4.7,0-9.4,0-14.1c-3.4-0.4-6.8-0.7-10.1-1.2c-3.5-0.5-6.9-1.5-10-3.3c-2.3-1.4-3.7-3.2-3.4-6.1
	c0.1-1.5,0-3.1,0-4.9c-5.1,2.9-10.1,5.8-14.8,8.5c-0.1,1.8,0,3.4-0.4,4.9c-0.9,3.5-4.1,5.8-7.7,5.8c-3.6,0-6.7-2.3-7.7-5.8
	c-1-3.4,0.3-7,3.2-9c3-2,6.9-1.9,9.6,0.4c0.7,0.6,1.2,0.6,1.9,0.2c4.9-2.9,9.9-5.7,14.9-8.6c0.7-0.4,1-0.8,1-1.6
	c0-7.6,0-15.3,0-22.9c0-0.8-0.3-1.3-1-1.7c-5-2.8-10-5.7-15-8.6c-0.7-0.4-1.1-0.4-1.7,0.1c-2.8,2.4-6.8,2.6-9.8,0.5
	c-2.9-2-4.2-5.8-3-9.2c1.1-3.5,4.5-5.7,8.2-5.5c3.5,0.2,6.6,2.7,7.3,6.2c0.3,1.3,0,2.7,0,4.3c4.8,2.8,9.8,5.6,15,8.6
	c0-1.8,0.1-3.5,0-5.1c-0.2-2.8,1.1-4.5,3.3-5.9c3.1-1.9,6.5-2.9,10.1-3.4c3.3-0.5,6.7-0.8,10.1-1.2c0-4.6,0-9.3,0-14.1
	c-2.4-0.7-4.4-2.1-5.5-4.5c-0.8-1.7-1-3.5-0.5-5.4c0.9-3.6,4.3-6.2,8.2-6c3.8,0.1,7,2.9,7.7,6.8c0.7,3.9-1.5,7.3-6.2,9.2
	c0,4.7,0,9.4,0,14.2c2.2,0.2,4.5,0.3,6.6,0.6c4.1,0.5,8.2,1.3,12,3.1c1.1,0.5,2.2,1.3,3.1,2.1c1.2,1,1.7,2.3,1.7,3.9
	c-0.1,1.8,0,3.7,0,5.8c5.1-3,10.1-5.8,14.8-8.5c0.1-1.8,0-3.4,0.4-4.8c0.9-3.4,4-5.8,7.5-5.8c3.7-0.1,6.8,2.2,7.9,5.7
	c1,3.4-0.2,7-3.1,9c-3,2-6.9,1.9-9.6-0.4c-0.7-0.6-1.2-0.7-2-0.2c-4.9,2.9-9.9,5.8-14.9,8.6c-0.7,0.4-1,0.9-1,1.7
	c0,7.6,0,15.2,0,22.8c0,0.9,0.3,1.3,1.1,1.7c5,2.8,9.9,5.7,14.9,8.6c0.7,0.4,1.1,0.5,1.8-0.1c2.8-2.4,6.7-2.5,9.7-0.5
	c3,2,4.2,5.8,3.1,9.2c-1.2,3.4-4.4,5.6-8.1,5.4c-3.6-0.2-6.7-2.7-7.4-6.3c-0.3-1.3,0-2.7,0-4.2c-4.8-2.8-9.8-5.6-15-8.6
	c0,1.6-0.1,3,0,4.4c0.5,3.7-1.5,5.8-4.5,7.3c-3.9,2-8.1,2.8-12.4,3.3C70.3,95.8,68.1,95.9,65.8,96.1z M42.3,61.8c0,2.9,0,6,0,9
	c0,1.1,0.3,1.9,1.2,2.3c1.6,0.8,3.3,1.7,5.1,2.2c9.5,2.6,19.1,2.6,28.7,0.6c2.4-0.5,4.7-1.6,6.9-2.5c1.2-0.5,1.7-1.5,1.6-2.8
	c-0.1-2.2,0-4.4,0-6.6c0-0.8,0-1.6,0-2.2C71.2,67,56.8,67,42.3,61.8z M42.3,76.9c0,3,0,6.1,0,9.2c0,0.9,0.3,1.7,1.1,2.1
	c1.6,0.8,3.2,1.7,4.9,2.2c9.2,2.6,18.6,2.7,28,0.9c2.7-0.5,5.3-1.7,7.9-2.7c1.2-0.5,1.7-1.5,1.6-2.8c-0.1-1.8,0-3.7,0-5.5
	c0-1.1,0-2.3,0-3.2C71.2,82.2,56.8,82.2,42.3,76.9z M42.3,46.6c0,2.9,0.1,5.9,0,8.9c0,1.2,0.4,2.1,1.4,2.6c1.6,0.8,3.2,1.6,5,2.1
	c9.4,2.5,18.9,2.6,28.4,0.6c2.5-0.5,4.8-1.6,7.2-2.6c1.2-0.5,1.7-1.5,1.6-2.8c-0.1-2.6,0-5.2,0-7.8c0-0.4,0-0.8-0.1-0.9
	C71.2,51.9,56.8,51.8,42.3,46.6z M64,46.9c0,0.1,8.9-0.6,13.3-1.4c2.7-0.4,5.3-1.3,7.6-3c1.3-1,1.3-1.8,0-2.7
	c-1.1-0.7-2.3-1.4-3.6-1.8c-4.2-1.6-8.6-2.2-13.1-2.4c-6.7-0.4-13.4-0.1-19.9,1.9c-1.7,0.5-3.4,1.4-5,2.3c-1.4,0.8-1.4,1.8,0,2.7
	c1,0.7,2.2,1.3,3.3,1.7C52.2,46.4,58.1,46.9,64,46.9z M64,5.2c-2.5,0-4.6,2-4.5,4.6c0,2.5,2,4.5,4.5,4.5c2.5,0,4.6-2,4.6-4.5
	C68.6,7.3,66.5,5.3,64,5.2z M68.5,118.2c0-2.5-2.1-4.6-4.6-4.5c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.6,4.5,4.6
	C66.5,122.8,68.5,120.7,68.5,118.2z M20.4,36.2c0-2.5-2-4.5-4.5-4.5c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.5,4.5,4.5
	C18.3,40.8,20.4,38.8,20.4,36.2z M107.6,36.2c0,2.5,2,4.6,4.6,4.5c2.5,0,4.5-2.1,4.5-4.5c0-2.5-2.1-4.6-4.6-4.5
	C109.6,31.7,107.7,33.7,107.6,36.2z M15.9,96.3c2.5,0,4.5-2,4.4-4.5c0-2.5-2.1-4.6-4.6-4.5c-2.5,0-4.5,2.1-4.5,4.5
	C11.3,94.3,13.4,96.3,15.9,96.3z M112.2,96.3c2.5,0,4.5-2,4.5-4.5c0-2.5-2.1-4.6-4.6-4.6c-2.5,0-4.5,2.1-4.5,4.6
	C107.7,94.3,109.7,96.3,112.2,96.3z M54.7,71c0.2-1.1-0.3-1.8-1.3-2.1c-1.7-0.4-3.3-0.8-5-1.1c-1-0.2-1.8,0.2-2,1.2
	c-0.2,1,0.1,1.8,1.1,2.1c1.7,0.5,3.5,0.9,5.2,1.3C53.7,72.4,54.5,72,54.7,71z M54.7,86.1c0.2-1-0.3-1.7-1.3-2
	c-1.7-0.4-3.3-0.8-5-1.2c-1-0.2-1.8,0.2-2,1.2c-0.3,1,0.1,1.8,1.1,2.1c1.8,0.5,3.6,0.9,4.9,1.2C53.8,87.5,54.5,87.1,54.7,86.1z
	 M46.3,53.8c-0.2,1,0.1,1.8,1.1,2.1c1.8,0.5,3.6,0.9,5.4,1.2c0.9,0.2,1.6-0.4,1.8-1.3c0.2-1-0.2-1.8-1.2-2.1
	C51.7,53.3,50,53,48,52.6C47.3,52.5,46.5,52.9,46.3,53.8z"/>
</svg>
                        <h3>Data Management</h3>
                        <p>Let us help define your data storage structure for the future and merge that with your social network data.</p>
                    </Col>
					<Col className='dataintegration_s2_box' xs={{ span: 23,offset:-1}} sm={{ span: 11,offset:-1}} xl={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M45.1,17.7c0.9,0.5,1.6,0.9,2.2,1.3c-0.9,2.4-1.9,4.8-2.8,7.3c0.4,0.2,0.7,0.3,1,0.4c6.2,1.5,12.2,3.7,18,6.3
	c0.5,0.2,1.2,0.3,1.7,0.1c5.7-1.9,11.4-3.9,17.1-5.9c0.1,0,0.2-0.1,0.5-0.2c-0.5-1.4-1-2.9-1.6-4.3c-1.9-4.9-4.3-9.5-7.9-13.4
	c-2.2-2.4-4.7-4.3-8-4.9c-2.2-0.4-4.2,0.1-6.2,0.8c-0.5-0.7-1.1-1.3-1.7-2c3.5-1.8,7-2.1,10.6-0.8c3.7,1.3,6.4,3.8,8.8,6.8
	C80.6,14,83,19.4,85,25.1c0.1,0.4,0.3,0.8,0.4,1.2c2.8-0.4,5.5-0.9,8.3-1.2c4.9-0.6,9.9-0.5,14.7,0.8c9,2.5,13.1,9.4,10.8,18.5
	c-1.5,5.8-4.6,10.7-8.2,15.3c-1,1.4-2.2,2.7-3.3,4.1c0.9,1.2,1.9,2.3,2.8,3.5c3.6,4.7,6.8,9.6,8.3,15.4c1.3,4.8,1.1,9.5-2.1,13.6
	c-1.7,2.2-4,3.6-6.8,4.6c-0.2-0.8-0.3-1.6-0.5-2.5c0.2-0.1,0.3-0.2,0.5-0.3c5.8-2.8,8.1-7.4,6.7-13.6c-1.2-5.8-4.3-10.6-7.9-15.2
	c-0.9-1.2-1.9-2.3-3.1-3.7c-1.3,1.3-2.3,2.7-3.6,3.8c-3.8,3.2-7.6,6.3-11.4,9.5c-0.4,0.4-0.8,0.9-0.9,1.4
	c-1.1,6.1-2.1,12.2-3.2,18.4c1.2,0.2,2.8,0.5,4.4,0.8c0,0.8,0,1.6,0,2.6c-1.9-0.3-3.6-0.6-5.4-0.9c-0.9,2.4-1.8,4.8-2.8,7.1
	c-1.9,4.5-4.3,8.7-7.7,12.3c-1.2,1.2-2.6,2.4-4,3.4c-5,3.3-10.7,3.1-15.8-0.5c-3.6-2.6-6.2-5.9-8.2-9.8c-1.7-3.4-3.1-6.9-4.7-10.4
	c-0.2-0.5-0.4-1-0.6-1.5c-1.3,0.2-2.5,0.4-3.8,0.7c0-0.9,0-1.7,0-2.6c0.9-0.2,1.9-0.4,3-0.6c-0.5-2-1.1-3.8-1.4-5.7
	c-0.8-4.7-1.6-9.3-2.4-14c-0.1-0.5-0.3-1.1-0.7-1.4c-4.5-4-9.1-7.9-13.7-11.8c-0.1-0.1-0.3-0.2-0.4-0.4c-4.9,5.7-9.5,11.6-11.1,19.2
	c-1.4,6.6,1.1,11.3,7.4,13.9c0.2,0.1,0.4,0.2,0.6,0.3c-0.1,0.8-0.2,1.6-0.2,2.4c-5.1-1.1-9.5-5.2-10.4-10.2c-0.8-4.2,0-8.1,1.6-11.9
	c2.1-5.2,5.3-9.7,9-14c0.4-0.5,0.9-1,1.4-1.6c-1.6-2-3.3-4-4.8-6c-3-4.1-5.6-8.4-6.9-13.4c-2.5-9.8,1.8-17,11.7-19.3
	c5.8-1.4,11.6-1,17.4-0.2c1,0.2,2,0.3,3,0.6c0.8,0.2,1.1,0,1.4-0.8C43.2,22.5,44.1,20.2,45.1,17.7z M88.4,64.1
	c-0.3-4.6-0.6-9.2-0.9-13.8c0-0.4-0.4-0.9-0.7-1.1c-6.8-4.8-14-9-21.6-12.5c-0.4-0.2-1.1-0.2-1.6,0c-8.1,3.7-15.8,8.1-23,13.3
	c-0.5,0.3-0.9,1-0.9,1.5c-0.8,8.3-0.8,16.5,0,24.8c0,0.4,0.4,1,0.7,1.3c7.3,5.3,15.1,9.7,23.3,13.4c0.4,0.2,1.1,0.2,1.5,0
	c7.6-3.5,14.9-7.7,21.7-12.5c0.3-0.2,0.6-0.8,0.7-1.2C87.8,73,88.1,68.5,88.4,64.1z M86.2,28.9c1.8,6.3,2.9,12.5,3.7,18.7
	c0,0.4,0.4,0.8,0.7,1c2.6,2.1,5.2,4,7.7,6.2c2.6,2.2,5,4.6,7.6,6.9c4.6-5.4,8.9-10.9,10.7-18c0.5-2,0.7-4.3,0.4-6.4
	c-0.7-4.9-4.3-7.3-8.6-8.7c-5-1.6-10.2-1.5-15.4-0.9C90.7,28.1,88.5,28.5,86.2,28.9z M82.9,100.7c-0.3-0.1-0.4-0.2-0.5-0.2
	C76.5,99,70.8,97,65.2,94.7c-0.5-0.2-1.2-0.1-1.7,0.1c-4.5,1.6-8.9,3.4-13.4,5c-1.8,0.6-3.7,1-5.5,1.6c0.6,1.5,1,2.9,1.6,4.2
	c2.2,5.3,4.8,10.5,9.1,14.5c5.3,5,11.5,5,16.8,0C77.6,114.7,80.4,107.8,82.9,100.7z M22.4,61.8c2.4-2.2,4.7-4.4,7.1-6.5
	c2.4-2,4.9-3.9,7.3-5.9c0.3-0.2,0.4-0.7,0.5-1.2c0.8-4.5,1.4-8.9,2.2-13.4c0.4-2.1,1-4.2,1.6-6.4c-1.9-0.3-3.7-0.6-5.4-0.9
	c-5-0.6-9.9-0.8-14.8,0.4c-8.3,2-11.7,7.7-9.4,16C13.4,50.9,17.7,56.5,22.4,61.8z M90.4,75.9c5-3.8,9.5-7.7,13.6-12.1
	c-4.3-4.5-8.8-8.4-13.7-12c0.1,4.1,0.3,8.1,0.3,12C90.8,67.7,90.6,71.7,90.4,75.9z M39.9,81c1.2,6,2.4,11.8,3.6,17.8
	c6.2-1.5,11.9-3.4,17.3-5.8C54,89.1,47.1,85.1,39.9,81z M60.8,34.7c-5.4-2.3-11.1-4.3-17.1-5.6C42,35,40.8,40.8,40.2,46.7
	C47.1,42.7,53.9,38.7,60.8,34.7z M87.2,81.9c-6.6,3.8-12.9,7.4-19.5,11.2c5.3,1.8,10.5,3.5,16.1,5.3C85,92.7,86.1,87.3,87.2,81.9z
	 M83.7,29.3c-5.6,1.9-10.8,3.6-16,5.4c6.6,3.8,12.9,7.5,19.4,11.2C86.1,40.4,84.9,35.1,83.7,29.3z M36.7,52.7
	c-4.3,3.8-8.3,7.4-12.6,11.3c4.3,3.9,8.4,7.5,12.6,11.2C36.7,67.5,36.7,60.3,36.7,52.7z M100.6,94.6c-3.8,0-6.9,3.1-6.9,6.8
	c0,3.8,3.1,6.9,6.9,6.9c3.8,0,6.8-3.1,6.8-6.9C107.4,97.7,104.3,94.6,100.6,94.6z M28.6,94.6c-3.8,0-6.9,3-6.9,6.8
	c-0.1,3.8,3.1,6.9,6.9,6.9c3.8,0,6.8-3,6.8-6.9C35.4,97.7,32.3,94.7,28.6,94.6z M44.2,10.3c0,3.7,3.1,6.9,6.9,6.9
	c3.8,0,6.9-3.1,6.8-7c0-3.8-3.1-6.8-6.9-6.8C47.2,3.5,44.2,6.5,44.2,10.3z M65.7,83.7c0.1,0,0.3-0.1,0.4-0.1
	c2.6-0.1,5.2-0.6,7.7-1.4c1.7-0.6,3.2-1.3,4.5-2.5c0.8-0.8,1.4-1.6,1.4-2.8c0-1.5,0-3.1,0-4.6c0-0.6-0.1-1.1-0.5-1.6
	c-0.3,0.4-0.7,0.8-1,1.1c-1.4,1.2-3,1.9-4.7,2.5c-2.5,0.8-5.2,1.2-7.8,1.3c-3.8,0.2-7.6-0.2-11.3-1.3c-2.2-0.7-4.3-1.7-5.7-3.7
	c-0.3,0.5-0.5,1.1-0.5,1.7c0,1.5,0,3,0,4.6c0,0.8,0.3,1.6,0.8,2.2c0.8,1,1.8,1.7,3,2.2c2.5,1.2,5.2,1.8,8,2.1
	c0.8,0.1,1.5,0.2,2.3,0.2C63.4,83.7,64.6,83.7,65.7,83.7z M48.3,62.5c0,1.4,0,2.8,0,4.2c0,1.3,0.5,2.4,1.5,3.2
	c1.3,1.2,2.8,1.9,4.4,2.4c2.5,0.8,5.1,1.3,7.7,1.4c4.1,0.2,8.1-0.1,12-1.4c1.7-0.6,3.2-1.3,4.5-2.5c0.8-0.7,1.4-1.6,1.4-2.7
	c0-1.6,0-3.2,0-4.8c0-0.6-0.1-1.1-0.5-1.5c-0.4,0.4-0.7,0.8-1,1.1c-1.3,1.2-2.9,1.9-4.6,2.5c-2.5,0.8-5,1.2-7.6,1.3
	c-3.9,0.2-7.8-0.1-11.6-1.3c-2.2-0.7-4.3-1.6-5.7-3.7C48.4,61.3,48.3,61.9,48.3,62.5z M48.3,52.8c0,1.4,0,2.8,0,4.3
	c0,1.3,0.5,2.3,1.4,3.2c1.3,1.2,2.9,1.9,4.6,2.5c2.3,0.8,4.7,1.2,7.2,1.3c4.2,0.3,8.4,0,12.4-1.4c1.7-0.5,3.2-1.3,4.5-2.5
	c0.8-0.7,1.4-1.6,1.4-2.8c0-1.6,0-3.2,0-4.7c0-0.5-0.2-1-0.3-1.5c0,0-0.1,0-0.1,0c-0.2,1-0.9,1.6-1.7,2c-0.7,0.4-1.5,0.8-2.2,1.1
	c-2.4,0.9-4.8,1.3-7.3,1.5c-3.9,0.3-7.8,0.2-11.7-0.5c-1.9-0.4-3.8-0.8-5.5-1.8c-1-0.5-1.9-1.1-2.2-2.4
	C48.4,51.7,48.2,52.3,48.3,52.8z M58.7,44.7c-2.2,0.2-4.3,0.7-6.4,1.5c-1,0.4-2,0.9-2.8,1.6c-1.1,1-1.1,2.3,0,3.3
	c0.6,0.5,1.3,1,2,1.3c2.5,1.2,5.2,1.6,7.9,1.9c2.7,0.3,5.4,0.3,8.2,0.1c2.4-0.2,4.8-0.6,7.2-1.3c1.4-0.4,2.7-1,3.8-2
	c0.9-0.8,1.1-1.7,0.5-2.6c-0.3-0.4-0.7-0.8-1.1-1.1c-1.3-0.9-2.8-1.4-4.3-1.8c-3.2-0.8-6.4-1.1-9.7-1.2
	C62.2,44.4,60.5,44.5,58.7,44.7z"/>
</svg>
                        <h3>Data Fusion</h3>
                        <p>Let us help you unify all of your business process and create a better user experience and leverage the power of data and measure your success.</p>
                    </Col>
					
                    
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default Dataintegration