import React from 'react'
import { Menu, Col, Divider, Row, Space, Card } from 'antd';
import './megamenu.css'


const Megamenu = () => {
  return (
    <div className='megamenu_main'>

        <div className='container'>
            <Space direction='horizontal'>
            <img className='megamenu_image1' src={require('../../assets/megamenu_image1.jpg')} alt='' />
            <Menu className='megamenu_list' 
            items={[
                {

                type: 'group',
                label: 'IT Consulting',
                key: 'IT Consulting',
                },
                
                  {
                    label: 'Technology Assessment',
                    key: 'Technology Assessment',
                  },
                  {
                    label: 'Technology Consulting',
                    key: 'Technology Consulting',
                  },
                  {
                    label: 'Strategy and Management ',
                    key: 'Strategy and Management ',
                  },
                
            ]} />
            
            <Menu className='megamenu_list' 
            items={[
                {

                type: 'group',
                label: 'Software Development',
                key: 'Software Development',
                },
                
                  {
                    label: 'App Development',
                    key: 'App Development',
                  },
                  {
                    label: 'Data & Analytics',
                    key: 'Data & Analytics',
                  },
                  {
                    label: 'Data Migration',
                    key: 'Data Migration',
                  },
                
            ]} />
            <Menu className='megamenu_list' 
            items={[
                {

                type: 'group',
                label: 'Managed IT Services',
                key: 'Managed IT Services',
                },
                
                  {
                    label: 'Cloud Enablement',
                    key: 'Cloud Enablement',
                  },
                  {
                    label: 'Cloud Migration',
                    key: 'Cloud Migration',
                  },
                  {
                    label: 'Digital Transformation',
                    key: 'Digital Transformation',
                  },
                  {
                    label: 'DevOps',
                    key: 'DevOps',
                  },
                
            ]} />
              
              </Space>
          

        </div>

        



    </div>
  )
}

export default Megamenu