import React from 'react';
import './offers.css';
import { Menu, Col, Divider, Row } from 'antd';


const Offers = () => {
  return (
    <div className='offers'>
      
      <Row>
        <Col xs={{ span: 8 }} lg={{ span: 16 }}>

            <h2>What We</h2>
            <h1> Offers </h1>

        </Col>
        <Col xs={{ span: 4 }} lg={{ span: 8 }}>
          <img className='offers_box' src={require('../../assets/offer-img1.jpg')} alt='' />
        </Col>
      
    </Row>


    </div>
  )
}

export default Offers