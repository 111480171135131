import './cloudmigration.css';
import { Headersub, Footer, Services, Partners, Offers, News, Casestudies } from '../../containers';
import { Menu, Col, Divider, Row } from 'antd';



const Cloudmigration = () => {

    
  return (
    <><Headersub />
    <div className='services_banner'>
        <h1>Our Services</h1>
    </div>
    <div className='cloudmigration_page'>
        <Row className='align_margin'>
            <Col xs={{ span: 13 }} lg={{ span: 13 }}>
            <div className='bg_box1'></div>
            
                
            </Col>
            <Col className='services_div_right' xs={{ span: 11 }} lg={{ span: 11 }}>
                <h1>Cloud Migration</h1>
                <div className='line'></div>
                <p>Leverage our cloud expertise to identify the best suited cloud technologies to migrate your legacy applications and modernize your tech landscape. Loose your data centers and reduce your Total Cost of Ownership <strong>(TOC)</strong>.</p>                
            </Col>
        </Row>
        <div className='cloudmigration_box_container'>
        

        <Row className='cloudmigration_s2_container align_margin'>
            
            <Col className='cloudmigration_div_right' xs={{ span: 24,offset:0 }} lg={{ span: 24, offset:0 }}>
                <div className='cloudmigration_s2_box_container'>
                    <Row>
                    <Col className='cloudmigration_s2_box' xs={{ span: 7,offset:-1}} lg={{ span: 7,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M126.6,70.9c-0.6,2.3-0.9,4.7-1.7,6.8c-3.9,10-11.2,15.8-21.8,17.6c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.5-0.1
	c-0.1-0.5-0.1-1.1-0.2-1.6c-0.1-0.6-0.1-1.1-0.2-1.7c1.9-0.5,3.9-0.9,5.7-1.5c10.2-3.5,16.9-14.3,15.4-24.9
	c-1.4-10.5-8.4-18.3-18.7-20.5c-0.9-0.2-1.8-0.3-2.8-0.4c-0.9,0-1.2-0.3-1.2-1.3c0.1-6.6-1.8-12.6-5.9-17.7
	C87.8,18,79.6,14.3,69.7,15.4C58.1,16.6,50.3,23.2,46.2,34c-0.1,0.3-0.3,0.7-0.5,1.2c-3.7-2.5-7.6-3.4-11.6-1.1
	c-4,2.2-5.4,5.9-5.2,10.3c-1.8,0.3-3.6,0.4-5.3,0.8C6.3,48.8-0.9,70.5,10.8,83.7c4.8,5.5,10.9,8.2,18.2,8.3c2.6,0,5.3,0,8,0
	c0,1.3,0,2.4,0,3.7c-2.7,0-5.4,0-8.1,0c-3.4,0-6.6-0.6-9.8-1.8C7.1,89.5-0.4,76.7,1.7,64.1c2-11.8,10.8-20.8,22.6-22.8
	c0.7-0.1,1-0.4,1.2-1.1c1.3-5.7,4.8-9.3,10.4-10.7c2-0.5,4-0.5,5.9,0c0.6,0.2,1.2,0.3,1.6,0.2c2-2.8,3.7-5.7,6-8.2
	C62.4,7.4,85.2,8.3,97,23.4c3.9,5,6.1,10.6,6.6,16.9c0.1,0.8,0.4,1.1,1.1,1.2c11.7,2.5,20.2,11.7,21.7,23.4c0,0.3,0.2,0.5,0.2,0.8
	C126.6,67.5,126.6,69.2,126.6,70.9z M28.7,85.3c4,0,7.6,0,11.4,0c0,10.1,0,20.1,0,30c5.8,0,11.6,0,17.4,0c0-10.1,0-20,0-30.1
	c3.8,0,7.4,0,11.3,0C62,77,55.4,68.9,48.7,60.7C42.1,68.9,35.5,77,28.7,85.3z M36.4,81.6c4.2-5.2,8.2-10.1,12.3-15.1
	c4.1,5,8.1,9.9,12.3,15.1c-2.5,0-4.8,0-7.3,0c0,10.1,0,20.1,0,30.1c-3.4,0-6.6,0-10,0c0-9.9,0-19.9,0-30.1
	C41.3,81.6,39,81.6,36.4,81.6z M79.3,116.5c6.7-8.2,13.3-16.3,20.1-24.6c-4,0-7.6,0-11.4,0c0-10.1,0-20,0-30c-5.8,0-11.5,0-17.3,0
	c0,5.1,0,10,0,15c0,5,0,10,0,15.1c-3.8,0-7.4,0-11.3,0C66,100.3,72.6,108.4,79.3,116.5z M91.5,95.7c-4.2,5.1-8.2,10-12.3,15.1
	c-4.1-5-8.1-9.9-12.3-15c2.5,0,4.8,0,7.2,0c0-10.1,0-20,0-30.1c3.4,0,6.7,0,10.1,0c0,10,0,19.9,0,30.1C86.7,95.7,89,95.7,91.5,95.7z
	 M120.2,66.1c-1.1,0.1-2.2,0.2-3.4,0.4c0.3,5.1-1,9.6-4.4,13.4c-3.3,3.8-7.6,5.7-12.6,6c0,1.3,0,2.4,0,3.5
	C112.4,89.5,122.1,77.3,120.2,66.1z M114.4,59.1c1-0.7,2-1.3,3.1-1.9c-0.8-1.1-1.6-2.1-2.4-3.2c-0.9,0.9-1.8,1.6-2.7,2.5
	C113.1,57.4,113.7,58.3,114.4,59.1z M115.4,61.1c0.4,1.1,0.8,2.1,1.1,3.1c1.2-0.3,2.3-0.6,3.5-0.9c-0.4-1.3-0.8-2.5-1.3-3.8
	C117.5,60.2,116.4,60.6,115.4,61.1z M51.2,96.7c-1.2,0-2.3,0-3.5,0c0,3.6,0,7.2,0,10.8c1.2,0,2.3,0,3.5,0
	C51.2,103.8,51.2,100.2,51.2,96.7z M47.7,88c1.2,0,2.4,0,3.5,0c0-1.2,0-2.4,0-3.5c-1.2,0-2.3,0-3.5,0C47.7,85.7,47.7,86.8,47.7,88z
	 M47.7,90.6c0,1.2,0,2.3,0,3.5c1.2,0,2.4,0,3.6,0c0-1.2,0-2.3,0-3.5C50,90.6,48.8,90.6,47.7,90.6z M78.2,91.4c1.2,0,2.4,0,3.5,0
	c0-3.2,0-6.2,0-9.4c-1.2,0-2.3,0-3.5,0C78.2,85.2,78.2,88.3,78.2,91.4z M78.3,69.8c0,1.2,0,2.4,0,3.5c1.2,0,2.4,0,3.5,0
	c0-1.2,0-2.3,0-3.5C80.5,69.8,79.4,69.8,78.3,69.8z M78.3,79.4c1.2,0,2.3,0,3.5,0c0-1.2,0-2.4,0-3.6c-1.2,0-2.3,0-3.5,0
	C78.3,77.2,78.3,78.3,78.3,79.4z"/>
</svg>
                        <h3>Lift and Shift</h3>
                        <p>Migrate applications that are complex into cloud to start saving your operational cost immediately.</p>
                    </Col>
                    <Col className='cloudmigration_s2_box' xs={{ span: 7,offset:-1}} lg={{ span: 7,offset:-1 }}>                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M75,1.3c2.8,0.7,5.7,1,8.3,2c10.8,4.2,17.4,12,19.9,23.3c0.3,1.2,0.4,2.4,0.5,3.6c0,0.8,0.4,1,1.1,1.2
	c7.7,1.6,13.7,5.8,17.9,12.4c2.1,3.3,3.3,6.9,3.9,10.7c0.1,0.4,0.2,0.8,0.3,1.2c0,1.7,0,3.4,0,5.1c-0.5,2.1-0.8,4.3-1.6,6.4
	c-3.8,10.2-11.2,16.2-21.9,18.1c-0.2,0-0.4,0-0.6,0.1c-0.1,0-0.2,0-0.5-0.1c-0.1-1.1-0.2-2.2-0.4-3.3c2-0.5,3.9-0.9,5.8-1.6
	c10.1-3.5,16.7-14.3,15.3-24.9c-1.4-10.6-8.5-18.3-18.8-20.5c-0.9-0.2-1.8-0.4-2.7-0.4c-1,0-1.4-0.3-1.4-1.4
	c0.1-5.6-1.3-10.9-4.4-15.5c-6.2-9.1-15-13.6-26-12.5C58,6.4,50.2,13.1,46.1,24c-0.1,0.3-0.2,0.6-0.4,1.1c-3.7-2.5-7.6-3.4-11.6-1.1
	c-4.1,2.2-5.5,6-5.2,10.5c-7.8,0.3-14.1,3.2-18.9,9.2c-3.3,4.2-4.9,9.1-5,14.4C4.7,68.3,12,80,26.1,81.9c-0.1,1.1-0.2,2.2-0.4,3.6
	c-1.7-0.4-3.4-0.6-5-1.1C9.7,80.7,3.2,73.1,1.4,61.6c0-0.3-0.2-0.5-0.2-0.8c0-1.7,0-3.4,0-5.1c0.5-2,0.8-4.1,1.5-6
	c3.7-10.2,10.9-16.4,21.6-18.4c0.7-0.1,1-0.3,1.2-1.1c1.9-8.4,9.5-12.9,17.8-10.4c0.2,0,0.3,0.1,0.4,0.1c1.1-1.8,2-3.6,3.2-5.2
	C52.2,7,59.6,2.6,69,1.5c0.3,0,0.6-0.1,0.9-0.2C71.6,1.3,73.3,1.3,75,1.3z M76.3,60.2c0-9.1,0-18.1,0-27.3c-1.5,0-3,0-4.6,0
	c0-1.6,0-3.1,0-4.5c-1.3,0-2.4,0-3.7,0c0,1.5,0,3,0,4.6c-6,0-11.9,0-17.8,0c0,20.9,0,41.6,0,62.3c6.5,0,12.9,0,19.2,0
	c0-1.2,0-2.4,0-3.6c-5.2,0-10.4,0-15.5,0c0-18.4,0-36.7,0-54.9c6.3,0,12.4,0,18.7,0c0,7.9,0,15.6,0,23.5
	C74,60.2,75.1,60.2,76.3,60.2z M73.2,65.5c-0.3,0.3-0.5,0.9-0.5,1.4c0,9.1,0,18.2,0,27.3c0,0.4,0,0.7,0.1,1c11.6,0,23.1,0,34.6,0
	c0-1.2,0-2.3,0-3.6c-2.8,0-5.6,0-8.4,0c0-0.6,0-1.1,0-1.6c0-6.9-0.1-13.8,0.1-20.7c0.1-2.5-0.6-4.1-2.6-5.7
	c-3.6-2.9-7.1-6.1-10.6-9.2C81.6,58.2,77.4,61.9,73.2,65.5z M76.4,91.7c0-0.5,0-0.9,0-1.3c0-7.2,0-14.4,0-21.6
	c0-0.8,0.2-1.4,0.8-1.9c2.9-2.5,5.7-5,8.7-7.5c3.1,2.7,6.1,5.3,9.1,7.9c0.2,0.2,0.4,0.6,0.4,1c0,7.6,0,15.3,0,22.9
	c0,0.2-0.1,0.3-0.1,0.5C89,91.7,82.8,91.7,76.4,91.7z M32.7,90.9c0-11.4,0-22.7,0-34.1c0-0.7,0.3-1.1,0.9-1.4
	c4-1.9,7.9-3.8,11.8-5.7c0.8-0.4,1.7-0.8,2.6-1.3c-0.5-1.1-1.1-2.2-1.6-3.2c-0.5,0.2-0.8,0.3-1.1,0.5c-5,2.4-10.1,4.9-15.1,7.3
	c-0.9,0.4-1.2,0.9-1.1,1.9c0,11.8,0,23.5,0,35.3c0,0.5,0,1,0,1.6c-2.9,0-5.6,0-8.4,0c0,1.3,0,2.4,0,3.6c8.9,0,17.7,0,26.4,0
	c0-1.2,0-2.4,0-3.6c-4.8,0-9.5,0-14.3,0C32.7,91.3,32.7,91.1,32.7,90.9z M49.2,97.4c-2.2,2.2-4.4,4.3-6.5,6.5
	c-0.4,0.5-0.8,1.2-0.8,1.9c-0.1,1.8-0.1,3.6,0,5.4c0,0.9-0.2,1.4-1.1,1.8c-2.8,1.1-4.4,3.9-4.3,6.8c0.1,2.9,2,5.5,4.7,6.4
	c3.7,1.3,7.8-0.5,9.2-4.1c1.4-3.7-0.2-7.7-3.9-9.2c-0.7-0.3-1-0.7-0.9-1.4c0.1-1.4-0.1-2.9,0-4.3c0-0.6,0.3-1.3,0.7-1.7
	c1.9-1.9,3.8-3.8,5.7-5.6c-0.9-1-1.7-1.8-2.5-2.6C49.3,97.4,49.3,97.4,49.2,97.4z M40.2,119.6c0-2,1.6-3.6,3.6-3.6
	c1.9,0,3.5,1.7,3.5,3.6c0,1.9-1.6,3.5-3.5,3.5C41.8,123.1,40.2,121.5,40.2,119.6z M78.9,102.7c2.9,2.2,4.1,4.9,3.6,8.5
	c-0.1,0.9-0.2,1.4-1.2,1.8c-2.6,1.1-4.4,3.9-4.3,6.6c0.1,3,2,5.7,4.8,6.6c3.7,1.3,7.7-0.5,9.1-4c1.5-3.7-0.2-7.8-3.9-9.3
	c-0.7-0.3-1-0.7-0.9-1.4c0.1-1.2-0.1-2.4,0-3.5c0.3-2.3-0.5-4-2.3-5.5c-1.9-1.6-3.5-3.5-5.3-5.2c-0.9,0.9-1.7,1.7-2.6,2.6
	C77,100.8,77.9,101.9,78.9,102.7z M80.8,119.6c0-1.9,1.6-3.6,3.6-3.6c1.9,0,3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5
	C82.4,123.1,80.8,121.5,80.8,119.6z M62.2,105c0,0.9-0.2,1.3-1.1,1.7c-3.1,1.3-4.8,4.6-4.2,7.9c0.6,3.1,3.3,5.5,6.6,5.8
	c3.3,0.2,6.3-1.8,7.3-5c1-3.2-0.2-6.8-3.1-8.2c-1.8-0.9-2-1.9-1.9-3.6c0.1-1.4,0-2.8,0-4.2c0-0.3-0.1-0.6-0.1-0.9
	c-1.2,0-2.3,0-3.6,0C62.2,100.8,62.1,102.9,62.2,105z M60.5,113.3c0-2,1.5-3.6,3.6-3.5c2,0,3.5,1.6,3.5,3.5c0,2-1.5,3.5-3.5,3.5
	C62,116.8,60.5,115.3,60.5,113.3z M36.3,87.8c1.2,0,2.3,0,3.5,0c0-9.8,0-19.5,0-29.3c-1.2,0-2.3,0-3.5,0
	C36.3,68.3,36.3,78,36.3,87.8z M43.2,87.7c1.2,0,2.4,0,3.5,0c0-5.7,0-11.3,0-17c-1.2,0-2.3,0-3.5,0C43.2,76.4,43.2,82.1,43.2,87.7z
	 M69.9,64.3c-4.4,0-8.7,0-13.1,0c0,1.2,0,2.3,0,3.5c4.4,0,8.7,0,13.1,0C69.9,66.5,69.9,65.4,69.9,64.3z M69.8,53.4
	c0-1.2,0-2.4,0-3.5c-4.4,0-8.7,0-13.1,0c0,1.2,0,2.3,0,3.5C61.2,53.4,65.5,53.4,69.8,53.4z M56.8,42.7c0,1.2,0,2.3,0,3.5
	c4.4,0,8.7,0,13.1,0c0-1.2,0-2.3,0-3.5C65.4,42.7,61.1,42.7,56.8,42.7z M69.8,60.6c0-1.2,0-2.4,0-3.5c-4.4,0-8.7,0-13.1,0
	c0,1.2,0,2.3,0,3.5C61.2,60.6,65.5,60.6,69.8,60.6z M56.8,74.9c4.4,0,8.7,0,13,0c0-1.2,0-2.4,0-3.6c-4.4,0-8.7,0-13,0
	C56.8,72.6,56.8,73.8,56.8,74.9z M109.6,72.5c-1.5,1.1-3.2,1.8-4.9,2.7c0.3,1.1,0.7,2.2,1,3.3c4.5-1.5,8.1-4,10.9-7.8
	c-1-0.7-1.9-1.4-2.8-2C112.3,70,111.1,71.4,109.6,72.5z M120.8,58.3c-1.3,0-2.5,0-3.7,0c-0.1,1.1-0.2,2.1-0.3,3.3
	c1.2,0.2,2.3,0.4,3.6,0.7C120.5,60.8,120.6,59.6,120.8,58.3z M43.2,58.5c0,1.2,0,2.3,0,3.5c1.2,0,2.4,0,3.6,0c0-1.2,0-2.3,0-3.5
	C45.4,58.5,44.3,58.5,43.2,58.5z M116.2,63.7c-0.5,1.1-0.9,2-1.3,3c1.1,0.6,2.1,1.2,3.2,1.8c0.6-1.3,1-2.4,1.6-3.7
	C118.5,64.4,117.3,64.1,116.2,63.7z M46.7,68.1c0-1.2,0-2.4,0-3.5c-1.2,0-2.3,0-3.5,0c0,1.2,0,2.3,0,3.5
	C44.4,68.1,45.5,68.1,46.7,68.1z M87.7,82.3c1.4,0,2.6,0,3.9,0c0-1.2,0-2.3,0-3.5c-1.3,0-2.6,0-3.9,0C87.7,80,87.7,81.1,87.7,82.3z
	 M87.7,71.4c0,1.2,0,2.3,0,3.5c1.3,0,2.6,0,4,0c0-1.2,0-2.3,0-3.5C90.2,71.4,88.9,71.4,87.7,71.4z M83.9,74.9c0-1.2,0-2.4,0-3.5
	c-1.3,0-2.6,0-3.9,0c0,1.2,0,2.3,0,3.5C81.3,74.9,82.6,74.9,83.9,74.9z M80,78.8c0,1.2,0,2.3,0,3.5c1.3,0,2.6,0,3.9,0
	c0-1.2,0-2.3,0-3.5C82.5,78.8,81.3,78.8,80,78.8z"/>
</svg>
                        <h3>Cloud Applications</h3>
                        <p>Enhance your applications with Cloud Native Architecture to scale for future, simplified business process and better user experience.</p>
                    </Col>
                    <Col className='cloudmigration_s2_box' xs={{ span: 7,offset:-1}} lg={{ span: 7,offset:-1 }}>
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path className='svg_icons' d="M123.9,49.8c-3.4-9.1-11.5-14.5-21-14.7c-1,0-1.5-0.3-1.7-1.3c-0.4-2.3-1.4-4.4-2.4-6.5
	c-4.7-9.7-12.2-16.2-22.4-19.5c-1.8-0.6-3.7-0.9-5.6-1.2c-4-0.6-7.9-0.7-11.9,0.2c-3.3,0.4-6.4,1.5-9.4,2.9
	c-6.4,3-11.6,7.4-15.4,13.5c-0.5,1.1-1.4,1.3-2.6,1.3c-4.9-0.3-9.5,0.8-13.8,3c-2.6,1.4-5,3-7,5.2C5,38.4,2.3,45.3,2.6,53.4
	c0.1,1.2,0.1,2.4,0.3,3.5C5,69.4,14.9,78.8,27.5,80.2c1.2,0.1,1.4,0.6,1.3,1.6c-0.1,5.4,0.9,10.6,3.2,15.5
	c5.6,11.8,14.6,19.9,27.2,23.7c1.4,0.4,2.9,1,4.4,0.8c4.6-0.7,9-2.3,13-4.8c-0.6-0.4-1.1-0.9-1.6-1.4c0.5,0.5,1,1,1.6,1.4
	c4.5-2.5,8.3-5.7,11.6-9.7c4.6-5.3,7.6-11.3,8.9-18.3c0.5-2.5,0.7-5,0.6-7.5c0-0.9,0.2-1.2,1.1-1.1c1.9,0,3.8,0.1,5.7,0
	c5-0.4,9.5-2.2,13.2-5.7c2.7-2.2,4.5-5,5.8-8.1C126,61,126,55.4,123.9,49.8z M93.5,81.8c0,1.6-0.1,3.2-0.2,4.9c0,0,0,0,0,0
	c-1,6.7-3.9,12.6-8.1,17.9c-3.3,3.8-7.1,7-11.5,9.5c0.4,0.4,0.7,0.8,1.1,1.2c-0.4-0.4-0.7-0.8-1.1-1.2c-2.2,1-4.5,2-6.8,2.7
	c-2.3,0.7-4.5,0.8-6.8,0.1C45.5,112,36.5,102.1,33.4,87c-0.3-1.4-0.3-2.9-0.4-4.3c0-9.2,0-18.4,0-27.7c0-2.2,0-2.2,2.1-2.8
	c2.8-0.6,5.7-1.1,8.5-1.7c4-0.9,8-1.8,12.1-2.6c1.2-0.2,2.4-0.4,3.6-0.7c2.6-0.8,5.2-0.6,7.9,0c8.3,1.8,16.6,3.5,24.9,5.3
	c1.1,0.2,1.5,0.6,1.4,1.8C93.5,63.4,93.6,72.6,93.5,81.8z M116,70.6c-0.3,0.3-0.6,0.6-0.9,1c0,0,0,0,0,0c-2.8,2.3-6,3.9-9.6,4.5
	c-2.3,0.3-4.7,0.1-7,0.2c-0.6,0-0.7-0.4-0.7-0.9c0-1.1,0-2.3,0-3.4c0-6.7,0-13.4,0-20.2c0-2-0.4-2.5-2.3-2.9
	c-5.9-1.2-11.8-2.4-17.7-3.7c-4.6-1-9.1-1.8-13.7-2.9c-0.4-0.1-0.9-0.1-1.3,0c-3.2,0.7-6.4,1.4-9.6,2.1c0,0.2,0,0.4,0.1,0.6
	c-0.1-0.2-0.1-0.3-0.1-0.6c-2.1,0.4-4.3,0.8-6.4,1.3c-4.7,1-9.5,2.1-14.2,3.1c-3.7,0.4-3.7,1.3-3.7,3.9c0.1,7.1,0,14.1,0,21.2
	c0,2.4,0,2.4-2.4,2.1c-0.1,0-0.2,0-0.3,0c-3-0.7-6-1.8-8.6-3.5c-4.8-3.2-8-7.5-9.9-12.9c-0.3-1-0.4-1.9-0.8-2.9
	C6.5,53.1,6.6,49.5,7.6,46c1.1-4,3.3-7.4,6-10.5c-0.3-0.4-0.6-0.8-1-1.2c0.4,0.4,0.7,0.8,1,1.2c5.4-5.1,11.7-7.6,19.1-6.9
	c2.9,0.3,3.1,0.1,4.6-2.9c-0.1-0.1-0.1-0.1-0.2-0.2c0.1,0.1,0.1,0.1,0.2,0.2c2.3-3.4,5-6.5,8.3-8.9c4.6-3.4,9.7-5.5,15.4-6.2
	c0.3,0,0.5-0.1,0.8-0.2c4.8-0.3,9.6,0,14.2,1.6c11.7,4.3,18.9,12.6,21.6,24.7c0.6,2.5,0.9,2.8,3.5,2.6c8.1-0.5,14.2,2.8,18,9.9
	C122.9,56.3,121.5,64.8,116,70.6z M81.3,69.1c0.8,0.8,0.9,2.1,0,3c-2.6,2.6-5.1,5.2-7.8,7.7c-1.1,0.9-2,2.1-3,3.1
	c-1.1,1.1-2.3,2.2-3.4,3.3c-2.6,2.6-5.2,5.3-7.8,7.8c-1,0.9-2.1,0.9-3.1-0.1c-3.6-3.6-7.2-7.2-10.8-10.8c-1-1-1-2.2-0.1-3
	c0.8-0.8,2.1-0.8,3.1,0.1c2.9,2.8,5.7,5.6,8.5,8.5c0.7,0.8,1.2,0.7,1.8,0c1.8-1.8,3.6-3.6,5.4-5.4c4.7-4.7,9.4-9.5,14.1-14.2
	C79.2,68.2,80.5,68.3,81.3,69.1z"/>
</svg>
                        <h3>Cloud DevOps</h3>
                        <p>Safe operational and maintenance cost with automated DevOps process.</p>
                    </Col>
                    
                    </Row>
                </div>
            </Col>
        </Row>
        </div>
    </div>
    
    <Footer /></>

  )
}
export default Cloudmigration