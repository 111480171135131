import React from 'react';
import { Navi, Servicesbox } from '../../components';
import './header.css';
import Logo from '../../logo.svg';

import { Col, Row } from 'antd';



const Header = () => {
  return (
    <>
    <div className='header'>
    <Row className='top__navi container'>
        <Col xs={{ span: 6 }} lg={{ span: 6 }}>
          <img className='app__logo' src={Logo} alt='' />
        </Col>
        <Col className='navi__align' xs={{ span: 18 }} lg={{ span: 18, offset:0 }}>
          <Navi />
         </Col>
    </Row>
      <div className='header__banner'>
      <Row>
        <Col xs={{ span: 10 }} lg={{ span: 10 }}>
        <div>
  
        </div>
        </Col>
        <Col xs={{ span: 14 }} lg={{ span: 14 }}>
          <div className='banner_container'>
              <div class="slide"></div>
              <div class="slide"></div>
              <div class="slide"></div>
          </div>
        </Col>
    </Row>

    <Servicesbox />

      </div>
    </div>
      
    </>
  )
}

export default Header