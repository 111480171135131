import React from 'react';
import './partners.css';
import { Menu, Col, Divider, Row } from 'antd';
import Microsoft from '../../assets/microsoft.svg'


const Partners = () => {
  return (
    <>
    <div className='partners'>
      <div className='overlay_color'>
    <div className='container'>
        <div className='partners_container'>
        <Row>
        <Col xs={{ span: 12 }} lg={{ span: 24 }}>
              <h1>Partners and Awards</h1>
              
        </Col>
        </Row>
        <Row>
        <Col xs={{ span: 12 }} lg={{ span: 24 }}>
              <svg height="40" width="100">
                <g fill="none">
                  <path stroke-width="2" stroke="#353535" d="M5 20 l215 0" />
                </g>
              </svg>
        </Col>
        </Row>
        <Row className='brands_container'>
            <Col xs={{ span: 3 }} lg={{ span: 6 }}>

                <img className='partners_box' src={require('../../assets/aws.png')} alt='' />
                  
            </Col>
            <Col xs={{ span: 3 }} lg={{ span: 6 }}>
                <img className='partners_box' src={Microsoft} alt='' />
            </Col>
            <Col xs={{ span: 3 }} lg={{ span: 6 }}>

                <img className='partners_box' src={require('../../assets/oracle.png')} alt='' />
                  
            </Col>
            <Col xs={{ span: 3 }} lg={{ span: 6 }}>
                <img className='partners_box' src={require('../../assets/ibm.png')} alt='' />
            </Col>
        </Row>
      </div>
      </div>
      </div>
    </div>
    </>
  )
}

export default Partners